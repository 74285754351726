import React, { useState, useEffect } from 'react';
import { useParams, Redirect, useLocation } from 'react-router-dom';
import { LoadingSpinner, Box } from 'react-limbix-ui';

import PatientTable from './PatientTable';

import {
  QueryCareteamsArgs,
  MutationResetPatientAccessAttemptsArgs,
} from '@/apollo/types';
import { CARETEAMS_LIST } from '@/apollo/queries';
import { RESET_PATIENT_ACCESS_ATTEMPTS } from '@/apollo/mutations';
import { UseParamsType, MutationResultType } from '@/types';
import history from '@/utils/history';
import { useQuery, useMutation } from '@/hooks/apollo';
import { isPermissionDeniedError } from '@/utils/errorUtils';

const PatientList: React.FC = () => {
  const { pdtUid } = useParams<UseParamsType>();
  const { state, pathname } = useLocation<{ searchFilter?: string }>();

  const [searchFilter] = useState(state?.searchFilter?.toLowerCase() || '');

  useEffect(() => {
    history.replace(pathname, null);
  }, []);

  const { loading, data, error } = useQuery<QueryCareteamsArgs>(CARETEAMS_LIST, {
    variables: {
      pdtUid,
    },
  });

  const refetchQueries = [
    {
      query: CARETEAMS_LIST,
      variables: {
        pdtUid,
      },
    },
  ];
  const [resetPatientAccessAttemptsMutation] = useMutation<MutationResetPatientAccessAttemptsArgs>(
    RESET_PATIENT_ACCESS_ATTEMPTS, { refetchQueries },
  );
  const resetPatientAccessAttempts = (patientUid: string): Promise<MutationResultType> => (
    resetPatientAccessAttemptsMutation({
      variables: {
        patientUid,
        pdtUid,
      },
    })
  );

  if (loading) {
    return <LoadingSpinner />;
  }

  if (isPermissionDeniedError(error)) {
    return <Redirect to="/operations" />;
  }

  if (data?.careteams) {
    return (
      <Box width="100%">
        <PatientTable
          pdtUid={pdtUid}
          careteams={data.careteams}
          resetPatientAccessAttempts={resetPatientAccessAttempts}
          searchFilter={searchFilter}
        />
      </Box>
    );
  }
  return (<></>);
};

export default PatientList;
