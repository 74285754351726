import React, { useState } from 'react';
import { TextField } from '@material-ui/core';
import { GraphQLError } from 'graphql';

import Styled from '../Modal.styles';

import { GraphQLErrorsType } from '@/types';
import ExclamationSVG from '@/assets/ExclamationCircle.svg';
import { ModalPayloadType } from '@/types/ReduxTypes';

type Props = {
  payload: ModalPayloadType['CONFIRMATION_POPUP_CHANGE_REASON'];
  onClose: () => void;
};

const ConfirmationPopupChangeReason: React.FC<Props> = (props: Props) => {
  const {
    onClose,
    payload,
  } = props;

  const {
    header,
    body,
    mutation,
    mutations,
  } = payload;
  const [changeReason, setChangeReason] = useState<string>();
  const [errors, setErrors] = useState<GraphQLErrorsType>([]);

  const triggerMutation = async () => {
    const { errors: resultErrors } = await mutation(changeReason);
    if (resultErrors) {
      setErrors(resultErrors);
    } else {
      onClose();
    }
  };

  const triggerMutations = async () => {
    const mutationErrors: GraphQLError[] = [];
    const results = await Promise.all(
      mutations.map((_mutation) => _mutation(changeReason)),
    );
    results.forEach((result) => {
      if (result.errors && result.errors?.length > 0) {
        mutationErrors.push(...result.errors);
      }
    });
    if (mutationErrors.length > 0) {
      setErrors([...mutationErrors]);
    } else {
      onClose();
    }
  };

  const handleConfirm = async () => {
    if (!mutation && mutations) {
      await triggerMutations();
    } else {
      await triggerMutation();
    }
  };

  return (
    <div style={{ width: '400px', height: '350px' }}>
      <Styled.DialogContent>
        <div style={{ display: 'flex' }}>
          <Styled.WarningSVGContainer>
            <Styled.WarningSVG src={ExclamationSVG} />
          </Styled.WarningSVGContainer>
          <Styled.TextContainer>
            <Styled.HeaderText>
              {header || 'Confirmation'}
            </Styled.HeaderText>
            <Styled.BodyText>
              {body}
            </Styled.BodyText>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              name="change reason"
              label="Change Reason"
              id="change reason"
              onChange={(event) => setChangeReason(event.target.value?.trim())}
            />
            {errors.map((error) => (
              <div key={error.message} style={{ color: 'red' }}>
                {error.message}
              </div>
            ))}
          </Styled.TextContainer>
        </div>
        <Styled.ButtonContainer>
          <Styled.NoButton onClick={onClose}>
            <Styled.NoButtonText>
              Close
            </Styled.NoButtonText>
          </Styled.NoButton>
          <Styled.YesButton onClick={handleConfirm} disabled={!changeReason}>
            <Styled.YesButtonText>
              Confirm
            </Styled.YesButtonText>
          </Styled.YesButton>
        </Styled.ButtonContainer>
      </Styled.DialogContent>
    </div>
  );
};

export default ConfirmationPopupChangeReason;
