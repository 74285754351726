import React, { useState } from 'react';
import {
  Table,
  Link,
  TableColumn as Column,
  TableRow as Row,
  SortState,
  Box,
} from 'react-limbix-ui';

import MobileAccess from './MobileAccess';
import AdminActions from './AdminActions';

import { CareTeamType } from '@/apollo/types';
import {
  MutationResultType,
} from '@/types';
import { SearchBar } from '@/components';
import { formatDate } from '@/utils/dateUtils';
import { createColumn, createCell, hasSearchString } from '@/utils/tableUtils';
import { useModal } from '@/hooks/redux';

type Props = {
  pdtUid: string;
  careteams?: CareTeamType[];
  resetPatientAccessAttempts?: (userUid: string) => Promise<MutationResultType>;
  searchFilter?: string;
};
const PatientTable: React.FC<Props> = (props: Props) => {
  const {
    careteams,
    resetPatientAccessAttempts,
    searchFilter,
    pdtUid,
  } = props;
  const [showModal] = useModal();
  const [searchString, setSearchString] = useState(searchFilter || '');

  const renderUID = (patientUid: string) => {
    const basePDTUrl = `/operations/pdt/${pdtUid}`;
    const linkToUID = `${basePDTUrl}/patient/${patientUid}`;
    return <Link.RouterLink to={linkToUID} fontSize="16px">{patientUid?.substring(0, 6) || 'None'}</Link.RouterLink>;
  };

  const renderFailedAttempts = (careteam: CareTeamType) => (
    !careteam.patient.user.accessAttempts ? '0' : (
      <Box>
        {`${careteam.patient.user.accessAttempts} `}
        <Link.ButtonLink onClick={() => (resetPatientAccessAttempts(careteam.patient.uid))}>
          (reset)
        </Link.ButtonLink>
      </Box>
    )
  );

  const renderAdminActions = (careteam: CareTeamType) => (
    <AdminActions careteam={careteam} pdtUid={pdtUid} />
  );

  const renderMobileAccess = (careteam: CareTeamType) => (
    <MobileAccess careteam={careteam} />
  );

  const renderNotes = (careteam: CareTeamType) => (
    <Link.ButtonLink
      onClick={
        () => showModal(
          'PATIENT_NOTES',
          {
            careteam,
          },
        )
      }
    >
      view
    </Link.ButtonLink>
  );

  const renderContactInfo = (careteam: CareTeamType) => (
    <Link.ButtonLink
      onClick={
        () => showModal(
          'PATIENT_CONTACT_INFO',
          {
            careteam,
          },
        )
      }
    >
      view
    </Link.ButtonLink>
  );

  const columns: Column[] = [
    createColumn('uid', 'UID', 'string', null, true),
    createColumn('ageAtEnrollment', 'Age at Enrollment', 'number', '5%', true),
    createColumn('currentAge', 'Current Age', 'number', '5%', true),
    createColumn('email', 'Email', 'string', null, true),
    createColumn('emailConfirmed', 'Email Confirmed', 'string', null, true),
    createColumn('failedAttempts', 'Failed Attempts', 'number', null, true),
    createColumn('status', 'Status', 'string', null, true),
    createColumn('accountCreation', 'Account Creation Date', 'date', null, true),
    createColumn('appVersion', 'App Version', 'string', null, true),
    createColumn('admin', 'Admin Actions', 'string', null, false),
    createColumn('access', 'Mobile Access', 'string', null, false),
    createColumn('notes', 'Notes', 'string', null, false),
    createColumn('contactInfo', 'Contact Info', 'string', '8%', false),
  ];

  const rows: Row[] = careteams
    .filter((careteam) => {
      if (!searchString || searchString === '') {
        return true;
      }
      const uidString = careteam?.patient?.uid?.toLowerCase();
      const email = careteam?.patient?.user?.email?.toLowerCase();
      try {
        return hasSearchString([uidString, email], searchString);
      } catch (error) {
        return true;
      }
    })
    .map((careteam) => {
      const { patient: { user }, patient, userPdtData } = careteam;
      return {
        id: careteam.patient.uid,
        cells: [
          createCell('uid', patient.uid, renderUID(patient.uid)),
          createCell('ageAtEnrollment', user.ageAtEnrollment, user.ageAtEnrollment),
          createCell('currentAge', user.currentAge, user.currentAge),
          createCell('email', user.email, user.email),
          createCell('emailConfirmed', user.emailVerified, `${user.emailVerified}`),
          createCell('failedAttempts', user.accessAttempts, renderFailedAttempts(careteam)),
          createCell('status', careteam.status, careteam.status),
          createCell('accountCreation', careteam.enrolledTime, formatDate(careteam.enrolledTime)),
          createCell('appVersion', userPdtData?.appVersion || '', userPdtData?.appVersion),
          createCell('admin', 'Admin Actions', renderAdminActions(careteam)),
          createCell('access', 'Mobile Access', renderMobileAccess(careteam)),
          createCell('notes', 'Notes', renderNotes(careteam)),
          createCell('contactInfo', 'contactInfo', renderContactInfo(careteam)),
        ],
      } as Row;
    });

  const defaultSortState: SortState = { col: '', direction: 'desc' };

  return (
    <Box>
      <Box marginBottom="20px" float="right">
        <SearchBar
          placeholder="Filter Patients"
          defaultValue={searchString}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => (
            setSearchString(event.currentTarget.value?.toLowerCase() || '')
          )}
        />
      </Box>
      <Table columns={columns} rows={rows} defaultSortState={defaultSortState} />
    </Box>
  );
};

export default PatientTable;
