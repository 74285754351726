import React, { useState } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import { Button } from '@material-ui/core';

import { LoadingSpinner, Box, Text } from 'react-limbix-ui';

import ParticipantTable from './ParticipantTable';

import {
  QueryStudyParticipantsArgs,
  MutationResetAccessAttemptsArgs,
  MutationGenerateCaseReportFormsArgs,
  MutationGenerateCaseReportFormArgs,
  LimbixUserAccountType,
} from '@/apollo/types';
import { STUDY_QUERY_PARTICIPANT_DATA } from '@/apollo/queries';
import {
  RESET_ACCESS_ATTEMPTS,
  GENERATE_STUDY_CRFS,
  GENERATE_PARTICIPANT_CRF,
} from '@/apollo/mutations';
import { MutationResultType, UseParamsType } from '@/types';
import { useModal, usePermissions } from '@/hooks/redux';
import { useQuery, useMutation } from '@/hooks/apollo';
import { isPermissionDeniedError } from '@/utils/errorUtils';

const ManageParticipants: React.FC = () => {
  const [showModal] = useModal();
  const [{ studyPermissions }] = usePermissions();
  const { studyUid } = useParams<UseParamsType>();
  const [generateCRFsLoading, setGenerateCRFsLoading] = useState(false);

  const { loading, data, error } = useQuery<QueryStudyParticipantsArgs>(STUDY_QUERY_PARTICIPANT_DATA, {
    variables: {
      studyUid,
    },
  });
  const refetchQueries = [
    {
      query: STUDY_QUERY_PARTICIPANT_DATA,
      variables: {
        studyUid,
      },
    },
  ];
  const [resetAccessAttemptsMutation] = useMutation<MutationResetAccessAttemptsArgs>(
    RESET_ACCESS_ATTEMPTS, { refetchQueries },
  );
  const resetAccessAttempts = (userUid: string): Promise<MutationResultType> => (
    resetAccessAttemptsMutation({
      variables: {
        userUid,
        studyUid,
      },
    })
  );

  const [generateCRFMutation] = useMutation<MutationGenerateCaseReportFormArgs>(
    GENERATE_PARTICIPANT_CRF, { refetchQueries },
  );
  const generateCRF = (userUid: string): Promise<MutationResultType> => (
    generateCRFMutation({
      variables: {
        userUid,
        studyUid,
      },
    })
  );

  const [generateCRFsMutation] = useMutation<MutationGenerateCaseReportFormsArgs>(
    GENERATE_STUDY_CRFS, { refetchQueries },
  );

  const permissions = studyPermissions.find((studyPermission) => studyPermission.uid === studyUid);

  if (loading) {
    return <LoadingSpinner />;
  }

  if (isPermissionDeniedError(error)
    || error?.message === 'LimbixStudy matching query does not exist.') {
    return <Redirect to="/research" />;
  }

  const onPressAddNewParticipant = () => showModal(
    'REGISTER_NEW_USER',
    {
      studyUid,
    },
  );
  const onPressAddExistingParticipant = () => showModal(
    'REGISTER_EXISTING_USER',
    {
      studyUid,
    },
  );
  const study = data?.limbixStudy;
  const studyParticipants = study?.studyParticipants || [];
  const canGenerateCRFs = !!study?.caseReportFormTemplate && !permissions.isBlinded;

  const onPressGenerateCRFs = async () => {
    setGenerateCRFsLoading(true);
    const result = await generateCRFsMutation({ variables: { studyUid } });
    if (result.errors) {
      showModal(
        'ERROR_POPUP',
        {
          header: 'Error while generating CRFs:',
          errors: result.errors.map((mutationError) => mutationError.message),
        },
      );
    } else {
      const crfURL = result?.data?.generateCaseReportForms?.url;
      if (crfURL) {
        window.open(crfURL, '_blank');
      }
    }

    setGenerateCRFsLoading(false);
  };

  const generateCRFsButton = generateCRFsLoading
    ? <LoadingSpinner /> : (
      <Button
        onClick={onPressGenerateCRFs}
        style={{ marginBottom: 16 }}
        variant="contained"
        color="primary"
        disabled={generateCRFsLoading}
      >
        Download Case Report Forms
      </Button>
    );

  const accountTypeValue = (accountType: LimbixUserAccountType) => {
    if (accountType === 'CHILD' || accountType === 'ADULT') {
      return 1;
    }
    return -1;
  };

  const sortedStudyParticipants = [...studyParticipants].sort((a, b) => {
    if (accountTypeValue(a.user.accountType) === accountTypeValue(b.user.accountType)) {
      return a.createdAt > b.createdAt ? 1 : -1;
    }
    if (accountTypeValue(a.user.accountType) > accountTypeValue(b.user.accountType)) {
      return a.createdAt < b.createdAt ? 1 : -1;
    }
    return a.createdAt > b.createdAt ? 1 : -1;
  });

  const addParticipantsButtons = (
    <>
      <Button
        onClick={onPressAddNewParticipant}
        style={{ marginBottom: 16, marginRight: 16 }}
        variant="contained"
        color="primary"
      >
        Add new participant
      </Button>
      <Button
        onClick={onPressAddExistingParticipant}
        style={{ marginBottom: 16, marginRight: 16 }}
        variant="contained"
        color="primary"
      >
        Add existing participant
      </Button>
    </>
  );

  return (
    <Box width="100%">
      <Text as="h1">
        {study?.name}
      </Text>
      {permissions.canModifyParticipants && addParticipantsButtons}
      {permissions.canViewStudy && canGenerateCRFs && generateCRFsButton}
      <ParticipantTable
        studyUid={studyUid}
        studyParticipants={sortedStudyParticipants}
        studyPermissions={permissions}
        resetAccessAttempts={resetAccessAttempts}
        generateCRF={canGenerateCRFs ? generateCRF : null}
      />
    </Box>
  );
};

export default ManageParticipants;
