import React from 'react';

import { Button } from '@material-ui/core';

import { Redirect } from 'react-router-dom';

import { Link } from 'react-limbix-ui';

import { formatDate } from '@/utils/dateUtils';
import { useMutation } from '@/hooks/apollo';
import { MutationAssignClinicianReportArgs } from '@/apollo/types';
import { PARTICIPANT_CLINICAL_CONCERNS_QUERY, STUDY_CLINICAL_CONCERNS_QUERY } from '@/apollo/queries';
import { ASSIGN_CLINICIAN_REPORT } from '@/apollo/mutations';
import { useModal } from '@/hooks/redux';

type Props = {
  studyUid: string;
  clinicalConcernUid: string;
  participantUserUid: string;
  clinicianReportUid?: string;
  completeTime?: Date;
}

const ClinicianReport: React.FC<Props> = (props:Props) => {
  const {
    completeTime, clinicianReportUid, participantUserUid, clinicalConcernUid, studyUid,
  } = props;
  const [showModal] = useModal();

  const refetchQueries = [
    {
      query: STUDY_CLINICAL_CONCERNS_QUERY,
      variables: {
        studyUid,
      },
    },
    {
      query: PARTICIPANT_CLINICAL_CONCERNS_QUERY,
      variables: {
        studyUid,
        userUid: participantUserUid,
      },
    },
  ];

  const [assignClinicianReport, { loading }] = useMutation<MutationAssignClinicianReportArgs>(
    ASSIGN_CLINICIAN_REPORT, { refetchQueries },
  );

  const getReportLink = (reportUid: string) => {
    const baseUrl = `/research/study/${studyUid}/participant/${participantUserUid}/research_assignments`;
    return `${baseUrl}/${reportUid}`;
  };

  if (clinicianReportUid) {
    return (
      <Link.RouterLink target="_blank" to={getReportLink(clinicianReportUid)} fontSize="18px">
        {completeTime ? `Completed at ${formatDate(completeTime)}` : 'Complete Report'}
      </Link.RouterLink>
    );
  }

  const handleClickAssignReport = async () => {
    const result = await assignClinicianReport({
      variables: {
        clinicalConcernUid,
        studyUid,
      },
    });

    if (result.errors) {
      showModal(
        'ERROR_POPUP',
        {
          header: 'Error while assigning clinician report:',
          errors: result.errors.map((mutationError) => mutationError.message),
        },
      );
    } else {
      const clinicianReportAssignmentUid = result?.data?.assignClinicianReport?.clinicalConcern?.clinicianReport?.uid;
      return <Redirect to={getReportLink(clinicianReportAssignmentUid)} />;
    }
    return null;
  };

  return (
    <Button
      onClick={handleClickAssignReport}
      variant="outlined"
      disabled={loading}
    >
      Create Report
    </Button>
  );
};

export default ClinicianReport;
