import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components/macro';
import { Box, Text } from 'react-limbix-ui';

import { AssignmentTypeOfClient } from '@/apollo/types';
import { stringToBase64 } from '@/utils/stringUtils';
import { useLoginMutation } from '@/hooks/apollo/mutations';
import { setAnalyticsFields } from '@/utils/analytics';
import history from '@/utils/history';

const StyledForm = styled.form`
  width: 100%;
  margin-top: 50px;
`;

const SubmitButton = styled(Button)`
  margin-top: 20px;
  background-color: #556cd6;
`;

type Props = RouteComponentProps;

const Login: React.FC<Props> = (props: Props) => {
  const { location } = props;
  const [formValues, setFormValues] = useState({ email: '', password: '', mfaCode: '' });
  const [redirectTo, setRedirectTo] = useState<string>(null);
  const [error, setError] = useState<string>();
  const requestToken = useLoginMutation({ fetchPolicy: 'no-cache' });

  // If they manually go to the login page or get redirected back out to the login page
  // clear their local storage
  if (localStorage.getItem('token') || localStorage.getItem('refreshToken')) {
    localStorage.clear();
  }

  const newReferrer = (location?.state as { referrer: string })?.referrer;

  if (!redirectTo && newReferrer && newReferrer !== '/login') {
    setRedirectTo(newReferrer);
  }

  const loginMutation = async () => {
    const { email, password, mfaCode } = formValues;
    const result = await requestToken({
      variables: {
        email,
        password,
        mfaCode,
        clientType: AssignmentTypeOfClient.WebPortal,
      },
    });
    if (result.errors?.length > 0 && result.errors[0]?.message) {
      setError(result.errors[0].message);
      return;
    }
    if (result.data?.tokenAuthMfa) {
      const { tokenAuthMfa: { token, refreshToken, user } } = result.data;
      if (!(user.isOperations || user.isResearch)) {
        setError('User is not marked as "Operations" or "Research"');
        return;
      }
      if (refreshToken) {
        localStorage.setItem('refreshToken', refreshToken);
      }
      if (token) {
        const uid = user?.uid || '';
        setAnalyticsFields({ userId: uid });
        localStorage.setItem('tokenCreatedAt', stringToBase64(`${(new Date()).getTime()}`));
        localStorage.setItem('token', token);
        localStorage.setItem('uid', uid);
        history.push(redirectTo || '/');
      }
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      loginMutation();
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box marginTop="10px" display="flex" flexDirection="column" alignItems="center" textAlign="center">
        <StyledForm noValidate>
          <Text as="h1">
            ResOps Login
          </Text>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoFocus
            onKeyDown={handleKeyDown}
            onChange={(event) => setFormValues({
              ...formValues,
              email: event.target.value,
            })}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            onKeyDown={handleKeyDown}
            onChange={(event) => setFormValues({
              ...formValues,
              password: event.target.value,
            })}
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            name="mfaCode"
            label="2 Factor Code"
            id="mfaCode"
            onKeyDown={handleKeyDown}
            onChange={(event) => setFormValues({
              ...formValues,
              mfaCode: event.target.value,
            })}
          />
          <a href="/reset-password">reset password</a>
          <Text color="red">
            {error}
          </Text>
          <SubmitButton
            type="button"
            fullWidth
            variant="contained"
            color="primary"
            onClick={() => loginMutation()}
          >
            Login
          </SubmitButton>
        </StyledForm>
      </Box>
    </Container>
  );
};

export default withRouter(Login);
