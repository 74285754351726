import {
  NavigationType,
  NavigationActionTypes,
  NavigationAction,
} from '@/types/ReduxTypes';

export type NavigationStateType = NavigationType;

export const defaultNavigationState: NavigationStateType = {
  isBlocked: false,
  message: null,
  listeners: [],
};

const navigationState: NavigationStateType = { ...defaultNavigationState };

const NavigationReducer = (
  state: NavigationStateType = navigationState,
  dispatched: NavigationAction,
): NavigationStateType => {
  switch (dispatched.type) {
  case NavigationActionTypes.blockNavigationOnEvent:
    return {
      ...state,
      isBlocked: false,
      message: dispatched.payload.message,
      listeners: dispatched.payload.listeners,
    };
  case NavigationActionTypes.eventOccurred:
    return {
      ...state,
      isBlocked: dispatched.payload.value,
    };
  case NavigationActionTypes.blockNavigation:
    return {
      ...state,
      isBlocked: true,
      message: dispatched.payload.message,
      listeners: [],
    };
  case NavigationActionTypes.unblockNavigation:
    return {
      ...state,
      isBlocked: false,
      message: null,
      listeners: [],
    };
  default:
    return state;
  }
};

export default NavigationReducer;
