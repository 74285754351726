import React, { useState, createContext, useEffect } from 'react';
import { Dialog } from '@material-ui/core';
import { useSelector } from 'react-redux';

import Transition from './Transition';
import IdleWarning from './IdleWarning';
import ContactInfo from './ContactInfo';
import TransitionToAdult from './TransitionToAdult';
import DeleteParticipant from './DeleteParticipant';
import StudyActions from './StudyActions';
import RevokeAccess from './RevokeAccess';
import ChangeStudyName from './ChangeStudyName';
import ClinicalConcerns from './ClinicalConcerns';
import AddStudyPersonnel from './AddStudyPersonnel';
import RemoveStudyPersonnel from './RemoveStudyPersonnel';
import ParticipantNotes from './ParticipantNotes';
import AllContactInfo from './AllContactInfo';
import RegisterNewUser from './RegisterNewUser';
import EditParents from './EditParents';
import ErrorPopup from './ErrorPopup';
import UpdatePid from './UpdatePid';
import RegisterExistingUser from './RegisterExistingUser';
import PatientNotes from './PatientNotes';
import PractitionerNotes from './PractitionerNotes';
import AccessCodes from './AccessCodes';
import RevokePatientAccess from './RevokePatientAccess';
import ConfirmationPopup from './ConfirmationPopup';
import ViewPatients from './ViewPatients';
import ViewProviders from './ViewProviders';
import PatientContactInfo from './PatientContactInfo';
import OrganizationInfo from './OrganizationInfo';
import DataGridConfirmation from './DataGridConfirmation';
import ConfirmationPopupChangeReason from './ConfirmationPopupChangeReason';
import GenericWarning from './GenericWarning';
import PortalAccess from './PortalAccess';
import GenericModal from './GenericModal';

import { RootState } from '@/store';
import { ModalContentType } from '@/types';
import { ModalPayloadType } from '@/types/ReduxTypes';
import { useModal } from '@/hooks/redux';

type ModalLoadingContextType = {
  modalLoading: boolean,
  setModalLoading: (loading: boolean) => void,
};
export const ModalLoadingContext = createContext<ModalLoadingContextType>(null);

type Props<ModalType extends ModalContentType> = {
  payload: ModalPayloadType[ModalType];
  onClose: () => void;
};

const modalTypeToModal: { [key in ModalContentType]: React.FC<Props<key>>} = {
  IDLE_WARNING: IdleWarning,
  TRANSITION_TO_ADULT: TransitionToAdult,
  DELETE_PARTICIPANT: DeleteParticipant,
  CONTACT_INFO: ContactInfo,
  REVOKE_ACCESS: RevokeAccess,
  STUDY_ACTIONS: StudyActions,
  REGISTER_NEW_USER: RegisterNewUser,
  REGISTER_EXISTING_USER: RegisterExistingUser,
  CHANGE_STUDY_NAME: ChangeStudyName,
  REMOVE_STUDY_PERSONNEL: RemoveStudyPersonnel,
  ADD_STUDY_PERSONNEL: AddStudyPersonnel,
  CLINICAL_CONCERNS: ClinicalConcerns,
  PARTICIPANT_NOTES: ParticipantNotes,
  EDIT_PARENTS: EditParents,
  ERROR_POPUP: ErrorPopup,
  ALL_CONTACT_INFO: AllContactInfo,
  UPDATE_PID: UpdatePid,
  CONFIRMATION_POPUP: ConfirmationPopup,
  PATIENT_NOTES: PatientNotes,
  PRACTITIONER_NOTES: PractitionerNotes,
  PORTAL_ACCESS: PortalAccess,
  ACCESS_CODES: AccessCodes,
  VIEW_PATIENTS: ViewPatients,
  VIEW_PROVIDERS: ViewProviders,
  PATIENT_CONTACT_INFO: PatientContactInfo,
  REVOKE_PATIENT_ACCESS: RevokePatientAccess,
  ORGANIZATION_INFO: OrganizationInfo,
  DATA_GRID_CONFIRMATION: DataGridConfirmation,
  CONFIRMATION_POPUP_CHANGE_REASON: ConfirmationPopupChangeReason,
  GENERIC_WARNING: GenericWarning,
  GENERIC_MODAL: GenericModal,
  NONE: () => <></>,
};

const Modal: React.FC = () => {
  const { visible, modalType, payload } = useSelector((state: RootState) => ({
    visible: state.modal.visible,
    modalType: state.modal.modalType,
    payload: state.modal.payload,
  }));
  const [, hideModal] = useModal();
  const [modalLoading, setModalLoading] = useState(false);

  useEffect(() => () => {
    hideModal();
  }, []);

  if (!visible) {
    return null;
  }

  const renderModalContent = () => {
    const ModalToRender = modalTypeToModal[modalType] as React.FC<Props<typeof modalType>>;
    return <ModalToRender payload={payload} onClose={hideModal} />;
  };

  const handleClose = () => {
    if (!modalLoading) {
      hideModal();
    }
  };

  const value = {
    modalLoading,
    setModalLoading,
  };

  return (
    <ModalLoadingContext.Provider value={value}>
      <Dialog
        onClose={handleClose}
        aria-labelledby="dialog"
        open={visible}
        TransitionComponent={Transition}
      >
        {renderModalContent()}
      </Dialog>
    </ModalLoadingContext.Provider>
  );
};

export default Modal;
