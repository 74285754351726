import React, { useState } from 'react';
import 'date-fns';
import { TextField, MenuItem } from '@material-ui/core';
import { GraphQLError } from 'graphql';

import Styled from '../Modal.styles';

import { STUDY_QUERY_PARTICIPANT_DATA } from '@/apollo/queries';
import { REGISTER_NEW_USER } from '@/apollo/mutations';
import { MutationRegisterNewUserArgs } from '@/apollo/types';
import { MutationResultType, GenderType } from '@/types';
import ExclamationSVG from '@/assets/ExclamationCircle.svg';
import { ModalPayloadType } from '@/types/ReduxTypes';
import { formatDate } from '@/utils/dateUtils';
import { useMutation } from '@/hooks/apollo';

type Props = {
  payload: ModalPayloadType['REGISTER_NEW_USER'];
  onClose: () => void;
};
const RegisterNewUser: React.FC<Props> = (props: Props) => {
  const {
    onClose,
    payload,
  } = props;

  const { studyUid } = payload;

  const [email, setEmail] = useState('');
  const [accountType, setAccountType] = useState('ADULT');
  const [firstName, setFirstName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [gender, setGender] = useState<string>(GenderType.None);
  const [dateOfBirth, setDateOfBirth] = useState(new Date());
  const [referralSource, setReferralSource] = useState('');
  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');
  const [mutationErrors, setMutationErrors] = useState<readonly GraphQLError[]>(null);
  const [isConfirming, setIsConfirming] = useState(false);

  const refetchQueries = [
    {
      query: STUDY_QUERY_PARTICIPANT_DATA,
      variables: {
        studyUid,
      },
    },
  ];
  const [registerNewUserMutation] = useMutation<MutationRegisterNewUserArgs>(
    REGISTER_NEW_USER, { refetchQueries },
  );
  const registerNewUser = (args: MutationRegisterNewUserArgs): Promise<MutationResultType> => (
    registerNewUserMutation({ variables: args })
  );

  const passwordsMatch = password1 === password2;
  const fieldsFilled = email && accountType && firstName && phoneNumber && password1 && password2;
  const formValid = passwordsMatch && fieldsFilled;

  const submitAndClose = async () => {
    const result = await registerNewUser({
      studyUid,
      email,
      accountType,
      firstName,
      phoneNumber,
      password: password1,
      gender: gender || null,
      dateOfBirth,
      referralSource,
    });
    if (result.errors) {
      setMutationErrors(result.errors);
    } else {
      onClose();
    }
  };

  const emailField = (
    <TextField
      variant="outlined"
      margin="normal"
      fullWidth
      name="email"
      label="Email"
      id="email"
      type="email"
      value={email}
      onChange={(event) => setEmail(event.target.value)}
    />
  );

  const accountTypes = [
    { value: 'ADULT', label: 'Adult' },
    { value: 'CHILD', label: 'Child' },
    { value: 'PARENT', label: 'Parent' },
  ];

  const accountTypeField = (
    <TextField
      select
      variant="outlined"
      margin="normal"
      fullWidth
      name="accountType"
      label="Account type"
      id="accountType"
      defaultValue="ADULT"
      value={accountType}
      onChange={(event) => setAccountType(event.target.value)}
    >
      {accountTypes.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  );

  const firstNameField = (
    <TextField
      variant="outlined"
      margin="normal"
      fullWidth
      name="firstName"
      label="First name"
      id="firstName"
      value={firstName}
      onChange={(event) => setFirstName(event.target.value)}
    />
  );

  const phoneNumberField = (
    <TextField
      variant="outlined"
      margin="normal"
      fullWidth
      name="phoneNumber"
      label="Phone number"
      id="phoneNumber"
      value={phoneNumber}
      onChange={(event) => setPhoneNumber(event.target.value)}
    />
  );

  const genderField = (
    <TextField
      select
      variant="outlined"
      margin="normal"
      fullWidth
      name="gender"
      label="Gender"
      id="gender"
      defaultValue="O"
      value={gender}
      onChange={(event) => setGender(event.target.value as GenderType)}
    >
      {Object.entries(GenderType).map(([key, value]) => (
        <MenuItem key={key} value={value}>
          {key}
        </MenuItem>
      ))}
    </TextField>
  );

  // due to precision difference on TextField type 'datetime-local' and our cortex datetimes
  // we must use .substring(0, 16) to remove extra decimal places from microseconds to make it milliseconds
  // otherwise the date cannot be displayed

  const isDOBParsable = dateOfBirth && dateOfBirth instanceof Date && Number.isFinite(dateOfBirth.valueOf());

  const dateOfBirthField = (
    <TextField
      id="datetime-local"
      error={!isDOBParsable}
      helperText={!isDOBParsable && 'Please enter valid date'}
      label="Date of Birth"
      type="datetime-local"
      variant="outlined"
      margin="normal"
      fullWidth
      defaultValue={isDOBParsable ? dateOfBirth.toISOString().substring(0, 16) : dateOfBirth}
      InputLabelProps={{
        shrink: true,
      }}
      onChange={(event) => setDateOfBirth(new Date(event.target.value))}
    />
  );

  const referralSourceField = (
    <TextField
      variant="outlined"
      margin="normal"
      fullWidth
      name="referralSource"
      label="Referral source"
      id="referralSource"
      value={referralSource}
      onChange={(event) => setReferralSource(event.target.value)}
    />
  );

  const passwordField = (
    <TextField
      variant="outlined"
      margin="normal"
      fullWidth
      name="password"
      label="Password"
      id="password"
      type="password"
      value={password1}
      onChange={(event) => setPassword1(event.target.value)}
    />
  );

  const passwordConfirmationField = (
    <TextField
      error={!passwordsMatch}
      helperText={passwordsMatch ? null : 'Passwords must match'}
      variant="outlined"
      margin="normal"
      fullWidth
      name="passwordConfirmation"
      label="Confirm password"
      id="passwordConfirmation"
      type="password"
      value={password2}
      onChange={(event) => setPassword2(event.target.value)}
    />
  );

  const formModalContent = (
    <>
      <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 16 }}>
        <Styled.HeaderText style={{ marginBottom: 32 }}>
          {`Register new user in Study ${studyUid}`}
        </Styled.HeaderText>
        { emailField }
        { accountTypeField }
        { firstNameField }
        { phoneNumberField }
        { genderField }
        { dateOfBirthField }
        { referralSourceField }
        { passwordField }
        { passwordConfirmationField }
      </div>
      <div style={{ display: 'flex' }}>
        <Styled.NoButton onClick={onClose}>
          <Styled.NoButtonText>
            Cancel
          </Styled.NoButtonText>
        </Styled.NoButton>
        <Styled.YesButton
          style={{ backgroundColor: formValid ? null : 'gray' }}
          onClick={() => setIsConfirming(true)}
          disabled={!formValid}
          color="primary"
        >
          <Styled.YesButtonText>
            Submit
          </Styled.YesButtonText>
        </Styled.YesButton>
      </div>
    </>
  );

  const fields = [
    { label: 'Email', value: email },
    { label: 'Account type', value: accountType },
    { label: 'First name', value: firstName },
    { label: 'Phone number', value: phoneNumber },
    { label: 'Gender', value: gender || 'n/a' },
    { label: 'Date of Birth', value: formatDate(dateOfBirth) },
    { label: 'Referral Source', value: referralSource },
    { label: 'Password', value: password1 },
  ];

  const confirmModalContent = (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <div style={{ marginRight: 16, marginTop: 4 }}>
        <Styled.WarningSVG src={ExclamationSVG} />
      </div>
      <div>
        <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 16 }}>
          <Styled.HeaderText style={{ marginBottom: 16 }}>
            Confirm New User Information
          </Styled.HeaderText>
          {fields.map(({ label, value }) => (
            <p key={label}>{`${label}: ${value}`}</p>
          ))}
          {mutationErrors && (
            <div>
              {mutationErrors.map((error) => error.message)}
            </div>
          )}
        </div>
        <div style={{ display: 'flex' }}>
          <Styled.NoButton
            onClick={() => {
              setIsConfirming(false);
              setMutationErrors([]);
            }}
          >
            <Styled.NoButtonText>
              Back
            </Styled.NoButtonText>
          </Styled.NoButton>
          <Styled.YesButton
            onClick={submitAndClose}
            color="primary"
          >
            <Styled.YesButtonText>
              Confirm
            </Styled.YesButtonText>
          </Styled.YesButton>
        </div>
      </div>
    </div>
  );

  return (
    <div style={{ width: '416px', height: '75%' }}>
      <Styled.DialogContent>
        { isConfirming ? confirmModalContent : formModalContent }
      </Styled.DialogContent>
    </div>
  );
};

export default RegisterNewUser;
