import React from 'react';
import { useParams, Redirect } from 'react-router-dom';
import { Button } from '@material-ui/core';
import {
  Table,
  LoadingSpinner,
  TableColumn as Column,
  TableRow as Row,
  SortState,
  Box,
  Text,
} from 'react-limbix-ui';

import { QueryLimbixStudyArgs } from '@/apollo/types';
import { DETERIORATION_ALERTS_QUERY } from '@/apollo/queries';
import {
  UseParamsType,
  DeteriorationAlertsType,
} from '@/types';
import { createColumn, createCell, renderPID } from '@/utils/tableUtils';
import { formatDate } from '@/utils/dateUtils';
import { useModal, usePermissions } from '@/hooks/redux';
import { useQuery } from '@/hooks/apollo';
import { isPermissionDeniedError } from '@/utils/errorUtils';

const DeteriorationAlerts: React.FC = () => {
  const { studyUid } = useParams<UseParamsType>();
  const [showModal] = useModal();
  const [{ studyPermissions }] = usePermissions();
  const { loading, data, error } = useQuery<QueryLimbixStudyArgs>(DETERIORATION_ALERTS_QUERY, {
    variables: {
      studyUid,
    },
  });

  const permissions = studyPermissions.find((study) => study.uid === studyUid);

  if (loading) {
    return <LoadingSpinner />;
  }

  if (isPermissionDeniedError(error)) {
    return <Redirect to="/research" />;
  }

  const { limbixStudy } = { ...data };
  const deteriorationAlerts: DeteriorationAlertsType[] = limbixStudy.deteriorationAlerts.map(
    (alert: string): DeteriorationAlertsType => {
      const parsed = JSON.parse(alert);
      return {
        createdAt: new Date(parsed.created_at),
        userUid: parsed.user_uid,
        participantId: parsed.pid,
        baseline: parsed.baseline,
        recentScore: parsed.score,
        scoreDifference: parsed.diff,
      };
    },
  );

  if (deteriorationAlerts.length === 0) {
    return (
      <Text as="h1">
        {'It seems there aren\'t any deterioration alerts'}
        <br />
        If that seems like a mistake please reach out to Research and Eng
      </Text>
    );
  }

  const renderClinicalConcerns = (userUid: string, participantId: string) => (
    <Button
      variant="contained"
      onClick={() => showModal(
        'CLINICAL_CONCERNS',
        {
          userUid,
          participantId,
          studyUid,
          navigate: true,
        },
      )}
    >
      Clinical Concern
    </Button>
  );

  const columns: Column[] = [
    createColumn('pid', 'PID', 'string', null, true),
    createColumn('dateCreated', 'Date Created', 'date', null, true),
    createColumn('baseline', 'Baseline PHQ Score', 'number', null, true),
    createColumn('deteriorated', 'Deteriorated Score', 'number', null, true),
    createColumn('difference', 'Score Difference', 'date', null, true),
  ];

  if (permissions.canModifyParticipants && !!limbixStudy.clinicalConcernAssessment?.name) {
    columns.push(createColumn('clinicalConcerns', 'Clinical Concerns', 'string', null, false));
  }

  const rows: Row[] = deteriorationAlerts.map((alert, index) => {
    const row: Row = {
      id: `${index}`,
      cells: [
        createCell('pid', alert.userUid, renderPID(studyUid, alert.userUid, alert.participantId)),
        createCell('dateCreated', alert.createdAt, formatDate(alert.createdAt)),
        createCell('baseline', alert.baseline, alert.baseline),
        createCell('deteriorated', alert.recentScore, alert.recentScore),
        createCell('difference', alert.scoreDifference, alert.scoreDifference),
      ],
    };
    if (permissions.canModifyParticipants) {
      row.cells.push(
        createCell(
          'clinicalConcerns',
          'Clinical Concerns',
          renderClinicalConcerns(alert.userUid, alert.participantId),
        ),
      );
    }
    return row;
  });

  const defaultSortState: SortState = { col: 'dateCreated', direction: 'desc' };

  return (
    <Box>
      <Table columns={columns} rows={rows} defaultSortState={defaultSortState} />
    </Box>
  );
};

export default DeteriorationAlerts;
