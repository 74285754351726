import { gql } from '@apollo/client';

export const VERIFY_TOKEN_MUTATION = gql`
  mutation VerifyToken($token: String!) {
    verifyToken(token: $token) {
      payload
    }
  }
`;

export const REFRESH_TOKEN_MUTATION = gql`
  mutation RefreshToken($refreshToken: String!) {
    refreshToken(refreshToken: $refreshToken) {
      token
      refreshToken
    }
  }
`;

export const CREATE_TOTP_AUTH_MUTATION = gql`
  mutation CreateTotpAuthenticator {
    createTotpAuthenticator {
      totpAuthenticator {
        qrcodeSvg
      }
    }
  }
`;

export const EDIT_PARTICIPANT_METADATA = gql`
  mutation EditParticipantMetadata(
    $userUid: String!,
    $studyUid: String!,
    $metadata: JSONString!,
    $changeReason: String,
  ) {
    editParticipantMetadata(userUid: $userUid, studyUid: $studyUid, metadata: $metadata, changeReason: $changeReason) {
      success
    }
  }
`;

export const RESET_ACCESS_ATTEMPTS = gql`
  mutation ResetAccessAttempts($userUid: String!, $studyUid: String!) {
    resetAccessAttempts(userUid: $userUid, studyUid: $studyUid) {
      success
    }
  }
`;

export const RESEND_VERIFICATION_EMAIL = gql`
  mutation ResendVerificationEmail($email: String!) {
    resendEmailVerification(email: $email) {
      success
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation ResetPassword($email: String!) {
    resetPassword(email: $email) {
      success
    }
  }
`;

export const REVOKE_MOBILE_ACCESS = gql`
  mutation RevokeMobileAccess($userUid: String!, $studyUid: String!) {
    revokeMobileAccess(userUid: $userUid, studyUid: $studyUid) {
      success
    }
  }
`;

export const TRANSITION_TO_ADULT = gql`
  mutation TransitionToAdult($userUid: String!, $studyUid: String!, $changeReason: String!) {
    transitionToAdult(userUid: $userUid, studyUid: $studyUid, changeReason: $changeReason) {
      success
    }
  }
`;

export const DELETE_PARTICIPANT = gql`
  mutation DeleteParticipant($userUid: String!, $studyUid: String!, $changeReason: String!) {
    deleteParticipant(userUid: $userUid, studyUid: $studyUid, changeReason: $changeReason) {
      success
    }
  }
`;

export const REGISTER_NEW_USER = gql`
  mutation RegisterNewUser(
    $email: String!,
    $password: String!,
    $firstName: String!,
    $accountType: String!,
    $phoneNumber: String!,
    $dateOfBirth: DateTime!,
    $studyUid: String!,
    $referralSource: String
    $gender: String,
  ) {
    registerNewUser(
      email: $email,
      password: $password,
      firstName: $firstName,
      accountType: $accountType,
      phoneNumber: $phoneNumber,
      dateOfBirth: $dateOfBirth,
      studyUid: $studyUid,
      referralSource: $referralSource,
      gender: $gender,
    ) {
      success
    }
  }
`;

export const REGISTER_EXISTING_USER = gql`
  mutation RegisterExistingUser(
    $studyUid: String!,
    $userEmail: String,
    $userUid: String,
    $referralSource: String!
  ) {
    registerExistingUser(
      studyUid: $studyUid,
      userEmail: $userEmail,
      userUid: $userUid,
      referralSource: $referralSource,
    ) {
      success
    }
  }
`;

export const ACTIVATE_PARTICIPANT = gql`
  mutation ActivateParticipant($userUid: String!, $studyUid: String!) {
    activateParticipant(userUid: $userUid, studyUid: $studyUid) {
      success
    }
  }
`;

export const COMPLETE_PARTICIPANT = gql`
  mutation CompleteParticipant($userUid: String!, $studyUid: String!) {
    completeParticipant(userUid: $userUid, studyUid: $studyUid) {
      success
    }
  }
`;

export const EDIT_PARENTS = gql`
  mutation EditParents($childUid: String!, $parentUids: [String!]!, $studyUid: String!, $changeReason: String!) {
    editParents(childUid: $childUid, parentUids: $parentUids, studyUid: $studyUid, changeReason: $changeReason) {
      success
    }
  }
`;

export const DROP_PARTICIPANT = gql`
  mutation DropParticipant($userUid: String!, $studyUid: String!, $changeReason: String!) {
    dropParticipant(userUid: $userUid, studyUid: $studyUid, changeReason: $changeReason) {
      success
    }
  }
`;

export const WITHDRAW_PARTICIPANT = gql`
  mutation WithdrawParticipant($userUid: String!, $studyUid: String!, $changeReason: String!) {
    withdrawParticipant(userUid: $userUid, studyUid: $studyUid, changeReason: $changeReason) {
      success
    }
  }
`;

export const MARK_PARTICIPANT_AS_LOST = gql`
  mutation MarkParticipantAsLost($userUid: String!, $studyUid: String!, $changeReason: String!) {
    markParticipantAsLost(userUid: $userUid, studyUid: $studyUid, changeReason: $changeReason) {
      success
    }
  }
`;

export const MARK_PARTICIPANT_SCREEN_FAIL = gql`
  mutation MarkParticipantAsScreenFail($userUid: String!, $studyUid: String!, $changeReason: String!) {
    markParticipantAsScreenFail(userUid: $userUid, studyUid: $studyUid, changeReason: $changeReason) {
      success
    }
  }
`;

export const REMOVE_STUDY_COORDINATOR = gql`
  mutation RemoveStudyCoordinator($userUid: String!, $studyUid: String!) {
    removeStudyCoordinator(userUid: $userUid, studyUid: $studyUid) {
      success
    }
  }
`;

export const REMOVE_STUDY_CO_INVESTIGATOR = gql`
  mutation RemoveStudyCoInvestigator($userUid: String!, $studyUid: String!) {
    removeStudyCoInvestigator(userUid: $userUid, studyUid: $studyUid) {
      success
    }
  }
`;

export const ADD_STUDY_COORDINATOR = gql`
  mutation AddStudyCoordinator($userEmail: String!, $studyUid: String!) {
    addStudyCoordinator(userEmail: $userEmail, studyUid: $studyUid) {
      success
    }
  }
`;

export const ADD_STUDY_CO_INVESTIGATOR = gql`
  mutation AddStudyCoInvestigator($userEmail: String!, $studyUid: String!) {
    addStudyCoInvestigator(userEmail: $userEmail, studyUid: $studyUid) {
      success
    }
  }
`;

export const UPDATE_STUDY_NAME = gql`
  mutation UpdateStudyName($studyUid: String!, $name: String!) {
    updateStudyName(studyUid: $studyUid, name: $name) {
      success
    }
  }
`;

export const EDIT_PARTICIPANT_NOTES = gql`
  mutation EditParticipantNotes($userUid: String!, $studyUid: String!, $notes: String!, $changeReason: String!) {
    editParticipantNotes(userUid: $userUid, studyUid: $studyUid, notes: $notes, changeReason: $changeReason) {
      success
    }
  }
`;

export const GENERATE_PARTICIPANT_CRF = gql`
  mutation GenerateCaseReportForm($userUid: String!, $studyUid: String!) {
    generateCaseReportForm(userUid: $userUid, studyUid: $studyUid) {
      url
    }
  }
`;

export const GENERATE_STUDY_CRFS = gql`
  mutation GenerateCaseReportForms($studyUid: String!) {
    generateCaseReportForms(studyUid: $studyUid) {
      url
    }
  }
`;

export const CREATE_CLINICAL_CONCERN = gql`
  mutation CreateClinicalConcern($studyUid: String!, $userUid: String!) {
    createClinicalConcern(studyUid: $studyUid, userUid: $userUid) {
      clinicalConcern {
        uid
        createdAt
      }
    }
  }
`;

export const REPORT_CLINICAL_CONCERN = gql`
  mutation ReportClinicalConcern(
    $clinicalConcernUid: String!,
    $studyUid: String!,
    $assessmentAnswers: [String]!,
    $changeReason: String!,
  ) {
    reportClinicalConcern(
      clinicalConcernUid: $clinicalConcernUid,
      studyUid: $studyUid,
      assessmentAnswers: $assessmentAnswers,
      changeReason: $changeReason,
    ) {
      clinicalConcern {
        uid
      }
    }
  }
`;

export const GENERATE_CLINICAL_CONCERN_CSV = gql`
  mutation GenerateClinicalConcernsCsv($studyUid: String!, $userUid: String, $participantAttributes: JSONString) {
    generateClinicalConcernsCsv(studyUid: $studyUid, userUid: $userUid, participantAttributes: $participantAttributes) {
      url
    }
  }
`;

export const UPDATE_PARTICIPANT_PID = gql`
  mutation UpdateParticipantPid(
    $userUid: String!,
    $studyUid: String!,
    $participantId: String!,
    $changeReason: String!
  ) {
    updateParticipantPid(
      userUid: $userUid,
      studyUid: $studyUid,
      participantId: $participantId,
      changeReason: $changeReason
    ) {
      success
    }
  }
`;

export const UPDATE_ENROLLMENT_TRACKER = gql`
  mutation UpdateEnrollmentTracker(
    $enrollmentTrackerResultUid: String!,
    $studyUid: String!,
    $assessmentAnswers: [String]!,
    $changeReason: String!,
  ) {
    updateEnrollmentTracker(
      enrollmentTrackerResultUid: $enrollmentTrackerResultUid,
      studyUid: $studyUid,
      assessmentAnswers: $assessmentAnswers,
      changeReason: $changeReason,
    ) {
      enrollmentTrackerResult {
        uid
      }
    }
  }
`;

export const RESET_PATIENT_ACCESS_ATTEMPTS = gql`
  mutation ResetPatientAccessAttempts($patientUid: String!, $pdtUid: String!) {
    resetPatientAccessAttempts(patientUid: $patientUid, pdtUid: $pdtUid) {
      success
    }
  }
`;

export const REVOKE_PATIENT_MOBILE_ACCESS = gql`
  mutation RevokePatientMobileAccess($patientUid: String!, $pdtUid: String!, $changeReason: String!) {
    revokePatientMobileAccess(patientUid: $patientUid, pdtUid: $pdtUid, changeReason: $changeReason) {
      success
    }
  }
`;

export const EDIT_PATIENT_INFO = gql`
  mutation EditPatientInfo(
    $patientUid: String!,
    $notes: String,
    $firstName: String,
    $lastName: String,
    $email: String,
    $dateOfBirth: DateTime,
    $changeReason: String!
  ) {
    editPatientInfo(
      patientUid: $patientUid,
      notes: $notes,
      firstName: $firstName,
      lastName: $lastName,
      email: $email,
      dateOfBirth: $dateOfBirth,
      changeReason: $changeReason
    ) {
      success
    }
  }
`;

export const REVOKE_PROVIDER_PORTAL_ACCESS = gql`
  mutation RevokeProviderPortalAccess($practitionerUid: String!, $changeReason: String!) {
    revokeProviderPortalAccess(practitionerUid: $practitionerUid, changeReason: $changeReason) {
      success
    }
  }
`;

export const GRANT_PROVIDER_PORTAL_ACCESS = gql`
  mutation GrantProviderPortalAccess(
    $practitionerUid: String!,
    $portalAccessTime: DateTime!,
    $changeReason: String!
  ) {
    grantProviderPortalAccess(
      practitionerUid: $practitionerUid,
      portalAccessTime: $portalAccessTime,
      changeReason: $changeReason
    ) {
      success
    }
  }
`;

export const EDIT_PRACTITIONER_NOTES = gql`
  mutation EditPractitionerNotes($practitionerUid: String!, $notes: String!, $changeReason: String!) {
    editPractitionerNotes(practitionerUid: $practitionerUid, notes: $notes, changeReason: $changeReason) {
      success
    }
  }
`;

export const RESEND_ACCESS_CODE = gql`
  mutation ResendAccessCode($practitionerUid: String!, $pdtUid: String!) {
    resendAccessCode(practitionerUid: $practitionerUid, pdtUid: $pdtUid) {
      success
    }
  }
`;

export const REVOKE_ACCESS_CODE = gql`
  mutation RevokeAccessCode($practitionerUid: String!, $pdtUid: String!, $changeReason: String!) {
    revokeAccessCode(practitionerUid: $practitionerUid, pdtUid: $pdtUid, changeReason: $changeReason) {
      success
    }
  }
`;

export const ISSUE_NEW_ACCESS_CODE = gql`
  mutation IssueNewAccessCode($practitionerUid: String!, $pdtUid: String!) {
    issueNewAccessCode(practitionerUid: $practitionerUid, pdtUid: $pdtUid) {
      success
    }
  }
`;

export const EDIT_ASSESSMENT = gql`
  mutation EditAssessment($assessmentData: AssessmentInputType!, $changeReason: String!) {
    editAssessment(assessmentData: $assessmentData, changeReason: $changeReason) {
      assessment {
        uid
        name
        contextText
        introText
        preQuestionText
        questions {
          uid
          text
          questionType
          isOptional
          isSensitive
          answers {
            uid
            text
          }
          conditions {
            dependsOnQuestion {
              uid
            }
            dependsOnAnswer {
              uid
            }
          }
        }
      }
    }
  }
`;

export const CREATE_ASSESSMENT = gql`
  mutation CreateAssessment($assessmentData: AssessmentInputType!) {
    createAssessment(assessmentData: $assessmentData) {
      assessment {
        uid
      }
    }
  }
`;

export const REPORT_RESEARCH_ASSESSMENT_RESULT = gql`
  mutation ReportResearchAssessmentResult($assessmentAnswers: [String]!, $assignmentUid: String!,
    $studyUid: String!, $assessmentResultUid: String, $changeReason: String) {
    reportResearchAssessmentResult(assessmentAnswers: $assessmentAnswers, assignmentUid: $assignmentUid,
      studyUid: $studyUid, assessmentResultUid:$assessmentResultUid, changeReason:$changeReason) {
      researchAssessmentResult {
        uid
        assessmentAnswers
      }
    }
  }
`;

export const START_RESEARCH_ASSIGNMENT = gql`
mutation StartResearchAssignment($assignmentUid: String!, $startTime:DateTime!, $studyUid: String!) {
    startResearchAssignment(assignmentUid: $assignmentUid, startTime: $startTime, studyUid: $studyUid) {
        assignment {
            uid
        }
    }
}
`;

export const COMPLETE_RESEARCH_ASSIGNMENT = gql`
  mutation CompleteResearchAssignment($assignmentUid: String!, $completeTime:DateTime!, $studyUid: String!) {
    completeResearchAssignment(assignmentUid: $assignmentUid, completeTime: $completeTime, studyUid: $studyUid) {
        assignment {
            uid
        }
    }
  }
`;

export const GENERATE_CENTRALIZED_CHANGE_LOG_CSV = gql`
  mutation GenerateChangeLog($studyUid: String!) {
    generateCentralizedChangeLogCsv(studyUid: $studyUid) {
      url
    }
  }
`;

export const ASSIGN_CLINICIAN_REPORT = gql`
  mutation AssignClinicianReport($studyUid: String!, $clinicalConcernUid: String!) {
    assignClinicianReport(clinicalConcernUid: $clinicalConcernUid,studyUid: $studyUid) {
      clinicalConcern {
        clinicianReport {
          uid
        }
      }
    }
  }
`;

export const GET_ACCESS_CODE_CSV_URLS = gql`
  mutation GetAccessCodeCsvUrls {
    getAccessCodeCsvUrls{
      urls
    }
  }
`;

export const SET_USER_BLINDED = gql`
  mutation SetUserBlinded($studyUid: String!, $userEmail: String!, $blinded: Boolean!) {
    setUserBlinded(studyUid: $studyUid, userEmail: $userEmail, blinded: $blinded) {
      success
    }
  }
`;

export const UPDATE_STUDY_ASSIGNMENT_SCHEDULE = gql`
  mutation UpdateStudyAssignmentSchedule(
      $studyUid: String!,
      $changeReason: String!,
      $assignmentScheduleData: AssignmentScheduleInputType!
      ) {
          updateStudyAssignmentSchedule(
          studyUid: $studyUid,
          changeReason: $changeReason,
          assignmentScheduleData: $assignmentScheduleData
          ) {
          assignmentSchedule {
              uid
          }
      }
  }
`;
