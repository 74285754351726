import { useLocation } from 'react-router-dom';

/*
Fetches limit from the URL query string and returns it. Returns null if value is invalid or not found.
 */
export const useLimit = (): number => {
  const { search } = useLocation();

  const searchParams = new URLSearchParams(search);
  const limit = searchParams.get('limit');
  const limitInt = parseInt(limit, 10);
  const isLimitIntValid = !!limitInt && limitInt > 0;

  return isLimitIntValid ? limitInt : null;
};
