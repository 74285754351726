import React from 'react';
import { Link, Box } from 'react-limbix-ui';

import {
  MutationResendEmailVerificationArgs,
  MutationResetPasswordArgs,
  PractitionerType,
} from '@/apollo/types';
import { PRACTITIONERS_LIST } from '@/apollo/queries';
import { RESEND_VERIFICATION_EMAIL, RESET_PASSWORD } from '@/apollo/mutations';
import { formatPractitionerName } from '@/utils/stringUtils';
import { useModal } from '@/hooks/redux';
import { useMutation } from '@/hooks/apollo';

type Props = {
  practitioner: PractitionerType;
  pdtUid: string;
};
const AdminActions: React.FC<Props> = (props: Props) => {
  const { practitioner, pdtUid } = props;
  const [showModal] = useModal();
  const refetchQueries = [
    {
      query: PRACTITIONERS_LIST,
      variables: {
        pdtUid,
      },
    },
  ];

  const [resendEmailVerificationMutation] = useMutation<MutationResendEmailVerificationArgs>(
    RESEND_VERIFICATION_EMAIL, { refetchQueries },
  );
  const resendEmailVerification = async (email: string) => (
    resendEmailVerificationMutation({
      variables: {
        email,
      },
    })
  );

  const [sendPasswordResetMutation] = useMutation<MutationResetPasswordArgs>(
    RESET_PASSWORD, { refetchQueries },
  );
  const sendPasswordReset = async (email: string) => (
    sendPasswordResetMutation({
      variables: {
        email,
      },
    })
  );
  const practitionerEmailVerified = practitioner?.user?.emailVerified;

  return (
    <Box>
      <Link.ButtonLink
        onClick={
          () => showModal(
            'VIEW_PATIENTS',
            { practitioner, pdtUid },
          )
        }
      >
        view patient info
      </Link.ButtonLink>
      {!practitionerEmailVerified && (
        <>
          {' | '}
          <Link.ButtonLink
            onClick={
              () => showModal(
                'CONFIRMATION_POPUP',
                {
                  header: 'Would you like to resend account verification?',
                  mutation: () => resendEmailVerification(practitioner.user.email),
                  context: { Name: formatPractitionerName(practitioner) },
                },
              )
            }
          >
            send account verification
          </Link.ButtonLink>
        </>
      )}
      {' | '}
      <Link.ButtonLink
        onClick={
          () => showModal(
            'CONFIRMATION_POPUP',
            {
              header: 'Would you like to reset the provider\'s password?',
              mutation: () => sendPasswordReset(practitioner.user.email),
              context: { Name: formatPractitionerName(practitioner) },
            },
          )
        }
      >
        send password reset
      </Link.ButtonLink>
    </Box>
  );
};

export default AdminActions;
