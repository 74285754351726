import React, { useEffect, useState } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import { Button } from '@material-ui/core';
import {
  LoadingSpinner,
  Row,
  Box,
  Text,
} from 'react-limbix-ui';

import { AssessmentDataGrid, ClinicianReport } from '@/components';
import {
  MutationReportClinicalConcernArgs,
  MutationGenerateClinicalConcernsCsvArgs,
} from '@/apollo/types';
import { REPORT_CLINICAL_CONCERN, GENERATE_CLINICAL_CONCERN_CSV } from '@/apollo/mutations';
import { MutationResultType, UseParamsType } from '@/types';
import { formatDate } from '@/utils/dateUtils';
import { makeStaticColumns } from '@/utils/dataGridUtils';
import { useModal, usePermissions, usePreventNavigation } from '@/hooks/redux';
import { useMutation } from '@/hooks/apollo';
import { getClinicalConcerns } from '@/hooks/apollo/queries';
import { isPermissionDeniedError } from '@/utils/errorUtils';
import { REDACTED_FOR_BLINDING_MESSAGE } from '@/utils/constants';
import { useLimit } from '@/hooks/useLimit';

const ClinicalConcernsLog: React.FC = () => {
  const limit = useLimit();
  const { studyUid, userUid } = useParams<UseParamsType>();
  const [showModal] = useModal();
  const [{ studyPermissions }] = usePermissions();
  const [, { setPreventNavigation, resetListenerEvent }] = usePreventNavigation();

  useEffect(() => {
    setPreventNavigation({ isBlocked: true, listeners: ['keyup', 'dblclick'] });
  }, []);

  const {
    loading,
    error,
    clinicalConcernAssessment,
    clinicianReportAssessment,
    clinicalConcerns,
    refetch,
  } = getClinicalConcerns(studyUid, userUid, limit);

  const [clinicalConcernsLoading, setClinicalConcernsLoading] = useState(false);

  const handleFinishMutations = () => {
    resetListenerEvent();
    refetch();
  };

  const [reportClinicalConcernMutation] = useMutation<MutationReportClinicalConcernArgs>(
    REPORT_CLINICAL_CONCERN, {
      ignoreResults: true,
    },
  );
  const reportClinicalConcern = (
    clinicalConcernUid: string,
    assessmentAnswers: Array<string>,
    changeReason: string,
  ): Promise<MutationResultType> => {
    const variables: MutationReportClinicalConcernArgs = {
      clinicalConcernUid,
      assessmentAnswers,
      studyUid,
      changeReason,
    };
    return reportClinicalConcernMutation({ variables });
  };

  const [generateClinicalConcernsMutatation] = useMutation<MutationGenerateClinicalConcernsCsvArgs>(
    GENERATE_CLINICAL_CONCERN_CSV,
  );

  const permissions = studyPermissions.find((study) => study.uid === studyUid);
  if (permissions.isBlinded) {
    return (
      <Text as="h1">
        You are blinded for this study. You cannot view clinical concerns.
      </Text>
    );
  }

  if (loading) {
    return <LoadingSpinner />;
  }

  if (isPermissionDeniedError(error)) {
    return <Redirect to="/research" />;
  }

  if (!clinicalConcernAssessment) {
    return (
      <Text as="h1">
        No Clinical Concern seems to be attacted to this study, please add one and try again
      </Text>
    );
  }

  const columns = [
    {
      field: 'pid',
      headerName: 'PID',
    },
    {
      field: 'createdAt',
      headerName: 'Created At',
    },
    {
      field: 'studyArm',
      headerName: 'Study Arm',
    },
    {
      field: 'status',
      headerName: 'Status',
    },
    {
      field: 'referralSource',
      headerName: 'Referral Source',
    },
  ];

  if (clinicianReportAssessment) {
    columns.push(
      {
        field: 'clinicianReport',
        headerName: 'Clinician Report',
      },
    );
  }
  const staticColumns = makeStaticColumns(columns);
  const staticCells = clinicalConcerns.map((clinicalConcern): Row => {
    const participant = clinicalConcern?.studyParticipant;
    const pid = participant?.participantId;
    const createdAt = formatDate(clinicalConcern?.createdAt);
    const studyArm = permissions.isBlinded ? REDACTED_FOR_BLINDING_MESSAGE
      : clinicalConcern?.studyParticipant?.studyArm?.name;
    const status = clinicalConcern?.studyParticipant?.status;
    const referralSource = participant?.referralSource;
    const cells: Row = {
      pid: { value: pid, originalValue: pid },
      createdAt: { value: createdAt, originalValue: createdAt },
      studyArm: { value: studyArm, originalValue: studyArm },
      status: { value: status, originalValue: status },
      referralSource: { value: referralSource, originalValue: referralSource },
    };
    if (clinicianReportAssessment) {
      cells.clinicianReport = {
        value: 'n/a',
        originalValue: 'n/a',
        renderCell: () => (
          <ClinicianReport
            studyUid={studyUid}
            participantUserUid={clinicalConcern.user.uid}
            clinicalConcernUid={clinicalConcern.uid}
            clinicianReportUid={clinicalConcern?.clinicianReport?.uid}
            completeTime={clinicalConcern?.clinicianReport?.completeTime}
          />
        ),
      };
    }
    return cells;
  });

  const onPressAddNewClinicalConcern = () => showModal(
    'CLINICAL_CONCERNS',
    {
      studyUid,
      userUid,
      navigate: false,
    },
  );

  const onPressGenerateCSV = async () => {
    setClinicalConcernsLoading(true);
    const participantAttributesObject = {
      PID: 'participant_id',
      Status: 'status',
      'Referral Source': 'referral_source',
    };
    if (!permissions.isBlinded) {
      Object.assign(participantAttributesObject, { 'Study Arm': 'study_arm.name' });
    }
    const participantAttributes = JSON.stringify(participantAttributesObject);
    const result = await generateClinicalConcernsMutatation({
      variables: {
        studyUid,
        userUid,
        participantAttributes,
      },
    });
    if (result.errors) {
      showModal(
        'ERROR_POPUP',
        {
          header: 'Error while generating CSV:',
          errors: result.errors.map((mutationError) => mutationError.message),
        },
      );
    } else {
      const csvUrl = result?.data?.generateClinicalConcernsCsv?.url;
      if (csvUrl) {
        window.open(csvUrl, '_blank');
      }
    }
    setClinicalConcernsLoading(false);
  };

  const generateClinicalConcernsButton = clinicalConcernsLoading
    ? <LoadingSpinner /> : (
      <Button
        onClick={onPressGenerateCSV}
        style={{ marginBottom: 16 }}
        variant="contained"
        color="primary"
        disabled={clinicalConcernsLoading}
      >
        Download Clinical Concerns CSV
      </Button>
    );

  return (
    <Box height="100%" display="inline">
      {permissions.canModifyParticipants && (
        <Box display="block">
          <Button
            onClick={onPressAddNewClinicalConcern}
            style={{ marginBottom: 16, marginRight: 16 }}
            variant="contained"
            color="primary"
          >
            Create New Clinical Concern
          </Button>
        </Box>
      )}
      {permissions.canViewStudy && generateClinicalConcernsButton}
      {
        limit !== null && (
          <Text fontSize={16}>
            {'Limiting to '}
            <b>{limit}</b>
            {' most recent clinical concerns'}
          </Text>
        )
      }
      <AssessmentDataGrid
        assessment={clinicalConcernAssessment}
        assessmentResults={clinicalConcerns}
        staticColumns={staticColumns}
        staticCells={staticCells}
        permissions={permissions}
        onSaveRow={reportClinicalConcern}
        onFinishMutations={handleFinishMutations}
      />
    </Box>
  );
};

export default ClinicalConcernsLog;
