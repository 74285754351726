import React, { useState } from 'react';
import { Select, MenuItem } from '@material-ui/core';

import Styled from '../Modal.styles';

import { MutationCreateClinicalConcernArgs, QueryStudyParticipantsArgs } from '@/apollo/types';
import history from '@/utils/history';
import { MutationResultType, GraphQLErrorsType } from '@/types';
import { CREATE_CLINICAL_CONCERN } from '@/apollo/mutations';
import {
  PARTICIPANT_CLINICAL_CONCERNS_QUERY,
  STUDY_CLINICAL_CONCERNS_QUERY,
  STUDY_PARTICIPANTS_DROPDOWN_QUERY,
} from '@/apollo/queries';
import ExclamationSVG from '@/assets/ExclamationCircle.svg';
import { ModalPayloadType } from '@/types/ReduxTypes';
import { useMutation, useLazyQuery } from '@/hooks/apollo';

type Props = {
  payload: ModalPayloadType['CLINICAL_CONCERNS'];
  onClose: () => void;
};
const ClinicalConcerns: React.FC<Props> = (props: Props) => {
  const {
    onClose,
    payload,
  } = props;

  const {
    studyUid,
    userUid,
    participantId,
    navigate,
  } = payload;
  const [mutationErrors, setMutationErrors] = useState<GraphQLErrorsType>(null);
  const [selectedParticipant, setSelectedParticipant] = useState(userUid || '');
  const [getParticipants, { called, data }] = useLazyQuery<QueryStudyParticipantsArgs>(
    STUDY_PARTICIPANTS_DROPDOWN_QUERY,
  );

  if (!participantId && !called) {
    getParticipants({ variables: { studyUid } });
  }

  const variables = {
    studyUid,
    userUid: userUid || selectedParticipant,
  };
  const [createClinicalConcernMutation] = useMutation<MutationCreateClinicalConcernArgs>(
    CREATE_CLINICAL_CONCERN, {
      ignoreResults: true,
      refetchQueries: [
        {
          variables,
          query: PARTICIPANT_CLINICAL_CONCERNS_QUERY,
        },
        {
          variables,
          query: STUDY_CLINICAL_CONCERNS_QUERY,
        },
      ],
    },
  );
  const createClinicalConcern = async (): Promise<MutationResultType> => (
    createClinicalConcernMutation({
      variables,
    })
  );

  const createAndClose = async () => {
    const result = await createClinicalConcern();
    if (result.errors && result.errors.length > 0) {
      setMutationErrors(result.errors);
      return;
    }
    const userUidValue = userUid || selectedParticipant;

    if (navigate) {
      history.push(`/research/study/${studyUid}/participant/${userUidValue}/clinical-concerns-log`);
    }
    onClose();
  };

  const participantList = [...(data?.limbixStudy?.studyParticipants || [])];
  participantList.sort((a, b) => (a?.participantId > b?.participantId ? 1 : -1));

  const onSelectParticipant = (event: React.ChangeEvent<{ value: unknown }>) => (
    setSelectedParticipant(event.target.value as string)
  );

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  return (
    <div style={{ width: '350px', height: '300px' }}>
      <Styled.DialogContent>
        <div style={{ display: 'flex' }}>
          <Styled.WarningSVGContainer>
            <Styled.WarningSVG src={ExclamationSVG} />
          </Styled.WarningSVGContainer>
          <Styled.TextContainer>
            <Styled.HeaderText>
              Clinical Concern
            </Styled.HeaderText>
            {!called ? (
              <>
                Would you like to create a Clinical Concern for participant with
                <br />
                PID:
                <strong>
                  {` ${participantId || 'None'}`}
                </strong>
                <div>
                  <br />
                  Be sure to check the log for an existing clinical concern related to this event
                </div>
              </>
            ) : (
              <>
                To create a new Clinical Concern please select a participant from this list and submit
                <br />
                <Select
                  labelId="label"
                  id="select"
                  value={selectedParticipant}
                  onChange={onSelectParticipant}
                  MenuProps={MenuProps}
                  style={{
                    minWidth: '100px',
                  }}
                >
                  <MenuItem value="">None</MenuItem>
                  {participantList.map((participant) => (
                    <MenuItem value={participant.user.uid} key={participant.user.uid}>
                      {participant.participantId || participant.user.uid}
                    </MenuItem>
                  ))}
                </Select>
              </>
            )}
            {mutationErrors && mutationErrors.map((error) => (
              <p style={{ color: 'red' }} key={error.message}>
                {error?.message}
              </p>
            ))}
          </Styled.TextContainer>
        </div>
        <Styled.ButtonContainer>
          <Styled.NoButton onClick={onClose}>
            <Styled.NoButtonText>
              No
            </Styled.NoButtonText>
          </Styled.NoButton>
          <Styled.YesButton onClick={createAndClose} disabled={!selectedParticipant}>
            <Styled.YesButtonText>
              Yes - Create
            </Styled.YesButtonText>
          </Styled.YesButton>
        </Styled.ButtonContainer>
      </Styled.DialogContent>
    </div>
  );
};

export default ClinicalConcerns;
