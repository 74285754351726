import React from 'react';
import {
  TextField,
  Select,
  Switch,
  Button,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import {
  Memo,
  LoadingSpinner,
  Text,
  Box,
} from 'react-limbix-ui';
import isEqual from 'react-fast-compare';

import { ActionType, ActionTypeEnum } from './AssignmentScheduleEditor.types';

import { EnumSelect } from '@/components';
import {
  AssessmentType,
  AssignmentScheduleAccountType,
  AssignmentScheduleInputType,
  AssignmentScheduleScheduleOnStudyState,
  AssignmentTypeOfClient,
  AssignmentScheduleTypeOfNotificationForAssignment,
} from '@/apollo/types';
import { UnknownChangeEvent, HTMLInputChangeEvent } from '@/types';

type AssignmentScheduleFooterProps = {
  onAddAssignmentSchedule: () => void;
  onSave: () => void;
  saving?: boolean;
};
const AssignmentScheduleFooter: React.FC<AssignmentScheduleFooterProps> = Memo(({
  onAddAssignmentSchedule,
  onSave,
  saving,
}) => (
  <Box padding="0px 0px 10px" textAlign="end">
    <Button variant="contained" color="default" onClick={onAddAssignmentSchedule}>
      Add Assignment Schedule
    </Button>
    {' '}
    {!saving ? (
      <Button
        variant="contained"
        color="primary"
        onClick={onSave}
      >
        Save
      </Button>
    ) : (
      <>
        <LoadingSpinner />
        Saving...
      </>
    )}
  </Box>
));

type BooleanFieldSwitchProps = {
  value: boolean;
  label: string;
  onFlipSwitch: (event: HTMLInputChangeEvent, checked: boolean) => void;
};
const BooleanFieldSwitch: React.FC<BooleanFieldSwitchProps> = Memo(({
  value,
  label,
  onFlipSwitch,
}) => (
  <Box>
    <FormControlLabel
      value="start"
      control={(
        <Switch
          color="primary"
          checked={value}
          value={value}
          onChange={onFlipSwitch}
        />
      )}
      label={label}
      labelPlacement="start"
    />
  </Box>
));

type NumericFieldInputProps = {
  value: number,
  label: string,
  onChange: (event: HTMLInputChangeEvent) => void,
};
const NumericFieldInput: React.FC<NumericFieldInputProps> = Memo(({
  value,
  label,
  onChange,
}) => (
  <Box margin="10px 0px">
    <TextField
      variant="outlined"
      id={label}
      label={label}
      style={{ width: '300px' }}
      value={value}
      size="small"
      type="number"
      onChange={(event) => onChange(event)}
      inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
    />
  </Box>
));

type AssessmentFieldSelectProps = {
  value: string,
  onSelect: (event: UnknownChangeEvent) => void,
  assessments: Array<AssessmentType>,
};
const AssessmentFieldSelect: React.FC<AssessmentFieldSelectProps> = Memo(({
  value,
  onSelect,
  assessments,
}) => (
  <Box margin="30px 0px 30px 10px">
    <Text fontWeight="600" fontSize="18px">
      Assessment:
    </Text>
    <Select
      labelId="select-question-type"
      id="select-question-type"
      native
      value={value}
      onChange={(event: UnknownChangeEvent) => onSelect(event)}
      inputProps={{
        id: 'select-multiple-native',
      }}
    >
      <option value="">
        Select Type
      </option>
      {assessments.map((assessment) => (
        <option key={assessment.uid} value={assessment.uid}>
          {assessment.nameInternal}
        </option>
      ))}
    </Select>
  </Box>
));

type NotificationSelectionProps = {
  value: boolean,
  label: string,
  onCheck: (event: HTMLInputChangeEvent, checked: boolean) => void;
};
const NotificationSelection: React.FC<NotificationSelectionProps> = Memo(({
  value,
  label,
  onCheck,
}) => (
  <Box margin="0px">
    <FormControlLabel
      value="start"
      control={(
        <Checkbox
          color="primary"
          checked={value}
          value={value}
          onChange={onCheck}
        />
      )}
      label={label}
      labelPlacement="start"
    />
  </Box>
));

type EditableAssignmentScheduleProps = {
  assignmentSchedule: AssignmentScheduleInputType,
  assessments: Array<AssessmentType>,
  dispatch: React.Dispatch<ActionType>,
  index: number,
}
const EditableAssignmentSchedule: React.FC<EditableAssignmentScheduleProps> = Memo(({
  assignmentSchedule,
  assessments,
  dispatch,
  index,
}) => {
  const onDuplicate = () => {
    dispatch({ type: ActionTypeEnum.DUPLICATE_ASSIGNMENT_SCHEDULE, index });
  };

  const handleSelectAssessment = (event: UnknownChangeEvent) => {
    dispatch({
      index,
      value: `${event.currentTarget.value}`,
      field: 'assessmentUid',
      type: ActionTypeEnum.EDIT_ASSIGNMENT_SCHEDULE,
    });
  };

  const handleSelectEnumField = (field: keyof AssignmentScheduleInputType) => (event: UnknownChangeEvent) => {
    dispatch({
      index,
      field,
      value: `${event.currentTarget.value}`,
      type: ActionTypeEnum.EDIT_ASSIGNMENT_SCHEDULE,
    });
  };

  const handleChangeNumericField = (field: keyof AssignmentScheduleInputType) => (event: HTMLInputChangeEvent) => {
    dispatch({
      index,
      field,
      value: `${event.currentTarget.value}`,
      type: ActionTypeEnum.EDIT_ASSIGNMENT_SCHEDULE,
    });
  };

  const handleChangeBooleanField = (field: keyof AssignmentScheduleInputType) => (
    _: HTMLInputChangeEvent,
    checked: boolean,
  ) => {
    dispatch({
      index,
      field,
      value: checked,
      type: ActionTypeEnum.EDIT_ASSIGNMENT_SCHEDULE,
    });
  };

  return (
    <Box>
      <Box display="inline-flex">
        <Text as="h2" marginRight="20px">
          {`${index + 1}.`}
        </Text>
        <Button
          variant="contained"
          color="default"
          onClick={onDuplicate}
          startIcon={<FileCopyIcon />}
        >
          Duplicate
        </Button>
      </Box>
      <AssessmentFieldSelect
        assessments={assessments}
        value={assignmentSchedule.assessmentUid}
        onSelect={handleSelectAssessment}
      />
      <EnumSelect
        label="Account Type: "
        value={assignmentSchedule.accountType}
        enumType={AssignmentScheduleAccountType}
        onSelect={handleSelectEnumField('accountType')}
      />
      <EnumSelect
        label="Client type: "
        value={assignmentSchedule.typeOfClientForAssignment}
        enumType={AssignmentTypeOfClient}
        onSelect={handleSelectEnumField('typeOfClientForAssignment')}
      />
      <EnumSelect
        label="Schedule on study state: "
        value={assignmentSchedule.scheduleOnStudyState}
        enumType={AssignmentScheduleScheduleOnStudyState}
        onSelect={handleSelectEnumField('scheduleOnStudyState')}
      />
      <EnumSelect
        label="Notification type: "
        value={assignmentSchedule.typeOfNotificationForAssignment}
        enumType={AssignmentScheduleTypeOfNotificationForAssignment}
        onSelect={handleSelectEnumField('typeOfNotificationForAssignment')}
      />
      <NumericFieldInput
        label="Time visible (days): "
        value={assignmentSchedule.visibleDurationDays}
        onChange={handleChangeNumericField('visibleDurationDays')}
      />
      <NumericFieldInput
        label="Repeat frequency (days): "
        value={assignmentSchedule.repeatFrequencyDays}
        onChange={handleChangeNumericField('repeatFrequencyDays')}
      />
      <NumericFieldInput
        label="Start time offset (days): "
        value={assignmentSchedule.startTimeOffsetDays}
        onChange={handleChangeNumericField('startTimeOffsetDays')}
      />
      <NumericFieldInput
        label="Ordinal"
        value={assignmentSchedule.ordinal}
        onChange={handleChangeNumericField('ordinal')}
      />
      <NumericFieldInput
        label="Number of assignments: "
        value={assignmentSchedule.count}
        onChange={handleChangeNumericField('count')}
      />
      <BooleanFieldSwitch
        label="Can skip questions?"
        value={assignmentSchedule.canSkipQuestions}
        onFlipSwitch={handleChangeBooleanField('canSkipQuestions')}
      />
      <BooleanFieldSwitch
        label="Can edit after completion?"
        value={assignmentSchedule.canEditAfterCompletion}
        onFlipSwitch={handleChangeBooleanField('canEditAfterCompletion')}
      />
      <Box>
        <Text as="h3" marginTop="20px">
          Notifications:
        </Text>
        <NotificationSelection
          label="Send push notifications?"
          value={assignmentSchedule.sendPushNotifications}
          onCheck={handleChangeBooleanField('sendPushNotifications')}
        />
        <NotificationSelection
          label="Send email notifications?"
          value={assignmentSchedule.sendEmailNotifications}
          onCheck={handleChangeBooleanField('sendEmailNotifications')}
        />
        <NotificationSelection
          label="Send SMS notifications?"
          value={assignmentSchedule.sendSmsNotifications}
          onCheck={handleChangeBooleanField('sendSmsNotifications')}
        />
      </Box>
    </Box>
  );
}, (prevProps, nextProps) => (
  isEqual(prevProps.assignmentSchedule, nextProps.assignmentSchedule)
));

export {
  EditableAssignmentSchedule,
  AssignmentScheduleFooter,
};
