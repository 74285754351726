import React, { useState } from 'react';
import { TextField } from '@material-ui/core';

import Styled from '../Modal.styles';

import { MutationRevokePatientMobileAccessArgs } from '@/apollo/types';
import { CARETEAMS_LIST } from '@/apollo/queries';
import { REVOKE_PATIENT_MOBILE_ACCESS } from '@/apollo/mutations';
import { MutationResultType, GraphQLErrorsType } from '@/types';
import ExclamationSVG from '@/assets/ExclamationCircle.svg';
import { ModalPayloadType } from '@/types/ReduxTypes';
import { useMutation } from '@/hooks/apollo';

type Props = {
  payload: ModalPayloadType['REVOKE_PATIENT_ACCESS'];
  onClose: () => void;
};
const RevokePatientAccess: React.FC<Props> = (props: Props) => {
  const {
    onClose,
    payload,
  } = props;
  const [changeReasonValue, setChangeReasonValue] = useState<string>();
  const [errors, setErrors] = useState<GraphQLErrorsType>([]);

  const close = () => {
    onClose();
  };

  const {
    careteam,
  } = payload;

  const refetchQueries = [
    {
      query: CARETEAMS_LIST,
      variables: {
        pdtUid: careteam.pdt.uid,
      },
    },
  ];

  const [revokePatientMobileAccessMutation] = useMutation<MutationRevokePatientMobileAccessArgs>(
    REVOKE_PATIENT_MOBILE_ACCESS, { refetchQueries },
  );

  const revokePatientMobileAccess = (changeReason: string): Promise<MutationResultType> => (
    revokePatientMobileAccessMutation({
      variables: {
        patientUid: careteam.patient.uid,
        pdtUid: careteam.pdt.uid,
        changeReason,
      },
    })
  );

  const revokeAndClose = async () => {
    const result = await revokePatientMobileAccess(changeReasonValue);
    if (result.errors) {
      setErrors(result.errors);
    } else {
      onClose();
    }
  };

  return (
    <div style={{ width: '350px', height: '325px' }}>
      <Styled.DialogContent>
        <div style={{ display: 'flex' }}>
          <Styled.WarningSVGContainer>
            <Styled.WarningSVG src={ExclamationSVG} />
          </Styled.WarningSVGContainer>
          <Styled.TextContainer>
            <Styled.HeaderText>
              Revoke Patient Mobile Access
            </Styled.HeaderText>
            <Styled.BodyText>
              Would you like to revoke Patient Mobile Access with UID:
              <strong>
                {` ${careteam.patient.uid.substring(0, 6)} `}
              </strong>
              for PDT:
              <strong>
                {` ${careteam.pdt.name} `}
              </strong>
            </Styled.BodyText>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              name="change reason"
              label="Change Reason"
              id="change reason"
              onChange={(event) => setChangeReasonValue(event.target.value?.trim())}
            />
            {errors.map((error) => (
              <div key={error.message} style={{ color: 'red' }}>
                {error.message}
              </div>
            ))}
          </Styled.TextContainer>
        </div>
        <Styled.ButtonContainer>
          <Styled.NoButton onClick={close}>
            <Styled.NoButtonText>
              No
            </Styled.NoButtonText>
          </Styled.NoButton>
          <Styled.YesButton onClick={revokeAndClose} disabled={!changeReasonValue}>
            <Styled.YesButtonText>
              Yes - Revoke
            </Styled.YesButtonText>
          </Styled.YesButton>
        </Styled.ButtonContainer>
      </Styled.DialogContent>
    </div>
  );
};

export default RevokePatientAccess;
