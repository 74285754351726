import { PopperPlacementType } from '@material-ui/core';
import { Row, Column } from 'react-limbix-ui';

import {
  IdleTimerPayloadType,
  MutationResultType,
  StudyPermission,
} from './DataTypes';

import {
  CareTeamType,
  LimbixStudyType,
  OrganizationType,
  PractitionerType,
  StudyParticipantType,
  UserType,
  QueryStudyAssignmentsArgs,
} from '@/apollo/types';
import { AccountType, AssignmentStatus, PaginationType } from '@/types';

export enum GlobalActionTypes {
  ClearStore = 'CLEAR_STORE',
}

export type GlobalAction = {
  type: GlobalActionTypes;
}

export enum PopperContentType {
  None,
  HeaderMenu,
}

export enum PopperActionTypes {
  ShowPopper = 'SHOW_POPPER',
  HidePopper = 'HIDE_POPPER',
  HidePopperResetAnchorEl = 'HIDE_POPPER_RESET_ANCHOR_EL',
}

export type PopperAction = {
  type: PopperContentType;
  payload: {
    payload?: unknown;
    placement?: PopperPlacementType;
    anchorEl?: HTMLElement;
  }
}

export type ResopsUserType = {
  isLoggedIn: boolean;
  isResearch: boolean;
  isOperations: boolean;
  studyPermissions: StudyPermission[];
};

export enum ResopsUserActionTypes {
  updateResopsUser = 'UPDATE_RESOPS_USER',
}

export type ResopsUserAction = {
  type: ResopsUserActionTypes;
  payload: ResopsUserType;
}

export type AssignmentFiltersStateType = QueryStudyAssignmentsArgs;

export enum AssignmentFiltersActionType {
  updateStudyUid = 'UPDATE_STUDY_UID',
  updateUserUid = 'UPDATE_USER_UID',
  updateAccountType = 'UPDATE_ACCOUNT_TYPE',
  updateStatusType = 'UPDATE_STATUS_TYPE',
  updatePagination = 'UPDATE_PAGINATION',
  addAssessmentUids = 'ADD_ASSESSMENT_UIDS',
  removeAssessmentUids = 'REMOVE_ASSESSMENT_UIDS',
  updateAssessmentUids = 'UPDATE_ASSESSMENT_UIDS',
  resetFilters = 'RESET_FILTERS'
}

export type AssignmentFiltersAction = {
  type: AssignmentFiltersActionType.updateStudyUid,
  payload: string,
} | {
  type: AssignmentFiltersActionType.updateUserUid,
  payload: string,
} | {
  type: AssignmentFiltersActionType.updateAccountType,
  payload: AccountType,
} | {
  type: AssignmentFiltersActionType.updateStatusType,
  payload: AssignmentStatus,
} | {
  type: AssignmentFiltersActionType.updatePagination,
  payload: PaginationType,
} | {
  type: AssignmentFiltersActionType.addAssessmentUids,
  payload: string[],
} | {
  type: AssignmentFiltersActionType.removeAssessmentUids,
  payload: string[],
} | {
  type: AssignmentFiltersActionType.updateAssessmentUids,
  payload: string[],
} | {
  type: AssignmentFiltersActionType.resetFilters,
  payload: string[],
}

export enum ModalActionTypes {
  ShowModal = 'SHOW_MODAL',
  HideModal = 'HIDE_MODAL',
}

export type ModalPayloadType = {
  NONE: null;
  IDLE_WARNING: IdleTimerPayloadType;
  TRANSITION_TO_ADULT: { studyParticipant: StudyParticipantType };
  DELETE_PARTICIPANT: { studyParticipant: StudyParticipantType };
  STUDY_ACTIONS: {
    type: string,
    studyParticipant: StudyParticipantType,
  };
  CONTACT_INFO: { studyParticipant: StudyParticipantType };
  REVOKE_ACCESS: { studyParticipant: StudyParticipantType };
  REGISTER_NEW_USER: { studyUid: string };
  REGISTER_EXISTING_USER: { studyUid: string };
  CHANGE_STUDY_NAME: { limbixStudy: LimbixStudyType };
  ADD_STUDY_PERSONNEL: {
    limbixStudy: LimbixStudyType;
    type: string;
  };
  REMOVE_STUDY_PERSONNEL: {
    limbixStudy: LimbixStudyType;
    user: UserType,
    type: string;
  };
  CLINICAL_CONCERNS: {
    studyUid: string,
    userUid?: string,
    participantId?: string,
    navigate: boolean,
  };
  PARTICIPANT_NOTES: {
    studyParticipant: StudyParticipantType,
    canModifyParticipants: boolean,
  };
  EDIT_PARENTS: { studyParticipant: StudyParticipantType };
  ERROR_POPUP: {
    header?: string;
    errors: Array<string>;
  };
  CONFIRMATION_POPUP: {
    header: string;
    mutation: () => Promise<MutationResultType>;
    context?: Record<string, string>;
    body?: string;
    skipMissingContextWarning?: boolean;
  };
  CONFIRMATION_POPUP_CHANGE_REASON: {
    header: string;
    body?: string;
    mutation?: (changeReason: string) => Promise<MutationResultType>;
    mutations?: Array<(changeReason: string) => Promise<MutationResultType>>,
  };
  ALL_CONTACT_INFO: { studyParticipant: StudyParticipantType };
  UPDATE_PID: { studyParticipant: StudyParticipantType };
  ACCESS_CODES: {
    practitioner: PractitionerType;
    pdtUid: string;
  };
  PATIENT_NOTES: { careteam: CareTeamType };
  PRACTITIONER_NOTES: {
    practitioner: PractitionerType;
    pdtUid: string;
  };
  VIEW_PATIENTS: {
    practitioner: PractitionerType;
    pdtUid: string;
  };
  PORTAL_ACCESS: {
    practitioner: PractitionerType;
    pdtUid: string;
  };
  VIEW_PROVIDERS: { careteam: CareTeamType };
  PATIENT_CONTACT_INFO: { careteam: CareTeamType };
  REVOKE_PATIENT_ACCESS: { careteam: CareTeamType };
  ORGANIZATION_INFO: { organization: OrganizationType };
  DATA_GRID_CONFIRMATION: {
    columns: Column[],
    editedRows: Row[],
    mutations: Array<(changeReason: string) => Promise<MutationResultType>>,
    onFinishMutations: () => void,
  },
  GENERIC_WARNING: {
    primaryButtonText: string;
    secondaryButtonText: string;
    onClickPrimaryButton?: () => void;
    onClickSecondaryButton?: () => void;
    headerText: string;
    body: React.ReactNode;
  },
  GENERIC_MODAL: {
    primaryButtonText?: string;
    secondaryButtonText?: string;
    onClickPrimaryButton?: () => void;
    onClickSecondaryButton?: () => void;
    headerText: string,
    body: React.ReactNode,
  },
};

export type ModalContentType = keyof ModalPayloadType;

export type ShowModalAction<ModalContent extends ModalContentType> = { type: ModalActionTypes.ShowModal }
  & {
  payload: {
    modalType: ModalContent,
    payload: ModalPayloadType[ModalContent],
  }
};

export type ModalAction = {
  type: ModalActionTypes.HideModal;
} | ShowModalAction<ModalContentType>;

/* Navigation Action/Reducer Types */
export type NavigationType = {
  isBlocked: boolean;
  listeners: Array<keyof WindowEventMap>,
  message?: string;
};

export enum NavigationActionTypes {
  blockNavigationOnEvent = 'BLOCK_NAVIGATION_ON_EVENT',
  eventOccurred = 'EVENT_OCCURRED',
  blockNavigation = 'BLOCK_NAVIGATION',
  unblockNavigation = 'UNBLOCK_NAVIGATION',
}

export type NavigationAction = {
  type: NavigationActionTypes.blockNavigationOnEvent,
  payload: {
    message?: string;
    listeners: Array<keyof WindowEventMap>,
  },
} | {
  type: NavigationActionTypes.blockNavigation,
  payload: {
    message?: string,
  }
} | {
  type: NavigationActionTypes.unblockNavigation,
} | {
  type: NavigationActionTypes.eventOccurred,
  payload: {
    value: boolean,
  }
}
