import React, { useState } from 'react';
import {
  Table,
  Link,
  TableColumn as Column,
  TableRow as Row,
  SortState,
  Box,
} from 'react-limbix-ui';

import StudyActions from './StudyActions';
import AdminActions from './AdminActions';
import MobileAccess from './MobileAccess';

import { StudyParticipantType } from '@/apollo/types';
import {
  StudyPermission,
  MutationResultType,
} from '@/types';
import { SearchBar } from '@/components';
import {
  createColumn,
  createCell,
  hasSearchString,
  renderPID,
} from '@/utils/tableUtils';
import { useModal } from '@/hooks/redux';
import CaseReportFormDownloader from '@/components/CaseReportFormDownloader';

type Props = {
  studyPermissions: StudyPermission;
  studyUid: string;
  studyParticipants?: StudyParticipantType[];
  resetAccessAttempts?: (userUid: string) => Promise<MutationResultType>;
  generateCRF?: (userUid: string) => Promise<MutationResultType>;
};
const ParticipantTable: React.FC<Props> = (props: Props) => {
  const {
    studyParticipants,
    studyPermissions,
    resetAccessAttempts,
    generateCRF,
    studyUid,
  } = props;
  const [showModal] = useModal();
  const [searchString, setSearchString] = useState('');

  const generateCRFsLoadingDefault: { [key: string]: boolean } = {};
  studyParticipants.forEach((participant) => {
    generateCRFsLoadingDefault[participant.user.uid] = false;
  });

  const renderAccountType = (studyParticipant: StudyParticipantType) => (
    studyParticipant.user.accountType !== 'CHILD' ? studyParticipant.user.accountType : (
      <div>
        <div>
          {studyParticipant.user.accountType}
        </div>
        {studyPermissions.canModifyParticipants && (
          <Link.ButtonLink
            onClick={
              () => showModal(
                'TRANSITION_TO_ADULT',
                { studyParticipant },
              )
            }
          >
            Transition to Adult
          </Link.ButtonLink>
        )}
      </div>
    )
  );

  const renderFailedAttempts = (studyParticipant: StudyParticipantType) => (
    !studyParticipant.user.accessAttempts ? '0' : (
      <div>
        {`${studyParticipant.user.accessAttempts} `}
        {studyPermissions.canModifyParticipants && (
          <Link.ButtonLink
            onClick={
              () => (
                resetAccessAttempts(studyParticipant.user.uid)
              )
            }
          >
            (reset)
          </Link.ButtonLink>
        )}
      </div>
    )
  );

  const renderStudyActions = (studyParticipant: StudyParticipantType) => (
    <StudyActions participant={studyParticipant} />
  );

  const renderAdminActions = (studyParticipant: StudyParticipantType) => (
    <AdminActions participant={studyParticipant} />
  );

  const renderMobileAccess = (studyParticipant: StudyParticipantType) => (
    <MobileAccess
      participant={studyParticipant}
      studyPermissions={studyPermissions}
    />
  );

  const renderRemove = (studyParticipant: StudyParticipantType) => (
    <Link.ButtonLink
      onClick={
        () => showModal(
          'DELETE_PARTICIPANT',
          {
            studyParticipant,
          },
        )
      }
    >
      Delete
    </Link.ButtonLink>
  );

  const renderNotes = (studyParticipant: StudyParticipantType) => (
    <Link.ButtonLink
      onClick={
        () => showModal(
          'PARTICIPANT_NOTES',
          {
            studyParticipant,
            canModifyParticipants: studyPermissions.canModifyParticipants,
          },
        )
      }
    >
      view
    </Link.ButtonLink>
  );

  const renderDownloadCRF = (studyParticipant: StudyParticipantType) => (
    <CaseReportFormDownloader
      studyParticipantUserUid={studyParticipant.user.uid}
      studyUid={studyUid}
    />
  );

  const columns: Column[] = [
    createColumn('pid', 'PID', 'string', null, true),
    createColumn('accountType', 'Account Type', 'string', null, true),
    createColumn('email', 'Email', 'string', null, true),
    createColumn('emailConfirmed', 'Email Confirmed', 'string', null, true),
    createColumn('failedAttempts', 'Failed Attempts', 'number', null, true),
    createColumn('status', 'Status', 'string', null, true),
  ];
  // Hide study arm if blinded.
  if (!studyPermissions.isBlinded) {
    columns.push(createColumn('studyArm', 'Study Arm', 'string', null, true));
  }

  if (generateCRF) {
    columns.push(createColumn('crf', 'CRF', 'string', null, false));
  }

  if (studyPermissions.canModifyParticipants) {
    columns.push(createColumn('studyActions', 'Study Actions', 'string', null, false));
    columns.push(createColumn('adminActions', 'Admin Actions', 'string', null, false));
  }
  columns.push(createColumn('mobileAccess', 'Mobile Access', 'string', null, false));
  if (studyPermissions.canDeleteParticipants) {
    columns.push(createColumn('remove', 'Remove', 'string', null, false));
  }
  columns.push(createColumn('notes', 'Notes', 'string', null, false));

  const rows: Row[] = studyParticipants
    .filter((participant) => {
      if (!searchString || searchString === '') {
        return true;
      }
      const pidString = participant?.participantId?.toLowerCase() || '';
      const email = participant.user?.email?.toLowerCase() || '';
      try {
        return hasSearchString([pidString, email], searchString);
      } catch (error) {
        return true;
      }
    })
    .map((participant) => {
      const row: Row = {
        id: participant.user.uid,
        cells: [
          createCell(
            'pid',
            participant.participantId,
            renderPID(studyUid, participant.user.uid, participant.participantId),
          ),
          createCell('accountType', participant.user.accountType, renderAccountType(participant)),
          createCell('email', participant.user.email, participant.user.email || 'None'),
          createCell('emailConfirmed', participant.user.emailVerified, `${participant.user.emailVerified}`),
          createCell('failedAttempts', participant.user.accessAttempts, renderFailedAttempts(participant)),
          createCell('status', participant.status, participant.status),
        ],
      };
      if (!studyPermissions.isBlinded) {
        row.cells.push(createCell('studyArm', participant?.studyArm?.name, participant?.studyArm?.name || 'None'));
      }
      if (generateCRF) {
        row.cells.push(createCell('crf', 'CRF', renderDownloadCRF(participant)));
      }
      if (studyPermissions.canModifyParticipants) {
        row.cells.push(createCell('studyActions', 'Study Actions', renderStudyActions(participant)));
        row.cells.push(createCell('adminActions', 'Admin Actions', renderAdminActions(participant)));
      }
      row.cells.push(createCell('mobileAccess', 'Mobile Access', renderMobileAccess(participant)));
      if (studyPermissions.canDeleteParticipants) {
        row.cells.push(createCell('remove', 'remove', renderRemove(participant)));
      }
      row.cells.push(createCell('notes', 'Notes', renderNotes(participant)));
      return row;
    });

  const defaultSortState: SortState = { col: '', direction: 'desc' };

  return (
    <Box>
      <Box marginBottom="20px" float="right">
        <SearchBar
          placeholder="Filter Participants"
          defaultValue={searchString}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => (
            setSearchString(event.currentTarget.value?.toLowerCase() || '')
          )}
        />
      </Box>
      <Table columns={columns} rows={rows} defaultSortState={defaultSortState} />
    </Box>
  );
};

export default ParticipantTable;
