import React from 'react';
import { Link, Box } from 'react-limbix-ui';

import { StudyParticipantType } from '@/apollo/types';
import { useModal } from '@/hooks/redux';

type Props = {
  participant: StudyParticipantType;
};
const StudyActions: React.FC<Props> = (props: Props) => {
  const { participant } = props;
  const [showModal] = useModal();
  const render = (
    text: string,
    clickable: boolean,
  ) => {
    if (clickable) {
      return (
        <Link.ButtonLink
          onClick={() => showModal('STUDY_ACTIONS', {
            type: text,
            studyParticipant: participant,
          })}
        >
          {text}
        </Link.ButtonLink>
      );
    }
    return (
      <>{text}</>
    );
  };

  return (
    <Box>
      {render(
        'activate',
        participant.canActivate,
      )}
      {' | '}
      {render(
        'complete',
        participant.canComplete,
      )}
      {' | '}
      {render(
        'withdraw',
        participant.canWithdraw,
      )}
      {' | '}
      {render(
        'lost to follow-up',
        participant.canBeLostToFollowup,
      )}
      {' | '}
      {render(
        'drop',
        participant.canBeDropped,
      )}
      {' | '}
      {render(
        'screen fail',
        participant.canScreenFail,
      )}
    </Box>
  );
};

export default StudyActions;
