import React from 'react';
import {
  TableColumn as Column,
  TableCell as Cell,
  KeyValuePairType,
  Link,
} from 'react-limbix-ui';

type ColValueType = 'string' | 'number' | 'date';

type RowValueType = string | number | boolean | Date;

export const createColumn = (
  id: string,
  text: string,
  valueType: ColValueType,
  width: string,
  sortable: boolean,
): Column => (
  {
    id,
    text,
    valueType,
    width,
    sortable,
  }
);

export const createCell = (columnId: string, value: RowValueType, render: KeyValuePairType): Cell => (
  {
    columnId,
    value,
    render: render ?? 'None',
  }
);

export const hasSearchString = (stringList: string[], searchString: string): boolean => {
  if (searchString.startsWith('-')) {
    if (searchString.length <= 1) {
      return true;
    }
    const negationString = searchString.slice(1);
    const regExNegated = new RegExp(negationString.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'));
    return stringList.reduce((acc: boolean, curr) => (
      acc && !curr.startsWith(negationString) && !regExNegated.test(curr)
    ), true);
  }
  const regEx = new RegExp(searchString.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'));
  return stringList.reduce((acc: boolean, curr) => (
    acc || curr.startsWith(searchString) || regEx.test(curr)
  ), false);
};

export const renderPID = (studyUid: string, userUid: string, participantId: string) => {
  if (!userUid) {
    return 'None';
  }
  const linkToPID = `/research/study/${studyUid}/participant/${userUid}`;
  return <Link.RouterLink to={linkToPID} fontSize="16px">{participantId || 'None'}</Link.RouterLink>;
};
