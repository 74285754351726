import { createAction } from '@reduxjs/toolkit';

import {
  ResopsUserType,
  ResopsUserActionTypes,
} from '@/types';

const updateResopsUser = createAction<ResopsUserType>(ResopsUserActionTypes.updateResopsUser);

export default {
  updateResopsUser,
};
