export const GENDER_MAP = {
  M: 'Male',
  F: 'Female',
  X: 'Non-Binary',
  O: 'Other',
};

export const DISTINCT_COLOR_PALETTE = [
  '#e6194B',
  '#3cb44b',
  '#ffe119',
  '#4363d8',
  '#800000',
  '#dcbeff',
  '#808000',
  '#f58231',
  '#000075',
  '#fabed4',
  '#469990',
  '#fffac8',
  '#f032e6',
];

export const ASSIGNMENT_FILTER_WIDTH = '350px';

export const REDACTED_FOR_BLINDING_MESSAGE = '[redacted for blinding]';

export const DEFAULT_CLINICAL_CONCERN_LIMIT = 20;

export const DEFAULT_STUDY_ASSIGNMENTS_LIMIT = 20;
