import React, { useReducer } from 'react';
import { Memo, Text, Box } from 'react-limbix-ui';

import { StateType, ActionTypeEnum } from './AssignmentScheduleEditor.types';
import { reducer } from './AssignmentScheduleEditor.reducer';
import {
  EditableAssignmentSchedule,
  AssignmentScheduleFooter,
} from './AssignmentScheduleEditor.components';

import {
  AssignmentScheduleType,
  AssessmentType,
  AssignmentScheduleInputType,
} from '@/apollo/types';
import { range } from '@/utils/arrayUtils';

const loadAssignmentSchedulesIntoState = (assignmentSchedules: Array<AssignmentScheduleType>) => (
  {
    assignmentSchedules: assignmentSchedules.filter((assignmentSchedule) => (
      !!assignmentSchedule?.assessment?.uid
    )).map((assignmentSchedule): AssignmentScheduleInputType => ({
      accountType: assignmentSchedule.accountType,
      assessmentUid: assignmentSchedule.assessment.uid,
      canEditAfterCompletion: assignmentSchedule.canEditAfterCompletion,
      canSkipQuestions: assignmentSchedule.canSkipQuestions,
      count: assignmentSchedule.count,
      ordinal: assignmentSchedule.ordinal,
      repeatFrequencyDays: assignmentSchedule.repeatFrequencyDays || 0,
      scheduleOnStudyState: assignmentSchedule.scheduleOnStudyState,
      sendPushNotifications: assignmentSchedule.sendPushNotifications,
      sendEmailNotifications: assignmentSchedule.sendEmailNotifications,
      sendSmsNotifications: assignmentSchedule.sendSmsNotifications,
      startTimeOffsetDays: assignmentSchedule.startTimeOffsetDays || 0,
      typeOfClientForAssignment: assignmentSchedule.typeOfClientForAssignment,
      typeOfNotificationForAssignment: assignmentSchedule.typeOfNotificationForAssignment,
      uid: assignmentSchedule.uid,
      visibleDurationDays: assignmentSchedule.visibleDurationDays || 0,
    })),
    assignmentSchedulesChanged: new Set<number>(),
  } as StateType
);

const divider = (
  <Box height="28px" bgcolor="#f0f0f0" marginTop="30px">
    {range(0, 4).map((_, i) => <hr key={+i} />)}
  </Box>
);

type AssignmentScheduleEditorProps = {
  assignmentSchedules: Array<AssignmentScheduleType>,
  assessments: Array<AssessmentType>,
  onSave: (assignmentScheduleData: AssignmentScheduleInputType[]) => Promise<void>,
  saving: boolean,
};
const AssignmentScheduleEditor: React.FC<AssignmentScheduleEditorProps> = (
  props: AssignmentScheduleEditorProps,
) => {
  const {
    assessments,
    onSave,
    saving,
    assignmentSchedules: currentAssignmentSchedules,
  } = props;
  const [state, dispatch] = useReducer(reducer, loadAssignmentSchedulesIntoState(currentAssignmentSchedules));

  const handleSave = () => {
    const editedAssignmentSchedules = state.assignmentSchedules.filter(
      (_, index) => state.assignmentSchedulesChanged.has(index),
    );
    onSave(editedAssignmentSchedules);
  };

  const handleAddAssignmentSchedule = () => {
    dispatch({ type: ActionTypeEnum.ADD_ASSIGNMENT_SCHEDULE });
  };

  return (
    <Box display="flex" width="100%" height="100%" paddingBottom="25px">
      <Box width="50%" padding="10px">
        <Text as="h1" margin="20px 0px">
          Assignment Schedule Editor
        </Text>
        {state.assignmentSchedules.map((assignmentSchedule, index) => (
          <Box key={`assignment-schedule-${+index}`} marginBottom="25px">
            <EditableAssignmentSchedule
              assignmentSchedule={assignmentSchedule}
              assessments={assessments}
              dispatch={dispatch}
              index={index}
            />
            {divider}
          </Box>
        ))}
        <AssignmentScheduleFooter
          onAddAssignmentSchedule={handleAddAssignmentSchedule}
          saving={saving}
          onSave={handleSave}
        />
      </Box>
    </Box>
  );
};

export default Memo(AssignmentScheduleEditor);
