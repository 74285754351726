import React, { useState } from 'react';
import { Button, FormControlLabel, Switch } from '@material-ui/core';
import {
  Link,
  Table,
  LoadingSpinner,
  TableColumn as Column,
  TableRow as Row,
  SortState,
  Box,
} from 'react-limbix-ui';

import { createColumn, createCell } from '@/utils/tableUtils';
import { ASSESSMENT_LIST } from '@/apollo/queries';
import { useQuery } from '@/hooks/apollo';
import history from '@/utils/history';

const AssessmentList: React.FC = () => {
  const [showArchived, setShowArchived] = useState(false);

  const { data, loading } = useQuery(ASSESSMENT_LIST, { variables: { showArchived } });

  if (loading) {
    return <LoadingSpinner />;
  }

  const assessments = data?.assessments || [];

  const baseAssessmentUrl = (assessmentUid: string) => (`/operations/assessment/${assessmentUid}`);

  const renderActions = (assessmentUid: string) => (
    <>
      <Link.RouterLink underlined to={`${baseAssessmentUrl(assessmentUid)}/edit`}>Edit</Link.RouterLink>
      {' | '}
      <Link.RouterLink underlined to={`${baseAssessmentUrl(assessmentUid)}/test`}>Test</Link.RouterLink>
    </>
  );

  const columns: Column[] = [
    createColumn('nameInternal', 'Name (Internal)', 'string', null, true),
    createColumn('name', 'Name (External)', 'string', null, true),
    createColumn('actions', 'Actions', 'string', '15%', false),
    createColumn('contextText', 'Context Text', 'string', null, false),
    createColumn('introText', 'Intro Text', 'string', null, false),
    createColumn('preQuestionText', 'Pre-Question Text', 'string', null, false),
    createColumn('archived', 'Archived', 'string', null, true),
  ];

  const rows: Row[] = assessments.map((assessment) => {
    const {
      uid,
      name,
      nameInternal,
      contextText,
      introText,
      preQuestionText,
      isArchived,
    } = assessment;
    return {
      id: uid,
      cells: [
        createCell('nameInternal', nameInternal, nameInternal),
        createCell('name', name, name),
        createCell('actions', 'Actions', renderActions(uid)),
        createCell('contextText', contextText, contextText),
        createCell('introText', introText, introText),
        createCell('preQuestionText', preQuestionText, preQuestionText),
        createCell('archived', isArchived.toString(), isArchived.toString()),
      ],
    } as Row;
  });

  const defaultSortState: SortState = { col: 'name', direction: 'asc' };

  return (
    <Box>
      <Button
        variant="contained"
        color="primary"
        style={{ marginBottom: 16, marginRight: 16 }}
        onClick={() => history.push('/operations/assessment/create')}
      >
        Create New Assessment
      </Button>
      <FormControlLabel
        value="start"
        control={(
          <Switch
            color="primary"
            checked={showArchived}
            value={showArchived}
            onChange={(_, checked) => setShowArchived(checked)}
          />
        )}
        label="Show Archived Assessments"
        labelPlacement="start"
      />
      <Table columns={columns} rows={rows} defaultSortState={defaultSortState} />
    </Box>
  );
};

export default AssessmentList;
