import { gql } from '@apollo/client';

export const IS_LOGGED_IN = gql`
  query isLoggedIn {
    isLoggedIn @client
  }
`;

export const LIMBIX_USER_QUERY = gql`
  query me {
    me {
      uid
      email
      firstName
      isResearch
      isOperations
    }
  }
`;

export const TOTP_AUTHENTICATOR_QUERY = gql`
  query totpAuthenticator {
    totpAuthenticator {
      qrcodeSvg
    }
  }
`;

export const STUDY_LIST_QUERY = gql`
  query limbixStudies {
    limbixStudies {
      name
      uid
      startTime
      endTime
      canViewStudy
      canChangeStudy
      canModifyParticipants
      canDeleteParticipants
      isBlinded
      enrollmentTrackerAssessment {
        name
      }
      clinicalConcernAssessment {
        name
      }
    }
  }
`;

export const STUDY_QUERY = gql`
  query limbixStudy($studyUid: String!) {
    limbixStudy(studyUid: $studyUid) {
      name
      uid
      startTime
      endTime
      status
      principalInvestigators {
        email
        uid
        isBlindedForStudy(studyUid: $studyUid)
      }
      coInvestigators {
        email
        uid
        isBlindedForStudy(studyUid: $studyUid)
      }
      studyCoordinators {
        email
        uid
        isBlindedForStudy(studyUid: $studyUid)
      }
      studyParticipants {
        participantId
        status
        user {
          accountType
        }
      }
    }
  }
`;

export const STUDY_PARTICIPANTS_DROPDOWN_QUERY = gql`
  query limbixStudy($studyUid: String!) {
    limbixStudy(studyUid: $studyUid) {
      studyParticipants {
        participantId
        user {
          uid
        }
      }
    }
  }
`;

export const DETERIORATION_ALERTS_QUERY = gql`
  query limbixStudy($studyUid: String!) {
    limbixStudy(studyUid: $studyUid) {
      name
      uid
      deteriorationAlerts
      clinicalConcernAssessment {
        name
      }
    }
  }
`;

export const ASSIGNMENT_QUERY = gql`
  query Assignment($assignmentUid: String!, $studyUid: String!) {
    assignment(assignmentUid: $assignmentUid, studyUid: $studyUid) {
      uid
      typeOfAssignment
      typeOfClient
      visibleDate
      dueDate
      startTime
      completeTime
      status
      user {
        uid
        firstName
        lastName
      }
      studyParticipant {
        participantId
        user {
          uid
        }
      }
      aboutStudyParticipant {
        participantId
        user {
          uid
        }
      }
      assessment {
        name
      }
      participantAssessmentResult {
        answers
        assessmentResultItems {
          value
          assessmentValue
          question {
            ordinal
            text
            uid
            questionType
          }
          textValue {
            value
          }
          dateValue
          selectedAnswers {
            text
            assessmentValue
          }
        }
        score
        assessment {
          name
          questions {
            uid
            text
            answers {
              uid
              text
              assessmentValue
            }
            questionType
          }
        }
      }
    }
  }
`;

export const STUDY_QUERY_PARTICIPANT_DATA = gql`
  query limbixStudy($studyUid: String!) {
    limbixStudy(studyUid: $studyUid) {
      name
      caseReportFormTemplate
      studyParticipants {
        participantId
        createdAt
        user {
          uid
          accountType
          email
          emailVerified
          phone
          accessAttempts
          parents {
            uid
            email
          }
       }
       study {
          uid
       }
       status
       canActivate
       canComplete
       canWithdraw
       canBeLostToFollowup
       canScreenFail
       canBeDropped
       canAccessPdt
       hasMobileTokens
       studyArm {
         name
       }
       notes
      }
    }
  }
`;

export const STUDY_PARTICIPANT_QUERY = gql`
query studyParticipant($studyUid: String!, $userUid: String!) {
  studyParticipant(studyUid: $studyUid, userUid: $userUid) {
    participantId
    activationTime
    completeTime
    turnsEighteenWhileEnrolled
    user {
      uid
      dateOfBirth
      gender
      currentAge
      ageAtEnrollment
      accountType
      email
      phone
      firstName
      localTimezone
      baselinePhqScore
      parents {
        uid
        email
        phone
        firstName
      }
    }
    status
    study {
      uid
      caseReportFormTemplate
      clinicalConcernAssessment {
        name
      }
    }
    studyArm {
      isInitialArm
    }
    notes
    userPdtData {
      currentModuleNumber
      currentTaskNumber
    }
    moodLogs {
      uid
    }
    behavioralActivations {
      uid
      completeTime
    }
    tasks {
      uid
      completeTime
    }
  }
}
`;

export const STUDY_FREE_FORM_TEXTS = gql`
  query studyFreeFormTexts($studyUid: String!, $date: DateTime!) {
    studyFreeFormTexts(studyUid: $studyUid, date: $date) {
      createdAt
      prompt
      value
      user {
        uid
      }
      participantId
    }
  }
`;

export const PARTICIPANT_FREE_FORM_TEXTS = gql`
  query participantFreeFormTexts($studyUid: String!, $userUid: String!) {
    participantFreeFormTexts(studyUid: $studyUid, userUid: $userUid) {
      uid
      createdAt
      prompt
      value
      user {
        uid
      }
      participantId
    }
  }
`;

export const STUDY_CLINICAL_CONCERN_ASSESSMENT = gql`
  query limbixStudy($studyUid: String!) {
    limbixStudy(studyUid: $studyUid) {
      clinicalConcernAssessment {
        questions {
          text
          ordinal
          questionType
          isSensitive
          answers {
            uid
            text
          }
        }
      }
    }
  }
`;

export const PARTICIPANT_CLINICAL_CONCERNS_QUERY = gql`
  query ParticipantClinicalConcerns($studyUid: String!, $userUid: String!, $limit: Int) {
    limbixStudy(studyUid: $studyUid) {
      clinicianReportAssessment {
        uid
      }
    }
    participantClinicalConcerns(studyUid: $studyUid, userUid: $userUid, limit: $limit) {
      uid
      answers
      user {
        uid
      }
      studyParticipant {
        participantId
        referralSource
        status
        studyArm {
          name
        }
      }
      createdAt
      clinicianReport {
        uid
        completeTime
      }
    }
  }
`;

export const STUDY_CLINICAL_CONCERNS_QUERY = gql`
  query StudyClinicalConcerns($studyUid: String!, $limit: Int) {
    limbixStudy(studyUid: $studyUid) {
      clinicianReportAssessment {
        uid
      }
    }
    studyClinicalConcerns(studyUid: $studyUid, limit: $limit) {
      uid
      answers
      user {
        uid
      }
      studyParticipant {
        participantId
        status
        studyArm {
          name
        }
        referralSource
      }
      createdAt
      clinicianReport {
        uid
        completeTime
      }
    }
  }
`;

export const STUDY_ENROLLMENT_TRACKER_ASSESSMENT = gql`
  query limbixStudy($studyUid: String!) {
    limbixStudy(studyUid: $studyUid) {
      enrollmentTrackerAssessment {
        questions {
          text
          ordinal
          questionType
          isOptional
          isSensitive
          answers {
            uid
            text
          }
        }
      }
    }
  }
`;

export const STUDY_ENROLLMENT_TRACKER_QUERY = gql`
  query StudyEnrollmentTracker($studyUid: String!) {
    limbixStudy(studyUid: $studyUid) {
      appAccessDurationDays
      studyParticipants {
        participantId
        status
        turnsEighteenWhileEnrolled
        studyArm {
          name
        }
        user {
          firstName
          lastName
          dateOfBirth
          ageAtEnrollment
          email
          phone
          parents {
            phone
            email
            firstName
            lastName
          }
        }
        referralSource
        activationTime
        enrollmentTrackerResult {
          uid
          answers
          createdAt
        }
      }
    }
  }
`;

export const PDT_LIST = gql`
  query Pdts {
    pdts {
      uid
      name
    }
  }
`;

export const CARETEAMS_LIST = gql`
  query Careteams($pdtUid: String!) {
    careteams(pdtUid: $pdtUid) {
      uid
      status
      enrolledTime
      canAccessPdt
      hasMobileTokens
      patient {
        uid
        notes
        user {
          firstName
          lastName
          dateOfBirth
          currentAge
          ageAtEnrollment
          email
          emailVerified
          accessAttempts
        }
      }
      practitioners {
        uid
        practitionerRole
        user {
          firstName
          lastName
          email
        }
      }
      pdt {
        uid
        name
      }
      userPdtData {
        appVersion
      }
    }
  }
`;

export const PRACTITIONERS_LIST = gql`
  query Practitioners($pdtUid: String!) {
    practitioners(pdtUid: $pdtUid) {
      uid
      identifier
      practitionerRole
      position
      notes
      portalAccessTime
      accessCodes {
        code
        expirationTime
        pdt {
          name
        }
      }
      organization {
        name
        phone
        email
      }
      user {
        firstName
        lastName
        email
        phone
        createdAt
        emailVerified
      }
      patients {
        uid
        user {
          email
          firstName
          lastName
        }
      }
    }
  }
`;

export const ASSESSMENT_LIST = gql`
  query Assessments($showArchived: Boolean) {
    assessments(showArchived: $showArchived) {
      uid
      isArchived
      isLocked
      name
      nameInternal
      contextText
      introText
      preQuestionText
    }
  }
`;

export const ASSESSMENT_QUERY = gql`
  query Assessment($assessmentUid: String!) {
    assessment(assessmentUid: $assessmentUid) {
      uid
      isLocked
      name
      nameInternal
      isArchived
      contextText
      introText
      preQuestionText
      questions {
        uid
        text
        keyString
        questionType
        ordinal
        isOptional
        isSensitive
        answers {
          uid
          text
          assessmentValue
        }
        conditions {
          dependsOnQuestion {
            uid
            questionType
            ordinal
          }
          dependsOnAnswer {
            uid
          }
          isNegated
        }
      }
    }
  }
`;

export const STUDY_PARTICIPANT_RESEARCH_ASSIGNMENTS_QUERY = gql`
  query StudyResearchAssignments($studyUid: String!, $participantUid: String!) {
    studyResearchAssignments(studyUid: $studyUid, aboutUserUid: $participantUid) {
      uid
      typeOfAssignment
      typeOfClient
      visibleDate
      dueDate
      weekInProgram
      assessment {
        name
      }
      user {
          uid
          firstName
          lastName
      }
      aboutStudyParticipant {
        participantId
        user {
          uid
        }
      }
    }
  }
`;

export const RESEARCH_ASSIGNMENT_QUERY = gql`
  query ResearchAssignment($assignmentUid: String!, $studyUid: String!) {
    assignment(assignmentUid: $assignmentUid, studyUid: $studyUid) {
      uid
      typeOfAssignment
      typeOfClient
      visibleDate
      dueDate
      startTime
      completeTime
      status
      canSkipQuestions
      canEditAfterCompletion
      studyParticipant {
        participantId
        user {
          uid
        }
      }
      user {
          accountType
          email
        }
      aboutStudyParticipant {
        participantId
        user {
          uid
        }
      }
      assessment {
        uid
        name
        contextText
        preQuestionText
        questions {
            ordinal
            uid
            text
            questionType
            isOptional
            conditions {
              ordinal
              question {
                uid
              }
              dependsOnAnswer {
                uid
              }
              dependsOnQuestion {
                uid
                questionType
                ordinal
              }
              isNegated
            }
            answers {
                uid
                text
            }
        }
      }
      researchAssessmentResult {
        lastUserToEdit {
          accountType
          email
        }
        uid
        answers
        assessmentResultItems {
          value
          assessmentValue
          question {
            ordinal
            text
            uid
          }
          textValue {
            value
          }
          dateValue
          selectedAnswers {
            text
            assessmentValue
          }
        }
        answers
        score
        assessment {
          name
          questions {
            text
            answers {
              uid
              text
              assessmentValue
            }
          }
        }
      }
    }
  }
`;

export const STUDY_ASSIGNMENTS_WITH_RESULTS_QUERY = gql`
  query StudyAssignments(
    $studyUid: String!,
    $userUid: String,
    $assessmentUids: [String],
    $accountType: String,
    $status: String
  ) {
    studyAssignments(
      studyUid: $studyUid,
      userUid: $userUid,
      assessmentUids: $assessmentUids,
      accountType: $accountType,
      status: $status
    ) {
        uid
        typeOfAssignment
        typeOfClient
        visibleDate
        dueDate
        completeTime
        weekInProgram
        status
        participantAssessmentResult {
          score
          createdAt
          assessmentResultItems {
            assessmentValue
            question {
              uid
            }
          }
          assessment {
            name
            nameInternal
            questions {
              uid
            }
          }
        }
        studyParticipant {
          participantId
          status
          user {
            uid
          }
        }
    }
  }
`;

export const STUDY_ASSIGNMENT_SCHEDULES = gql`
  query StudyAssignmentSchedules($studyUid: String!) {
    studyAssignmentSchedules(studyUid: $studyUid) {
      accountType
      assessment {
        name
      }
      count
      ordinal
      repeatFrequencyDays
      startTimeOffsetDays
      study {
        name
      }
      typeForAssignment
      typeOfClientForAssignment
      uid
      visibleDurationDays
    }
  }
`;
