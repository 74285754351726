import {
  ResopsUserType,
  ResopsUserAction,
  ResopsUserActionTypes,
} from '@/types';

type ResopsUserStateType = {
  resopsUser: ResopsUserType;
};

const defaultResopsUserTypeState: ResopsUserType = {
  isLoggedIn: false,
  isResearch: false,
  isOperations: false,
  studyPermissions: [],
};

export const defaultResopsUserState: ResopsUserStateType = {
  resopsUser: { ...defaultResopsUserTypeState } as ResopsUserType,
};

const resopsUserState: ResopsUserStateType = { ...defaultResopsUserState };

const ResopsUserReducer = (
  state = resopsUserState,
  dispatched: ResopsUserAction,
): ResopsUserStateType => {
  switch (dispatched.type) {
  case ResopsUserActionTypes.updateResopsUser:
    return {
      ...state,
      resopsUser: { ...dispatched.payload },
    };
  default:
    return state;
  }
};

export default ResopsUserReducer;
