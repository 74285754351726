import React from 'react';
import { useParams } from 'react-router-dom';
import {
  AssessmentForm,
  LoadingSpinner,
  Box,
  Text,
} from 'react-limbix-ui';

import { QueryAssessmentArgs, QuestionType } from '@/apollo/types';
import { UseParamsType } from '@/types';
import { ASSESSMENT_QUERY } from '@/apollo/queries';
import { useQuery } from '@/hooks/apollo';
import { useModal } from '@/hooks/redux';

const TestAssessment: React.FC = () => {
  const { assessmentUid } = useParams<UseParamsType>();
  const { data, loading } = useQuery<QueryAssessmentArgs>(ASSESSMENT_QUERY, {
    variables: {
      assessmentUid,
    },
  });
  const [showModal] = useModal();

  const renderSkippedQuestionsModal = (
    skippedQuestions: QuestionType[],
    onSkip: () => void, onReturn?:
    () => void,
  ) => {
    const questionsMissingAnswers = skippedQuestions.map((question) => (
      <Box key={question.uid}>
        <Text as="span">{question.text}</Text>
        <Text as="span" color="red">{question.isOptional ? '' : ' *'}</Text>
      </Box>
    ));
    showModal(
      'GENERIC_WARNING',
      {
        headerText: 'Are you sure you want to skip this question?',
        body: questionsMissingAnswers,
        primaryButtonText: 'Return to survey',
        secondaryButtonText: 'Skip questions and submit',
        onClickPrimaryButton: onReturn,
        onClickSecondaryButton: onSkip,
      },
    );
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  if (data?.assessment) {
    return (
      <Box>
        <AssessmentForm
          assessment={data.assessment}
          canSkipQuestions
          onSubmit={async () => console.log('submitted')}
          onCancel={async () => console.log('cancel')}
          onSubmitWithSkippedQuestions={renderSkippedQuestionsModal}
        />
      </Box>
    );
  }

  return (<></>);
};

export default TestAssessment;
