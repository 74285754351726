import { createAction } from '@reduxjs/toolkit';

import {
  AccountType,
  AssignmentStatus,
  PaginationType,
  AssignmentFiltersActionType,
} from '@/types';

const updateStudyUid = createAction<string>(AssignmentFiltersActionType.updateStudyUid);
const updateUserUid = createAction<string>(AssignmentFiltersActionType.updateUserUid);
const updateAccountType = createAction<AccountType>(AssignmentFiltersActionType.updateAccountType);
const updateStatusType = createAction<AssignmentStatus>(AssignmentFiltersActionType.updateStatusType);
const updatePagination = createAction<PaginationType>(AssignmentFiltersActionType.updatePagination);
const addAssessmentUids = createAction<Array<string>>(AssignmentFiltersActionType.addAssessmentUids);
const removeAssessmentUids = createAction<Array<string>>(AssignmentFiltersActionType.removeAssessmentUids);
const updateAssessmentUids = createAction<Array<string>>(AssignmentFiltersActionType.updateAssessmentUids);
const resetFilters = createAction<Array<string>>(AssignmentFiltersActionType.resetFilters);

export type AssignmentFiltersDispatchType = {
  updateStudyUid: typeof updateStudyUid,
  updateUserUid: typeof updateUserUid,
  updateAccountType: typeof updateAccountType,
  updateStatusType: typeof updateStatusType,
  updatePagination: typeof updatePagination,
  addAssessmentUids: typeof addAssessmentUids,
  removeAssessmentUids: typeof removeAssessmentUids,
  updateAssessmentUids: typeof updateAssessmentUids,
  resetFilters: typeof resetFilters,
}

export default {
  updateStudyUid,
  updateUserUid,
  updateAccountType,
  updateStatusType,
  updatePagination,
  addAssessmentUids,
  removeAssessmentUids,
  updateAssessmentUids,
  resetFilters,
};
