import { useSelector } from 'react-redux';

import useActions from './useActions';

import { RootState } from '@/store';
import { AssignmentFiltersStateType } from '@/types';
import { AssignmentFiltersDispatchType } from '@/store/actions/assignmentFiltersActions';

const useAssignmentFilters = (): [AssignmentFiltersStateType, AssignmentFiltersDispatchType] => {
  const { assignmentFilters: assignmentFiltersActions } = useActions();
  const assignmentFilters = useSelector((state: RootState) => (state.assignmentFilters));

  return [
    assignmentFilters,
    assignmentFiltersActions,
  ];
};

export default useAssignmentFilters;
