import { useEffect } from 'react';
import { gql, ApolloError } from '@apollo/client';

import useQuery from '../useQuery';
import useLazyQuery from '../useLazyQuery';

import {
  QueryLimbixStudyArgs,
  QueryParticipantClinicalConcernsArgs,
  QueryStudyClinicalConcernsArgs,
  AssessmentType,
  ClinicalConcernType,
} from '@/apollo/types';
import { STUDY_CLINICAL_CONCERNS_QUERY, PARTICIPANT_CLINICAL_CONCERNS_QUERY } from '@/apollo/queries';

export const STUDY_CLINICAL_CONCERN_ASSESSMENT = gql`
  query limbixStudy($studyUid: String!) {
    limbixStudy(studyUid: $studyUid) {
      clinicalConcernAssessment {
        questions {
          text
          ordinal
          questionType
          isSensitive
          answers {
            uid
            text
          }
        }
      }
    }
  }
`;

type GetClinicalConcerns = {
  loading: boolean,
  error: ApolloError,
  clinicalConcernAssessment: AssessmentType,
  clinicalConcerns: ClinicalConcernType[],
  clinicianReportAssessment: AssessmentType,
  refetch: () => void;
}
const getClinicalConcerns = (
  studyUid: string,
  userUid?: string,
  limit?: number,
): GetClinicalConcerns => {
  const {
    data: studyData,
    loading: assessmentLoading,
  } = useQuery<QueryLimbixStudyArgs>(STUDY_CLINICAL_CONCERN_ASSESSMENT, { variables: { studyUid } });

  const [getStudyClinicalConcerns, studyResult] = useLazyQuery<QueryStudyClinicalConcernsArgs>(
    STUDY_CLINICAL_CONCERNS_QUERY,
    {
      variables: {
        studyUid,
        limit,
      },
    },
  );

  const [getParticipantClinicalConcerns, participantResult] = useLazyQuery<QueryParticipantClinicalConcernsArgs>(
    PARTICIPANT_CLINICAL_CONCERNS_QUERY,
    {
      variables: {
        studyUid,
        userUid,
        limit,
      },
    },
  );

  useEffect(() => {
    if (userUid) {
      getParticipantClinicalConcerns();
    } else {
      getStudyClinicalConcerns();
    }
  }, []);

  const loading = assessmentLoading || studyResult?.loading || participantResult?.loading;
  const error = (
    userUid
      ? participantResult?.error
      : studyResult?.error
  );

  const clinicalConcernAssessment = studyData?.limbixStudy?.clinicalConcernAssessment;
  const clinicalConcerns = (
    userUid
      ? participantResult?.data?.participantClinicalConcerns
      : studyResult?.data?.studyClinicalConcerns
  ) || [];
  const clinicianReportAssessment = (
    userUid
      ? participantResult?.data?.limbixStudy?.clinicianReportAssessment
      : studyResult?.data?.limbixStudy?.clinicianReportAssessment
  );
  const refetch = () => {
    if (userUid) {
      getParticipantClinicalConcerns();
    } else {
      getStudyClinicalConcerns();
    }
  };

  return {
    loading,
    error,
    clinicalConcernAssessment,
    clinicalConcerns,
    clinicianReportAssessment,
    refetch,
  };
};

export default getClinicalConcerns;
