import { AssignmentScheduleInputType } from '@/apollo/types';

export type HTMLInputChangeEvent = React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>;

export type PointType = {
  x: number,
  y: number
}

export type LineType = {
  start: PointType,
  end: PointType,
}

export type ScheduleType = {
  name: string,
  fillColor: string,
  schedules: LineType[][],
}

export type StateType = {
  assignmentSchedules: AssignmentScheduleInputType[];
  assignmentSchedulesChanged: Set<number>;
}

export enum ActionTypeEnum {
  ADD_ASSIGNMENT_SCHEDULE,
  EDIT_ASSIGNMENT_SCHEDULE,
  DUPLICATE_ASSIGNMENT_SCHEDULE,
}

export type ActionType = {
  type: ActionTypeEnum.ADD_ASSIGNMENT_SCHEDULE,
} | {
  type: ActionTypeEnum.EDIT_ASSIGNMENT_SCHEDULE,
  index: number,
  field: keyof AssignmentScheduleInputType,
  value: AssignmentScheduleInputType[keyof AssignmentScheduleInputType],
} | {
  type: ActionTypeEnum.DUPLICATE_ASSIGNMENT_SCHEDULE,
  index: number,
}
