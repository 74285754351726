import React, { useState } from 'react';
import { TextField, Button } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';

import Styled from '../Modal.styles';

import { MutationEditPatientInfoArgs } from '@/apollo/types';
import { MutationResultType, GraphQLErrorsType } from '@/types';
import { CARETEAMS_LIST } from '@/apollo/queries';
import { EDIT_PATIENT_INFO } from '@/apollo/mutations';
import { ModalPayloadType } from '@/types/ReduxTypes';
import { useMutation } from '@/hooks/apollo';

type Props = {
  payload: ModalPayloadType['PATIENT_NOTES'];
  onClose: () => void;
};

const PatientNotes: React.FC<Props> = (props: Props) => {
  const {
    onClose,
    payload,
  } = props;

  const {
    careteam,
  } = payload;

  const inputRef = React.useRef<HTMLInputElement>();
  const [isEditingNotes, setIsEditingNotes] = useState(false);
  const [patientNotes, setPatientNotes] = useState(careteam.patient.notes);
  const [changeReasonValue, setChangeReasonValue] = useState<string>();
  const [errors, setErrors] = useState<GraphQLErrorsType>([]);

  if (isEditingNotes && patientNotes === null) {
    setPatientNotes('');
  }

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Tab') {
      event.preventDefault();
    }
  };

  const abandonChanges = () => {
    setPatientNotes(careteam.patient.notes);
    setIsEditingNotes(false);
  };

  const refetchQueries = [
    {
      query: CARETEAMS_LIST,
      variables: {
        pdtUid: careteam.pdt.uid,
      },
    },
  ];

  const [editParticipantInfoMutation] = useMutation<MutationEditPatientInfoArgs>(
    EDIT_PATIENT_INFO, { refetchQueries },
  );
  const editParticipantNotes = (notes: string, changeReason: string): Promise<MutationResultType> => (
    editParticipantInfoMutation({
      variables: {
        patientUid: careteam.patient.uid,
        notes,
        changeReason,
      },
    })
  );

  const updateNotes = async () => {
    const { errors: resultErrors } = await editParticipantNotes(patientNotes, changeReasonValue);
    if (resultErrors) {
      setErrors(resultErrors);
    } else {
      setIsEditingNotes(false);
    }
  };

  const viewNotes = (
    <div style={{ display: 'flex', width: '100%' }}>
      <div style={{ width: '100%' }}>
        <Styled.HeaderText style={{ marginBottom: 5 }}>
          View Patient Notes
        </Styled.HeaderText>
        <Styled.BodyText>
          Patient:
          {` ${careteam.patient.uid.substring(0, 6)}`}
        </Styled.BodyText>
        <TextField
          id="standard-multiline-flexible"
          variant="outlined"
          label=""
          multiline
          maxRows={10}
          minRows={10}
          value={patientNotes || 'None'}
          fullWidth
        />
      </div>
      <Styled.ButtonContainer>
        <Button
          variant="contained"
          onClick={onClose}
          style={{ marginRight: 15 }}
        >
          Close
        </Button>
        <Button
          variant="contained"
          color="primary"
          startIcon={<EditIcon />}
          onClick={() => setIsEditingNotes(true)}
        >
          Edit Notes
        </Button>
      </Styled.ButtonContainer>
    </div>
  );

  const editNotes = (
    <div style={{ display: 'flex', width: '100%' }}>
      <div style={{ width: '100%' }}>
        <Styled.HeaderText style={{ marginBottom: 5 }}>
          Edit Patient Notes
        </Styled.HeaderText>
        <Styled.BodyText>
          Patient:
          {` ${careteam.patient.uid.substring(0, 6)}`}
        </Styled.BodyText>
        <TextField
          id="standard-multiline-flexible"
          variant="outlined"
          label=""
          multiline
          maxRows={10}
          minRows={10}
          value={patientNotes}
          inputProps={{ ref: inputRef }}
          onKeyDown={handleKeyPress}
          fullWidth
          onChange={(event) => setPatientNotes(event.target.value)}
        />
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          name="change reason"
          label="Change Reason"
          id="change reason"
          onChange={(event) => setChangeReasonValue(event.target.value?.trim())}
        />
      </div>
      {errors.map((error) => (
        <div key={error.message} style={{ color: 'red' }}>
          {error.message}
        </div>
      ))}
      <Styled.ButtonContainer>
        <Button
          onClick={abandonChanges}
          variant="contained"
          style={{ marginRight: 15 }}
        >
          <Styled.NoButtonText>
            Abandon Changes
          </Styled.NoButtonText>
        </Button>
        <Styled.YesButton onClick={updateNotes} disabled={!changeReasonValue}>
          <Styled.YesButtonText>
            Update Notes
          </Styled.YesButtonText>
        </Styled.YesButton>
      </Styled.ButtonContainer>
    </div>
  );

  return (
    <div style={{ width: '416px', height: isEditingNotes ? '475px' : '375px' }}>
      <Styled.DialogContent>
        {isEditingNotes ? editNotes : viewNotes}
      </Styled.DialogContent>
    </div>
  );
};

export default PatientNotes;
