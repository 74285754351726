import React, { useState } from 'react';
import { TextField } from '@material-ui/core';

import Styled from '../Modal.styles';

import { MutationDeleteParticipantArgs } from '@/apollo/types';
import { STUDY_QUERY_PARTICIPANT_DATA } from '@/apollo/queries';
import { DELETE_PARTICIPANT } from '@/apollo/mutations';
import { MutationResultType } from '@/types';
import ExclamationSVG from '@/assets/ExclamationCircle.svg';
import { ModalPayloadType } from '@/types/ReduxTypes';
import { useMutation } from '@/hooks/apollo';

type Props = {
  payload: ModalPayloadType['DELETE_PARTICIPANT'];
  onClose: () => void;
};
const DeleteParticipant: React.FC<Props> = (props: Props) => {
  const {
    onClose,
    payload,
  } = props;
  const [textFieldValue, setTextFieldValue] = useState('');
  const [changeReasonValue, setChangeReasonValue] = useState<string>();
  const [deleteError, setDeleteError] = useState(false);

  const {
    studyParticipant,
  } = payload;

  const refetchQueries = [
    {
      query: STUDY_QUERY_PARTICIPANT_DATA,
      variables: {
        studyUid: studyParticipant.study.uid,
      },
    },
  ];

  const [deleteParticipantMutation] = useMutation<MutationDeleteParticipantArgs>(
    DELETE_PARTICIPANT, { refetchQueries },
  );
  const deleteParticipant = (changeReason: string): Promise<MutationResultType> => (
    deleteParticipantMutation({
      variables: {
        userUid: studyParticipant.user.uid,
        studyUid: studyParticipant.study.uid,
        changeReason,
      },
    })
  );

  const deleteAndClose = async () => {
    const result = await deleteParticipant(changeReasonValue);
    if (!result?.data?.deleteParticipant?.success) {
      setDeleteError(true);
    } else {
      onClose();
    }
  };

  const checkDisabled = () => {
    if (studyParticipant?.participantId) {
      return textFieldValue !== studyParticipant?.participantId || !changeReasonValue;
    }
    return textFieldValue !== 'None' || !changeReasonValue;
  };

  return (
    <div style={{ width: '416px', height: !deleteError ? '375px' : '425px' }}>
      <Styled.DialogContent>
        <div style={{ display: 'flex' }}>
          <Styled.WarningSVGContainer>
            <Styled.WarningSVG src={ExclamationSVG} />
          </Styled.WarningSVGContainer>
          <Styled.TextContainer>
            <Styled.HeaderText>
              Delete Participant
            </Styled.HeaderText>
            <Styled.BodyText>
              {'Are you sure that you want to delete this participant? \
              Please type their PID as shown here: \''}
              <strong>
                {studyParticipant?.participantId || 'None'}
              </strong>
              {'\' to confirm the deletion.'}
              <br />
              <small>
                {'If PID is \'None\' enter \'None\''}
              </small>
            </Styled.BodyText>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              name="participant id"
              label="PID"
              id="mfaCode"
              onChange={(event) => setTextFieldValue(event.target.value)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              name="change reason"
              label="Change Reason"
              id="change reason"
              onChange={(event) => setChangeReasonValue(event.target.value?.trim())}
            />
            {deleteError && (
              <div style={{ color: 'red' }}>
                User cannot be deleted in this study, their data must be retained
              </div>
            )}
          </Styled.TextContainer>
        </div>
        <Styled.ButtonContainer>
          <Styled.NoButton onClick={onClose}>
            <Styled.NoButtonText>
              No
            </Styled.NoButtonText>
          </Styled.NoButton>
          <Styled.YesButton
            onClick={deleteAndClose}
            disabled={checkDisabled()}
            color={checkDisabled() ? 'secondary' : 'primary'}
          >
            <Styled.YesButtonText>
              Yes - Delete
            </Styled.YesButtonText>
          </Styled.YesButton>
        </Styled.ButtonContainer>
      </Styled.DialogContent>
    </div>
  );
};

export default DeleteParticipant;
