import React from 'react';
import {
  Table,
  Link,
  LoadingSpinner,
  TableColumn as Column,
  TableRow as Row,
  SortState,
  Text,
  Box,
} from 'react-limbix-ui';

import ChangeLogDownloadLink from './ChangeLogDownloadLink';

import { LimbixStudyType } from '@/apollo/types';
import { STUDY_LIST_QUERY } from '@/apollo/queries';
import { formatDate } from '@/utils/dateUtils';
import { createColumn, createCell } from '@/utils/tableUtils';
import { useQuery } from '@/hooks/apollo';
import {
  DEFAULT_CLINICAL_CONCERN_LIMIT,
  DEFAULT_STUDY_ASSIGNMENTS_LIMIT,
} from '@/utils/constants';

const StudyList: React.FC = () => {
  const { data, loading, error } = useQuery(STUDY_LIST_QUERY);
  if (loading) {
    return <LoadingSpinner />;
  }

  if (!error && data.limbixStudies) {
    const { limbixStudies } = data;
    if (limbixStudies.length === 0) {
      return (
        <Text as="h1">
          {'It seems like you don\'t have access to any studies'}
          <br />
          Please reach out to Research or Eng if you think this is a mistake
        </Text>
      );
    }

    const baseStudyUrl = (studyUid: string) => (`/research/study/${studyUid}`);

    const renderActions = (study: LimbixStudyType) => (
      <>
        <Link.RouterLink underlined to={baseStudyUrl(study.uid)}>Manage Participants</Link.RouterLink>
        {' | '}
        <Link.RouterLink underlined to={`${baseStudyUrl(study.uid)}/settings`}>Edit Settings</Link.RouterLink>
        {' | '}
        <ChangeLogDownloadLink studyUid={study.uid} />
      </>
    );

    const renderView = (study: LimbixStudyType) => (
      <>
        {study.enrollmentTrackerAssessment && (
          <Link.RouterLink underlined to={`${baseStudyUrl(study.uid)}/enrollment-tracker`}>
            Enrollment Tracker
          </Link.RouterLink>
        )}
        {!study.isBlinded && study.clinicalConcernAssessment && (
          <>
            {' | '}
            <Link.RouterLink
              underlined
              to={`${baseStudyUrl(study.uid)}/clinical-concerns-log/?limit=${DEFAULT_CLINICAL_CONCERN_LIMIT}`}
            >
              Clinical Concerns Log
            </Link.RouterLink>
          </>
        )}
        {!study.isBlinded && (
          <>
            {' | '}
            <Link.RouterLink underlined to={`${baseStudyUrl(study.uid)}/free-form-text-review`}>
              Free-Form Text Review
            </Link.RouterLink>
          </>
        )}
        {' | '}
        <Link.RouterLink underlined to={`${baseStudyUrl(study.uid)}/deterioration-alerts`}>
          Deterioration Alerts
        </Link.RouterLink>
        {' | '}
        <Link.RouterLink
          underlined
          to={`${baseStudyUrl(study.uid)}/assignments?limit=${DEFAULT_STUDY_ASSIGNMENTS_LIMIT}`}
        >
          Assignment List
        </Link.RouterLink>
        {' | '}
        <Link.RouterLink underlined to={`${baseStudyUrl(study.uid)}/assignment-schedules`}>
          Assignment Schedules
        </Link.RouterLink>
      </>
    );

    const columns: Column[] = [
      createColumn('studyUid', 'UID', 'string', '5%', true),
      createColumn('studyName', 'Name', 'string', '15%', true),
      createColumn('startTime', 'Start Time', 'string', '15%', true),
      createColumn('actions', 'Actions', 'string', '20%', false),
      createColumn('view', 'View', 'string', '45%', false),
    ];

    const rows: Row[] = limbixStudies.map((limbixStudy) => (
      {
        id: limbixStudy.uid,
        cells: [
          createCell('studyUid', limbixStudy.uid, limbixStudy.uid.substring(0, 6)),
          createCell('studyName', limbixStudy.name, limbixStudy.name || 'None'),
          createCell('startTime', limbixStudy.startTime, formatDate(limbixStudy.startTime)),
          createCell('actions', 'actions', renderActions(limbixStudy)),
          createCell('view', 'view', renderView(limbixStudy)),
        ],
      } as Row
    ));

    const defaultSortState: SortState = { col: 'startTime', direction: 'desc' };

    return (
      <Box>
        <Table columns={columns} rows={rows} defaultSortState={defaultSortState} />
      </Box>
    );
  }

  return <></>;
};

export default StudyList;
