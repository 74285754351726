import React, { useEffect } from 'react';
import { useParams, useLocation, Redirect } from 'react-router-dom';
import { Grid, Switch } from '@material-ui/core';
import { LoadingSpinner, Box } from 'react-limbix-ui';

import { AssignmentScheduleViewer, AssignmentScheduleEditor } from '@/components';
import {
  QueryStudyAssignmentSchedulesArgs,
  MutationUpdateStudyAssignmentScheduleArgs,
  AssignmentScheduleInputType,
} from '@/apollo/types';
import { UseParamsType } from '@/types';
import { STUDY_ASSIGNMENT_SCHEDULES } from '@/apollo/queries';
import { UPDATE_STUDY_ASSIGNMENT_SCHEDULE } from '@/apollo/mutations';
import { useQuery, useMutation } from '@/hooks/apollo';
import { usePermissions, usePreventNavigation, useModal } from '@/hooks/redux';
import { useAssignmentEditorQueries } from '@/hooks/apollo/queries';
import history from '@/utils/history';

type Props = {
  studyUid: string,
}
const Viewer: React.FC<Props> = (props: Props) => {
  const { studyUid } = props;
  const { data, loading } = useQuery<QueryStudyAssignmentSchedulesArgs>(
    STUDY_ASSIGNMENT_SCHEDULES,
    {
      variables: {
        studyUid,
      },
    },
  );
  if (loading) {
    return <LoadingSpinner />;
  }
  const assignmentSchedules = data.studyAssignmentSchedules || [];
  return (
    <AssignmentScheduleViewer assignmentSchedules={assignmentSchedules} />
  );
};

const Editor: React.FC<Props> = (props: Props) => {
  const { studyUid } = props;
  const [{ studyPermissions }] = usePermissions();
  const [showModal] = useModal();
  const [, { setPreventNavigation, resetListenerEvent }] = usePreventNavigation();

  useEffect(() => {
    setPreventNavigation({ isBlocked: true, listeners: ['keyup', 'click'] });
  }, []);

  const permissions = studyPermissions.find((study) => study.uid === studyUid);

  const userIsSuperuserOrPI = permissions.canDeleteParticipants;
  if (!userIsSuperuserOrPI) {
    return <Redirect to={`/research/study/${studyUid}/assignment-schedules`} />;
  }

  const {
    loading,
    assessments,
    assignmentSchedules,
  } = useAssignmentEditorQueries(studyUid);

  const [updateAssignmentScheduleMutation, {
    loading: saving,
  }] = useMutation<MutationUpdateStudyAssignmentScheduleArgs>(
    UPDATE_STUDY_ASSIGNMENT_SCHEDULE,
  );
  const updateAssignmentSchedule = async (
    assignmentSchedule: AssignmentScheduleInputType,
    changeReason: string,
  ) => (
    updateAssignmentScheduleMutation(
      {
        variables: {
          studyUid,
          assignmentScheduleData: assignmentSchedule,
          changeReason,
        },
      },
    )
  );

  const handleSave = async (assignmentScheduleData: AssignmentScheduleInputType[]) => {
    const mutations = assignmentScheduleData.map((assignmentSchedule) => (
      (changeReason: string) => updateAssignmentSchedule(assignmentSchedule, changeReason)
    ));
    showModal(
      'CONFIRMATION_POPUP_CHANGE_REASON',
      {
        header: 'Confirm Assignment Schedule Edits',
        body: 'Please enter a reason for making these edits',
        mutations,
      },
    );
    resetListenerEvent();
  };

  if (loading) {
    return <LoadingSpinner />;
  }
  return (
    <AssignmentScheduleEditor
      assignmentSchedules={assignmentSchedules}
      assessments={assessments}
      onSave={handleSave}
      saving={saving}
    />
  );
};

const editModeSwitch = (isInEditMode: boolean, stateChangeUrl: string) => (
  <Box width="130px">
    <Grid component="label" container alignItems="center" spacing={1}>
      <Grid item>View</Grid>
      <Grid item>
        <Switch
          color="primary"
          checked={isInEditMode}
          value={isInEditMode}
          onChange={() => history.replace(stateChangeUrl)}
        />
      </Grid>
      <Grid item>Edit</Grid>
    </Grid>
  </Box>
);

const AssignmentSchedules: React.FC = () => {
  const { studyUid } = useParams<UseParamsType>();
  const location = useLocation();
  const [{ studyPermissions }] = usePermissions();
  const permissions = studyPermissions.find((study) => study.uid === studyUid);
  const isInEditMode = location.pathname.includes('edit');
  const stateChangeUrl = isInEditMode ? location.pathname.replace('/edit', '') : `${location.pathname}/edit`;

  const userIsSuperuserOrPI = permissions.canDeleteParticipants;

  return (
    <Box height="100%">
      {userIsSuperuserOrPI && (
        editModeSwitch(isInEditMode, stateChangeUrl)
      )}
      <Box height="100%">
        {!isInEditMode
          ? (
            <Viewer studyUid={studyUid} />
          ) : (
            <Editor studyUid={studyUid} />
          )}
      </Box>
    </Box>
  );
};

export default AssignmentSchedules;
