import {
  StateType,
  ActionTypeEnum,
  ActionType,
} from './AssignmentScheduleEditor.types';

import { replaceInArray } from '@/utils/arrayUtils';
import {
  AssignmentScheduleAccountType,
  AssignmentScheduleInputType,
  AssignmentScheduleScheduleOnStudyState,
  AssignmentTypeOfClient,
  AssignmentScheduleTypeOfNotificationForAssignment,
} from '@/apollo/types';

const blankAssignmentSchedule: AssignmentScheduleInputType = {
  uid: undefined,
  assessmentUid: '',
  count: 1,
  ordinal: 1,
  repeatFrequencyDays: 0,
  startTimeOffsetDays: 0,
  visibleDurationDays: 0,
  canEditAfterCompletion: false,
  canSkipQuestions: false,
  sendPushNotifications: true,
  sendEmailNotifications: true,
  sendSmsNotifications: true,
  accountType: AssignmentScheduleAccountType.Adult,
  scheduleOnStudyState: AssignmentScheduleScheduleOnStudyState.Activation,
  typeOfClientForAssignment: AssignmentTypeOfClient.Mobile,
  typeOfNotificationForAssignment: AssignmentScheduleTypeOfNotificationForAssignment.IntraStudy,
};

const updatedAssignmentSchedulesChangedSet = (assignmentSchedulesChangedSet: Set<number>, index: number) => (
  new Set(assignmentSchedulesChangedSet).add(index)
);

const addAssignmentSchedule = (state: StateType, action: ActionType): StateType => {
  if (action.type === ActionTypeEnum.ADD_ASSIGNMENT_SCHEDULE) {
    const assignmentSchedules = [...state.assignmentSchedules, blankAssignmentSchedule];
    const assignmentSchedulesChanged = updatedAssignmentSchedulesChangedSet(
      state.assignmentSchedulesChanged,
      assignmentSchedules.length - 1,
    );
    return {
      ...state,
      assignmentSchedulesChanged,
      assignmentSchedules,
    };
  }
  return state;
};

const editAssignmentSchedule = (state: StateType, action: ActionType): StateType => {
  if (action.type === ActionTypeEnum.EDIT_ASSIGNMENT_SCHEDULE) {
    const assignmentScheduleForUpdate = state.assignmentSchedules[action.index];
    const updatedAssignmentSchedule = {
      ...assignmentScheduleForUpdate,
      [action.field]: action.value,
    };
    const assignmentSchedulesChanged = updatedAssignmentSchedulesChangedSet(
      state.assignmentSchedulesChanged,
      action.index,
    );
    return {
      ...state,
      assignmentSchedules: replaceInArray(state.assignmentSchedules, updatedAssignmentSchedule, action.index),
      assignmentSchedulesChanged,
    };
  }
  return state;
};

const duplicateAssignmentSchedule = (state: StateType, action: ActionType): StateType => {
  if (action.type === ActionTypeEnum.DUPLICATE_ASSIGNMENT_SCHEDULE) {
    setTimeout(() => {
      window.scrollTo(0, document.body.scrollHeight + 250);
    }, 100);
    const duplicate = {
      ...state.assignmentSchedules[action.index],
      uid: undefined,
    } as AssignmentScheduleInputType;
    const assignmentSchedules = [...state.assignmentSchedules, duplicate];
    const assignmentSchedulesChanged = updatedAssignmentSchedulesChangedSet(
      state.assignmentSchedulesChanged,
      assignmentSchedules.length - 1,
    );
    return {
      ...state,
      assignmentSchedules,
      assignmentSchedulesChanged,
    };
  }
  return state;
};

export const reducer = (state: StateType, action: ActionType): StateType => {
  switch (action.type) {
  case ActionTypeEnum.ADD_ASSIGNMENT_SCHEDULE:
    return addAssignmentSchedule(state, action);
  case ActionTypeEnum.EDIT_ASSIGNMENT_SCHEDULE:
    return editAssignmentSchedule(state, action);
  case ActionTypeEnum.DUPLICATE_ASSIGNMENT_SCHEDULE:
    return duplicateAssignmentSchedule(state, action);
  default:
    throw new Error('Unexpected State');
  }
};
