import React, { useState } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import { Button } from '@material-ui/core';
import {
  StaticDatePicker,
  Table,
  LoadingSpinner,
  TableColumn as Column,
  TableRow as Row,
  SortState,
  Text,
  Box,
} from 'react-limbix-ui';

import { QueryStudyFreeFormTextsArgs } from '@/apollo/types';
import { STUDY_FREE_FORM_TEXTS } from '@/apollo/queries';
import {
  UseParamsType,
} from '@/types';
import { formatDate } from '@/utils/dateUtils';
import { createColumn, createCell, renderPID } from '@/utils/tableUtils';
import { useModal, usePermissions } from '@/hooks/redux';
import { useQuery } from '@/hooks/apollo';
import { isPermissionDeniedError } from '@/utils/errorUtils';

const FreeFormTextReview: React.FC = () => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const { studyUid } = useParams<UseParamsType>();
  const [showModal] = useModal();
  const [{ studyPermissions }] = usePermissions();
  const { loading, data, error } = useQuery<QueryStudyFreeFormTextsArgs>(STUDY_FREE_FORM_TEXTS, {
    variables: {
      studyUid,
      date: selectedDate,
    },
  });

  const permissions = studyPermissions.find((study) => study.uid === studyUid);
  if (permissions.isBlinded) {
    return (
      <Text as="h1">
        You are blinded for this study. You cannot review free form text.
      </Text>
    );
  }

  if (isPermissionDeniedError(error)) {
    return <Redirect to="/research" />;
  }

  const renderFreeFormTextTable = () => {
    if (loading || !data) {
      return <LoadingSpinner />;
    }
    if (data.studyFreeFormTexts?.length === 0) {
      return (
        <Text as="h1">
          {'It seems there aren\'t any free form texts for this date'}
          <br />
          If that seems like a mistake please reach out to Research and Eng
        </Text>
      );
    }

    const { studyFreeFormTexts } = data;

    const renderClinicalConcerns = (userUid: string, participantId: string) => (
      <Button
        variant="contained"
        onClick={() => showModal(
          'CLINICAL_CONCERNS',
          {
            userUid,
            participantId,
            studyUid,
            navigate: true,
          },
        )}
      >
        Clinical Concern
      </Button>
    );

    const columns: Column[] = [
      createColumn('pid', 'PID', 'string', null, true),
      createColumn('dateCreated', 'Date Created', 'date', null, true),
      createColumn('prompt', 'Prompt', 'string', null, true),
      createColumn('text', 'Text', 'string', null, true),
    ];

    if (permissions.canModifyParticipants) {
      columns.push(createColumn('clinicalConcerns', 'Clinical Concerns', 'string', null, false));
    }

    const rows: Row[] = studyFreeFormTexts.map((fft, index) => {
      const row: Row = {
        id: `${index}`,
        cells: [
          createCell('pid', fft.participantId, renderPID(studyUid, fft.user.uid, fft.participantId)),
          createCell('dateCreated', fft.createdAt, formatDate(fft.createdAt)),
          createCell('prompt', fft.prompt, fft.prompt),
          createCell('text', fft.value, fft.value),
        ],
      };
      if (permissions.canModifyParticipants) {
        row.cells.push(
          createCell(
            'clinicalConcerns',
            'Clinical Concerns',
            renderClinicalConcerns(fft.user.uid, fft.participantId),
          ),
        );
      }
      return row;
    });

    const defaultSortState: SortState = { col: 'dateCreated', direction: 'asc' };

    return (
      <Box width="100%">
        <Table columns={columns} rows={rows} defaultSortState={defaultSortState} />
      </Box>
    );
  };

  return (
    <Box width="100%" display="flex">
      <Box zIndex="0">
        <StaticDatePicker selectedDate={selectedDate} onChange={(date: Date) => setSelectedDate(date)} />
      </Box>
      <Box
        style={{
          width: 'calc(100% - 350px)',
          display: 'inline',
          zIndex: 0,
          marginLeft: '40px',
        }}
      >
        {renderFreeFormTextTable()}
      </Box>
    </Box>
  );
};

export default FreeFormTextReview;
