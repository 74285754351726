import {
  ModalActionTypes,
  ModalContentType,
  ModalAction,
} from '@/types';
import { ModalPayloadType } from '@/types/ReduxTypes';

export const defaultModalState = {
  visible: false,
  modalType: 'NONE' as ModalContentType,
  payload: (null as ModalPayloadType[keyof ModalPayloadType]),
};

export type ModalStateType = typeof defaultModalState;

const modalState = { ...defaultModalState };

const ModalReducer = (state = modalState, dispatched: ModalAction): ModalStateType => {
  switch (dispatched.type) {
  case ModalActionTypes.ShowModal:
    return {
      ...state,
      visible: true,
      modalType: dispatched.payload.modalType,
      payload: dispatched.payload.payload,
    };
  case ModalActionTypes.HideModal:
    return {
      ...state,
      visible: false,
      modalType: 'NONE',
      payload: null,
    };
  default:
    return { ...state };
  }
};

export default ModalReducer;
