import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { LoadingSpinner, Box } from 'react-limbix-ui';

import {
  QueryAssessmentArgs,
  AssessmentInputType,
  MutationEditAssessmentArgs,
} from '@/apollo/types';
import { AssessmentEditor } from '@/components';
import { UseParamsType } from '@/types';
import { EDIT_ASSESSMENT } from '@/apollo/mutations';
import { ASSESSMENT_QUERY } from '@/apollo/queries';
import { useModal, usePreventNavigation } from '@/hooks/redux';
import { useQuery, useMutation } from '@/hooks/apollo';

const EditAssessment: React.FC = () => {
  const { assessmentUid } = useParams<UseParamsType>();
  const [showModal] = useModal();
  const [, { setPreventNavigation, resetListenerEvent }] = usePreventNavigation();

  useEffect(() => {
    setPreventNavigation({ isBlocked: true, listeners: ['keyup', 'click'] });
  }, []);

  const { data, loading } = useQuery<QueryAssessmentArgs>(ASSESSMENT_QUERY, {
    variables: {
      assessmentUid,
    },
  });

  const refetchQueries = [{
    query: ASSESSMENT_QUERY,
    variables: {
      assessmentUid,
    },
  }];

  const [editAssessmentMutation, mutationResult] = useMutation<MutationEditAssessmentArgs>(
    EDIT_ASSESSMENT, { refetchQueries },
  );
  const editAssessment = async (assessmentData: AssessmentInputType, changeReason: string) => {
    const result = await editAssessmentMutation({
      variables: {
        assessmentData,
        changeReason,
      },
    });
    // if no errors, resetListenerEvent for future navigation
    if (!result?.errors?.length) {
      resetListenerEvent();
    }
    return result;
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  const handleSave = async (assessmentData: AssessmentInputType) => {
    showModal(
      'CONFIRMATION_POPUP_CHANGE_REASON',
      {
        header: 'Confirm Assessment Edits',
        body: 'Please enter a reason for making these edits',
        mutation: (changeReason: string) => editAssessment(assessmentData, changeReason),
      },
    );
  };

  if (data?.assessment) {
    return (
      <Box>
        <AssessmentEditor
          assessment={data.assessment}
          onSave={handleSave}
          saving={mutationResult?.loading}
          isLocked={data?.assessment?.isLocked}
        />
      </Box>
    );
  }

  return (<></>);
};

export default EditAssessment;
