import React from 'react';
import { Button } from '@material-ui/core';
import {
  Table,
  Link,
  LoadingSpinner,
  TableColumn as Column,
  TableRow as Row,
  SortState,
  Box,
} from 'react-limbix-ui';

import AccessCodeUrlModal from './AccessCodeUrlModal';

import { PdtType } from '@/apollo/types';
import { PDT_LIST } from '@/apollo/queries';
import { GET_ACCESS_CODE_CSV_URLS } from '@/apollo/mutations';
import { createColumn, createCell } from '@/utils/tableUtils';
import { useQuery, useMutation } from '@/hooks/apollo';
import { useModal } from '@/hooks/redux';
import history from '@/utils/history';

const PdtList: React.FC = () => {
  const { data, loading } = useQuery(PDT_LIST);
  const [getAccessCodeCsvUrlsMutation] = useMutation(GET_ACCESS_CODE_CSV_URLS);

  const [showModal] = useModal();

  if (loading) {
    return <LoadingSpinner />;
  }

  if (data?.pdts) {
    const { pdts } = data;
    if (pdts.length === 0) {
      return (
        <h1>
          {'It seems like there aren\'t any PDTs'}
          <br />
          Please reach out to Research or Eng if you think this is a mistake
        </h1>
      );
    }

    const basePdtUrl = (pdtUid: string) => (`/operations/pdt/${pdtUid}`);

    const renderActions = (pdt: PdtType) => (
      <>
        <Link.RouterLink underlined to={`${basePdtUrl(pdt.uid)}/patient`}>Manage Patients</Link.RouterLink>
        {' | '}
        <Link.RouterLink underlined to={`${basePdtUrl(pdt.uid)}/provider`}>Manage Providers</Link.RouterLink>
        {' | '}
        <Link.RouterLink underlined to={`${basePdtUrl(pdt.uid)}/settings`}>Edit Settings</Link.RouterLink>
      </>
    );

    const columns: Column[] = [
      createColumn('pdtName', 'Name', 'string', '15%', true),
      createColumn('actions', 'Actions', 'string', '85%', false),
    ];

    const rows: Row[] = pdts.map((pdt) => (
      {
        id: pdt.uid,
        cells: [
          createCell('pdtName', pdt.name, pdt.name),
          createCell('actions', 'actions', renderActions(pdt)),
        ],
      } as Row
    ));

    const defaultSortState: SortState = { col: 'pdtName', direction: 'desc' };

    const handleClickViewAssessments = () => {
      history.push('/operations/assessment');
    };

    const handleClickGeneratedAccessCodes = async () => {
      const { data: response, errors } = await getAccessCodeCsvUrlsMutation();

      if (errors?.length) {
        showModal(
          'ERROR_POPUP',
          {
            header: 'Error generating Access Code list urls',
            errors: errors.map((error) => error?.message),
          },
        );
      } else {
        const { getAccessCodeCsvUrls } = response;
        showModal(
          'GENERIC_MODAL',
          {
            headerText: 'Access Code CSV files (newest first)',
            secondaryButtonText: 'close',
            body: <AccessCodeUrlModal urls={getAccessCodeCsvUrls?.urls} />,
          },
        );
      }
    };

    return (
      <Box>
        <Button
          variant="contained"
          color="primary"
          style={{ marginBottom: 16, marginRight: 16 }}
          onClick={handleClickViewAssessments}
        >
          View Assessments
        </Button>
        <Button
          variant="contained"
          color="primary"
          style={{ marginBottom: 16, marginRight: 16 }}
          onClick={handleClickGeneratedAccessCodes}
        >
          View Generated Access Codes
        </Button>
        <Table columns={columns} rows={rows} defaultSortState={defaultSortState} />
      </Box>
    );
  }

  return <></>;
};

export default PdtList;
