import { Column, Row } from 'react-limbix-ui';

import { isDate, toDate } from './dateUtils';

import {
  QuestionType,
  QuestionQuestionType,
  ClinicalConcernType,
  EnrollmentTrackerResultType,
} from '@/apollo/types';

const defaultColumnSettings = {
  editable: false,
  disableColumnMenu: true,
  width: 200,
  static: false,
};

type AssessmentResultType = ClinicalConcernType | EnrollmentTrackerResultType;

type makeStaticColumnsArgs = {
  field: string;
  headerName: string;
};

export const makeStaticColumns = (columns: makeStaticColumnsArgs[]): Column[] => (
  columns.map((column) => ({
    ...defaultColumnSettings,
    ...column,
    static: true,
  }))
);

const questionTypeToCellData = (question: QuestionType): Partial<Column> => {
  switch (question.questionType) {
  case (QuestionQuestionType.DateField): {
    return {
      type: QuestionQuestionType.DateField,
    };
  }
  case (QuestionQuestionType.FreeFormText): {
    return {
      type: QuestionQuestionType.FreeFormText,
    };
  }
  case (QuestionQuestionType.MultipleChoice): {
    return {
      type: QuestionQuestionType.MultipleChoice,
      valueOptions: question.answers.map((answer) => (answer.text)),
    };
  }
  case (QuestionQuestionType.MultiSelect): {
    return {
      type: QuestionQuestionType.MultiSelect,
      valueOptions: question.answers.map((answer) => (answer.text)),
    };
  }
  default: {
    return {};
  }
  }
};

export const makeDynamicColumns = (questions: QuestionType[], editable = false): Column[] => (
  questions.map((question) => (
    {
      field: `${question.ordinal}`,
      headerName: question.text + (question.isOptional ? '' : '*'),
      ...defaultColumnSettings,
      ...questionTypeToCellData(question),
      editable,
      isSensitive: question.isSensitive,
    }
  ))
);

const mapAnswerToUid = (question: QuestionType): Record<string, string> => (
  Object.assign(
    {} as Record<string, string>,
    ...question.answers.map((answer) => {
      const obj: Record<string, string> = {};
      obj[`${answer.text}`] = answer.uid;
      return obj;
    }),
  ) as Record<string, string>
);

export const makeUidsForMultiAnswer = (questions: QuestionType[]): Record<string, string>[] => (
  questions.map((question) => {
    if (
      question.questionType === QuestionQuestionType.MultipleChoice
      || question.questionType === QuestionQuestionType.MultiSelect
    ) {
      return mapAnswerToUid(question);
    }
    return null;
  })
);

export const makeEmptyRow = (columns: Column[]): Record<string, string> => (
  Object.assign(
    {},
    ...columns.map((col) => {
      const obj: Row = {};
      if (col.type === QuestionQuestionType.MultiSelect) {
        obj[`${col.field}`] = { value: [], originalValue: [], render: '' };
        return obj;
      }
      obj[`${col.field}`] = { value: '', originalValue: '', render: '' };
      return obj;
    }),
  ) as Record<string, string>
);

export const makeDynamicCells = (columns: Column[], assessmentResults: AssessmentResultType[]): Row[] => (
  assessmentResults.map((assessmentResult): Row => {
    const answers = assessmentResult.answers !== '{}'
      ? JSON.parse(assessmentResult.answers)
      : makeEmptyRow(columns);
    Object.keys(answers).forEach((key) => {
      const value = isDate(answers[key]) ? toDate(answers[key]) : answers[key];
      answers[key] = { value, originalValue: value };
    });
    return {
      id: { value: assessmentResult.uid, originalValue: assessmentResult.uid },
      ...answers,
    };
  })
);

export const makeDynamicData = (
  questions: QuestionType[],
  assessmentResults: AssessmentResultType[],
  editable = false,
): { dynamicColumns: Column[], dynamicCells: Row[] } => {
  const dynamicColumns = makeDynamicColumns(questions, editable);
  const dynamicCells = makeDynamicCells(dynamicColumns, assessmentResults);
  return {
    dynamicColumns,
    dynamicCells,
  };
};

export const getEditedRowValues = (rows: Row[], keysToKeep?: string[]): Row[] => (
  rows.map((row) => (
    Object.assign(
      {},
      ...Object.entries(row)
        .filter(([key, cell]) => (keysToKeep?.includes(key) || key === 'id' || cell.hasBeenEdited))
        .map(([key, cell]) => {
          const obj: Row = {};
          obj[`${key}`] = { originalValue: cell.originalValue, value: cell.value };
          return obj;
        }),
    )
  ))
);

export const convertRowToAssessmentAnswers = (
  row: Row,
  uidsForMultiAnswer: Record<string, string>[],
  keysToSkip?: string[],
): string[] => (
  Object.entries(row)
    .filter(([key]) => (
      !(keysToSkip?.includes(key) || key === 'id')
    ))
    .map(([, cell], index) => {
      const { value } = cell;
      if (value instanceof Date) {
        return value.toISOString();
      }
      if (Array.isArray(value)) {
        const uids = value.map((val) => {
          const uidsForMultiAnswerMap = uidsForMultiAnswer[index];
          const subbedAnswer = uidsForMultiAnswerMap ? uidsForMultiAnswerMap[val] : val;
          return `${(subbedAnswer ?? val)}`;
        });
        return JSON.stringify(uids);
      }
      const uidsForMultiAnswerMap = uidsForMultiAnswer[index];
      const subbedAnswer = uidsForMultiAnswerMap ? uidsForMultiAnswerMap[value] : value;
      return `${(subbedAnswer ?? value)}`;
    })
);
