import { useEffect, useMemo } from 'react';
import { gql, ApolloError } from '@apollo/client';
import useDeepCompareEffect from 'use-deep-compare-effect';

import useQuery from '../useQuery';
import useLazyQuery from '../useLazyQuery';

import {
  QueryStudyAssignmentSchedulesArgs,
  AssignmentScheduleType,
  QueryStudyAssignmentsTableArgs, StudyAssignmentsTableType,
} from '@/apollo/types';
import { AccountType, AssignmentStatus } from '@/types';
import { useAssignmentFilters } from '@/hooks/redux';

const STUDY_ASSIGNMENT_SCHEDULES = gql`
  query StudyAssignmentSchedules($studyUid: String!) {
    studyAssignmentSchedules(studyUid: $studyUid) {
      typeForAssignment
      assessment {
        name
        uid
      }
    }
  }
`;

const STUDY_ASSIGNMENTS_QUERY = gql`
  query StudyAssignments(
    $studyUid: String!,
    $userUid: String,
    $assessmentUids: [String],
    $accountType: String,
    $status: String,
    $limit: Int,
  ) {
    studyAssignmentsTable(
      studyUid: $studyUid,
      userUid: $userUid,
      assessmentUids: $assessmentUids,
      accountType: $accountType,
      status: $status,
      limit: $limit
    ) {
        uid
        typeOfAssignment
        typeOfClient
        visibleDate
        dueDate
        completeTime
        weekInProgram
        status
        user {
          uid
        }
        aboutUser {
          uid
        }
        assessment {
          name
          nameInternal
        }
        participantId
        aboutParticipantId
        participantStatus
    }
  }
`;

/**
* Converts a list of Assignment Schedules into a map of assessment's name: uid
* @param assignmentSchedules - List of AssignmentSchedules
* @returns Record<string, string> where the keys are assessment names and the values are their uids
*/
const convertSchedulesIntoAssessmentData = (assignmentSchedules: AssignmentScheduleType[]) => {
  const studyAssignmentSchedules = (assignmentSchedules || []).map((schedule) => ({
    name: schedule?.assessment?.name,
    uid: schedule?.assessment?.uid,
  }));

  const withAdverseEventsRemoved = studyAssignmentSchedules.filter((assignment) => (
    assignment.name && assignment.uid
  ));

  return withAdverseEventsRemoved.reduce((agg, curr) => {
    const aggCopy = { ...agg };
    aggCopy[`${curr.name}`] = curr.uid;
    return aggCopy;
  }, {} as Record<string, string>);
};

type GetStudyAssignmentsType = {
  assessmentNameToUidMap: Record<string, string>,
  studyAssignments: Array<StudyAssignmentsTableType>,
  assignmentsLoading: boolean,
  assessmentsLoading: boolean,
  error: ApolloError,
}
const getStudyAssignments = (
  studyUid: string,
  userUid?: string,
  limit?: number,
): GetStudyAssignmentsType => {
  const [filters, assignmentFiltersActions] = useAssignmentFilters();

  const [getStudyAssignmentsQuery, {
    data,
    loading,
    error,
  }] = useLazyQuery<QueryStudyAssignmentsTableArgs>(
    STUDY_ASSIGNMENTS_QUERY,
    {
      variables: {
        ...filters,
        studyUid,
        userUid,
        limit,
      },
    },
  );
  const {
    data: scheduleData,
    loading: schedulesLoading,
  } = useQuery<QueryStudyAssignmentSchedulesArgs>(STUDY_ASSIGNMENT_SCHEDULES, { variables: { studyUid } });

  const assessmentNameToUidMap = useMemo(() => (
    convertSchedulesIntoAssessmentData(scheduleData?.studyAssignmentSchedules || [])
  ), [scheduleData?.studyAssignmentSchedules]);

  useEffect(() => {
    assignmentFiltersActions.updateStudyUid(studyUid);
    assignmentFiltersActions.updateUserUid(userUid);
  }, [studyUid, userUid]);

  useDeepCompareEffect(() => {
    if (filters.studyUid) {
      const cleanVariables = {
        ...filters,
        limit,
      };
      if (cleanVariables.accountType === AccountType.None) {
        cleanVariables.accountType = null;
      }
      if (cleanVariables.status === AssignmentStatus.None) {
        cleanVariables.status = null;
      }
      if (!cleanVariables.assessmentUids?.length) {
        cleanVariables.assessmentUids = [''];
      }
      (async () => {
        await getStudyAssignmentsQuery({
          variables: cleanVariables,
        });
      })();
    }
  }, [filters, limit]);

  const studyAssignments = data?.studyAssignmentsTable || [];

  return {
    assessmentNameToUidMap,
    studyAssignments,
    assignmentsLoading: loading,
    assessmentsLoading: schedulesLoading,
    error,
  };
};

export default getStudyAssignments;
