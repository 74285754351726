import React, { useState } from 'react';
import {
  Table,
  Link,
  TableColumn as Column,
  TableRow as Row,
  SortState,
  Box,
} from 'react-limbix-ui';

import AdminActions from './AdminActions';

import { PractitionerType, OrganizationType } from '@/apollo/types';

import { SearchBar } from '@/components';
import { createColumn, createCell, hasSearchString } from '@/utils/tableUtils';
import { formatPractitionerName } from '@/utils/stringUtils';
import { formatDate } from '@/utils/dateUtils';
import { useModal } from '@/hooks/redux';

type Props = {
  pdtUid: string;
  practitioners?: PractitionerType[];
  searchFilter?: string;
};

const ProviderTable: React.FC<Props> = (props: Props) => {
  const {
    practitioners,
    searchFilter,
    pdtUid,
  } = props;
  const [showModal] = useModal();
  const [searchString, setSearchString] = useState(searchFilter || '');

  const renderName = (practitioner: PractitionerType) => {
    const basePDTUrl = `/operations/pdt/${pdtUid}`;
    const linkToUID = `${basePDTUrl}/provider/${practitioner.uid}`;
    return <Link.RouterLink to={linkToUID} fontSize="16px">{formatPractitionerName(practitioner)}</Link.RouterLink>;
  };

  const renderAccessCodes = (practitioner: PractitionerType) => (
    <Box>
      <Box>
        {practitioner?.accessCodes?.find((accessCode) => !accessCode.expirationTime)?.code || ''}
      </Box>
      <Link.ButtonLink
        onClick={
          () => showModal(
            'ACCESS_CODES',
            {
              practitioner,
              pdtUid,
            },
          )
        }
      >
        view codes
      </Link.ButtonLink>
    </Box>
  );

  const renderOrganization = (organization: OrganizationType) => (
    <Link.ButtonLink
      onClick={
        () => showModal(
          'ORGANIZATION_INFO',
          {
            organization,
          },
        )
      }
    >
      {organization.name}
    </Link.ButtonLink>
  );

  const renderAdminActions = (practitioner: PractitionerType) => (
    <AdminActions practitioner={practitioner} pdtUid={pdtUid} />
  );

  const renderNotes = (practitioner: PractitionerType) => (
    <Link.ButtonLink
      onClick={
        () => showModal(
          'PRACTITIONER_NOTES',
          {
            practitioner,
            pdtUid,
          },
        )
      }
    >
      view
    </Link.ButtonLink>
  );

  const renderPortalAccess = (practitioner: PractitionerType) => (
    <Link.ButtonLink
      onClick={
        () => showModal(
          'PORTAL_ACCESS',
          {
            practitioner,
            pdtUid,
          },
        )
      }
    >
      {formatDate(practitioner.portalAccessTime)}
    </Link.ButtonLink>
  );

  const columns: Column[] = [
    createColumn('name', 'Name', 'string', null, true),
    createColumn('phone', 'Phone Number', 'string', null, true),
    createColumn('email', 'Email', 'string', null, true),
    createColumn('npi', 'NPI', 'string', null, true),
    createColumn('createdAt', 'Created At', 'date', null, true),
    createColumn('accessCode', 'Access Code', 'string', null, false),
    createColumn('role', 'Role', 'string', '5%', true),
    createColumn('position', 'Position', 'string', null, true),
    createColumn('organization', 'Provider Organization', 'string', null, false),
    createColumn('admin', 'Admin Actions', 'string', null, false),
    createColumn('notes', 'Notes', 'string', '5%', false),
    createColumn('providerPortalAccess', 'Provider Portal Access', 'string', null, true),
  ];

  const rows: Row[] = practitioners
    .filter((practitioner) => {
      if (!searchString || searchString === '') {
        return true;
      }
      const name = `${practitioner.user.lastName}, ${practitioner.user.firstName}`.toLowerCase();
      const npi = practitioner?.identifier?.toLowerCase();
      const email = practitioner?.user?.email?.toLowerCase();
      const organization = practitioner?.organization?.name.toLowerCase();
      try {
        return hasSearchString([name, npi, email, organization], searchString);
      } catch (error) {
        return true;
      }
    })
    .map((practitioner) => {
      const { organization, user } = practitioner;
      return {
        id: practitioner.uid,
        cells: [
          createCell('name', formatPractitionerName(practitioner), renderName(practitioner)),
          createCell('phone', user.phone, user.phone),
          createCell('email', user.email, user.email),
          createCell('npi', practitioner.identifier, practitioner.identifier),
          createCell('createdAt', practitioner.user.createdAt, formatDate(practitioner.user.createdAt)),
          createCell('accessCode', 'Access Code', renderAccessCodes(practitioner)),
          createCell('role', practitioner.practitionerRole, practitioner.practitionerRole),
          createCell('position', practitioner.position, practitioner.position),
          createCell('organization', 'ProviderOrganization', renderOrganization(organization)),
          createCell('admin', 'Admin', renderAdminActions(practitioner)),
          createCell('notes', 'Notes', renderNotes(practitioner)),
          createCell('providerPortalAccess', 'Provider Portal Access', renderPortalAccess(practitioner)),
        ],
      } as Row;
    });

  const defaultSortState: SortState = { col: '', direction: 'desc' };

  return (
    <Box>
      <Box marginBottom="20px" float="right">
        <SearchBar
          placeholder="Filter Providers"
          defaultValue={searchString}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => (
            setSearchString(event.currentTarget.value?.toLowerCase() || '')
          )}
        />
      </Box>
      <Table columns={columns} rows={rows} defaultSortState={defaultSortState} />
    </Box>
  );
};

export default ProviderTable;
