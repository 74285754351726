import {
  AccountType,
  AssignmentStatus,
  AssignmentFiltersActionType,
  AssignmentFiltersAction,
  AssignmentFiltersStateType,
} from '@/types';

const initialState = {
  studyUid: null,
  userUid: null,
  assessmentUids: [],
  status: AssignmentStatus.Past_Due,
  accountType: AccountType.None,
  limit: null,
  offset: null,
  orderBy: ['visible_date'],
} as AssignmentFiltersStateType;

export const defaultAssignmentFiltersState: AssignmentFiltersStateType = {
  ...initialState,
};

const updateStudyUid = (
  state: AssignmentFiltersStateType,
  dispatched: AssignmentFiltersAction,
): AssignmentFiltersStateType => {
  if (dispatched.type === AssignmentFiltersActionType.updateStudyUid) {
    return {
      ...state,
      studyUid: dispatched.payload,
    };
  }
  return state;
};

const updateUserUid = (
  state: AssignmentFiltersStateType,
  dispatched: AssignmentFiltersAction,
): AssignmentFiltersStateType => {
  if (dispatched.type === AssignmentFiltersActionType.updateUserUid) {
    return {
      ...state,
      userUid: dispatched.payload,
    };
  }
  return state;
};

const updateAccountType = (
  state: AssignmentFiltersStateType,
  dispatched: AssignmentFiltersAction,
): AssignmentFiltersStateType => {
  if (dispatched.type === AssignmentFiltersActionType.updateAccountType) {
    return {
      ...state,
      accountType: dispatched.payload,
    };
  }
  return state;
};

const updateStatusType = (
  state: AssignmentFiltersStateType,
  dispatched: AssignmentFiltersAction,
): AssignmentFiltersStateType => {
  if (dispatched.type === AssignmentFiltersActionType.updateStatusType) {
    return {
      ...state,
      status: dispatched.payload,
    };
  }
  return state;
};

const updatePagination = (
  state: AssignmentFiltersStateType,
  dispatched: AssignmentFiltersAction,
): AssignmentFiltersStateType => {
  if (dispatched.type === AssignmentFiltersActionType.updatePagination) {
    return {
      ...state,
      limit: dispatched.payload.limit,
      offset: dispatched.payload.offset,
      orderBy: dispatched.payload.orderBy,
    };
  }
  return state;
};

const addAssessmentUids = (
  state: AssignmentFiltersStateType,
  dispatched: AssignmentFiltersAction,
): AssignmentFiltersStateType => {
  if (dispatched.type === AssignmentFiltersActionType.addAssessmentUids) {
    const assessmentUids = Array.isArray(state.assessmentUids) ? state.assessmentUids : [];
    const assessmentUidsSet = new Set(assessmentUids);

    dispatched.payload.forEach((value) => {
      if (!assessmentUidsSet.has(value)) {
        assessmentUidsSet.add(value);
      }
    });

    return {
      ...state,
      assessmentUids: Array.from(assessmentUidsSet).sort(),
    };
  }
  return state;
};

const removeAssessmentUids = (
  state: AssignmentFiltersStateType,
  dispatched: AssignmentFiltersAction,
): AssignmentFiltersStateType => {
  if (dispatched.type === AssignmentFiltersActionType.removeAssessmentUids) {
    const assessmentUids = Array.isArray(state.assessmentUids) ? state.assessmentUids : [];
    const assessmentUidsSet = new Set(assessmentUids);

    dispatched.payload.forEach((value) => {
      if (assessmentUidsSet.has(value)) {
        assessmentUidsSet.delete(value);
      }
    });

    return {
      ...state,
      assessmentUids: Array.from(assessmentUidsSet).sort(),
    };
  }
  return state;
};

const updateAssessmentUids = (
  state: AssignmentFiltersStateType,
  dispatched: AssignmentFiltersAction,
): AssignmentFiltersStateType => {
  if (dispatched.type === AssignmentFiltersActionType.updateAssessmentUids) {
    return {
      ...state,
      assessmentUids: dispatched.payload,
    };
  }
  return state;
};

const resetFilters = (
  state: AssignmentFiltersStateType,
  dispatched: AssignmentFiltersAction,
): AssignmentFiltersStateType => {
  if (dispatched.type === AssignmentFiltersActionType.resetFilters) {
    return {
      ...initialState,
      assessmentUids: dispatched.payload,
      studyUid: state.studyUid,
      userUid: state.userUid,
      status: AssignmentStatus.None,
    };
  }
  return state;
};

const AssignmentFiltersReducer = (
  state = defaultAssignmentFiltersState,
  dispatched: AssignmentFiltersAction,
): AssignmentFiltersStateType => {
  switch (dispatched.type) {
  case AssignmentFiltersActionType.updateStudyUid:
    return updateStudyUid(state, dispatched);
  case AssignmentFiltersActionType.updateUserUid:
    return updateUserUid(state, dispatched);
  case AssignmentFiltersActionType.updateAccountType:
    return updateAccountType(state, dispatched);
  case AssignmentFiltersActionType.updateStatusType:
    return updateStatusType(state, dispatched);
  case AssignmentFiltersActionType.updatePagination:
    return updatePagination(state, dispatched);
  case AssignmentFiltersActionType.addAssessmentUids:
    return addAssessmentUids(state, dispatched);
  case AssignmentFiltersActionType.removeAssessmentUids:
    return removeAssessmentUids(state, dispatched);
  case AssignmentFiltersActionType.updateAssessmentUids:
    return updateAssessmentUids(state, dispatched);
  case AssignmentFiltersActionType.resetFilters:
    return resetFilters(state, dispatched);
  default:
    return state;
  }
};

export default AssignmentFiltersReducer;
