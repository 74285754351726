import React from 'react';
import { useParams, Redirect } from 'react-router-dom';
import {
  LoadingSpinner,
  Table,
  Link,
  TableColumn as Column,
  TableRow as Row,
  SortState,
  Box, Text,
} from 'react-limbix-ui';

import {
  AssignmentFilters,
} from '@/components';
import {
  AssignmentTypeOfClient,
} from '@/apollo/types';
import { UseParamsType } from '@/types';
import { createColumn, createCell, renderPID } from '@/utils/tableUtils';
import { getStudyAssignments } from '@/hooks/apollo/queries';
import { isPermissionDeniedError } from '@/utils/errorUtils';
import { formatDate } from '@/utils/dateUtils';
import { ASSIGNMENT_FILTER_WIDTH } from '@/utils/constants';
import { usePermissions } from '@/hooks/redux';
import { useLimit } from '@/hooks/useLimit';

const AssignmentList: React.FC = () => {
  const limit = useLimit();
  const { studyUid, userUid } = useParams<UseParamsType>();
  const [{ studyPermissions }] = usePermissions();

  const {
    assessmentNameToUidMap,
    studyAssignments,
    assessmentsLoading,
    assignmentsLoading,
    error,
  } = getStudyAssignments(studyUid, userUid, limit);

  const permissions = studyPermissions.find((permissons) => permissons.uid === studyUid);

  if (isPermissionDeniedError(error)) {
    return <Redirect to="/research" />;
  }

  const baseStudyUrl = `/research/study/${studyUid}`;

  const renderAssessmentName = (
    assignmentUserUid: string,
    aboutUserUid: string,
    assessmentName: string,
    assignmentUid: string,
    clientType: AssignmentTypeOfClient,
  ) => {
    const assignmentParam = clientType === AssignmentTypeOfClient.ResopsPortal ? 'research_assignments' : 'assignments';
    const userUidParam = clientType === AssignmentTypeOfClient.ResopsPortal ? aboutUserUid : assignmentUserUid;
    const linkToAssignment = `${baseStudyUrl}/participant/${userUidParam}/${assignmentParam}/${assignmentUid}`;
    return <Link.RouterLink to={linkToAssignment} fontSize="16px">{assessmentName || 'None'}</Link.RouterLink>;
  };

  const renderWeekInProgram = (weekInProgram: string, clientType: AssignmentTypeOfClient) => {
    if (!weekInProgram) {
      return clientType === AssignmentTypeOfClient.ResopsPortal ? 'n/a - research assignment' : 'program not started';
    }
    if (weekInProgram === '0') {
      return 'baseline';
    }
    return `end of week ${weekInProgram}`;
  };

  const columns: Column[] = [
    createColumn('assessmentName', 'Assessment Name', 'string', null, true),
    createColumn('pid', 'PID', 'string', null, true),
    createColumn('participantStatus', 'Participant Status', 'string', null, true),
    createColumn('visibleDate', 'Visible Date', 'date', null, true),
    createColumn('dueDate', 'Due Date', 'date', null, true),
    createColumn('completeTime', 'Complete Time', 'date', null, true),
    createColumn('weekInProgram', 'Week In Program', 'number', null, true),
    createColumn('assignmentStatus', 'Assignment Status', 'string', null, true),
    createColumn('clientType', 'Client Type', 'string', null, true),
    createColumn('assignmentType', 'Assignment Type', 'string', null, true),
    createColumn('aboutUser', 'About User', 'string', null, true),
  ];

  const cleanedAssignments = studyAssignments
    .filter((assignment) => (
      // If blinded, filter out research assignments. Since accountType is not available on assignment,
      // we check if participantId is null which is equivalent.
      !permissions?.isBlinded || !!assignment.participantId
    ))
    .map((assignment) => (
      {
        uid: assignment.uid,
        assessmentName: (
          assignment.assessment?.name || assignment?.assessment?.nameInternal || assignment?.typeOfAssignment || 'None'
        ),
        assignmentUid: assignment.uid,
        pid: assignment?.participantId,
        userUid: assignment?.user?.uid,
        participantStatus: assignment.participantStatus,
        visibleDate: assignment?.visibleDate,
        dueDate: assignment?.dueDate,
        completeTime: assignment?.completeTime,
        weekInProgram: assignment?.weekInProgram,
        clientType: assignment?.typeOfClient,
        assignmentType: assignment?.typeOfAssignment,
        aboutParticipantPid: assignment?.aboutParticipantId,
        aboutParticipantUserUid: assignment?.aboutUser?.uid,
        assignmentStatus: assignment?.status,
      }
    ));

  const rows: Row[] = cleanedAssignments.map((assignment) => (
     {
       id: assignment.uid,
       cells: [
         createCell(
           'assessmentName',
           assignment.assessmentName,
           renderAssessmentName(
             assignment.userUid,
             assignment.aboutParticipantUserUid,
             assignment.assessmentName,
             assignment.assignmentUid,
             assignment.clientType,
           ),
         ),
         createCell('pid', assignment.pid, renderPID(studyUid, assignment.userUid, assignment.pid)),
         createCell('participantStatus', assignment.participantStatus, assignment.participantStatus),
         createCell('visibleDate', assignment.visibleDate, formatDate(assignment.visibleDate)),
         createCell('dueDate', assignment.dueDate, formatDate(assignment.dueDate)),
         createCell('completeTime', assignment.completeTime, formatDate(assignment.completeTime)),
         createCell(
           'weekInProgram',
           parseInt(assignment.weekInProgram, 10),
           renderWeekInProgram(assignment.weekInProgram, assignment.clientType),
         ),
         createCell('assignmentStatus', assignment.assignmentStatus, assignment.assignmentStatus),
         createCell('clientType', assignment.clientType, assignment.clientType),
         createCell('assignmentType', assignment.assignmentType, assignment.assignmentType),
         createCell(
           'aboutUser',
           assignment.aboutParticipantUserUid,
           renderPID(studyUid, assignment.aboutParticipantUserUid, assignment.aboutParticipantPid),
         ),
       ],
     } as Row
  ));

  const defaultSortState: SortState = { col: 'dueDate', direction: 'desc' };

  return (
    <Box height="calc(100% - 30px)" width="100%" display="inline-flex">
      <Box maxHeight="100%" overflow="scroll" width={ASSIGNMENT_FILTER_WIDTH}>
        {assessmentsLoading ? (
          <LoadingSpinner />
        ) : (
          <AssignmentFilters assessmentNameToUidMap={assessmentNameToUidMap} />
        )}
      </Box>
      <Box maxHeight="100%" overflow="scroll" width={`calc(100% - ${ASSIGNMENT_FILTER_WIDTH})`}>
        {
          limit !== null && (
            <Text fontSize={16}>
              {'Limiting to '}
              <b>{limit}</b>
              {' most recent assignments'}
            </Text>
          )
        }
        {assignmentsLoading ? (
          <LoadingSpinner />
        ) : (
          <Table columns={columns} rows={rows} defaultSortState={defaultSortState} />
        )}
      </Box>
    </Box>
  );
};

export default AssignmentList;
