import React from 'react';
import { Button } from '@material-ui/core';
import { useParams, Redirect } from 'react-router-dom';
import {
  Table,
  LoadingSpinner,
  TableColumn as Column,
  TableRow as Row,
  SortState,
  Box,
  Text,
} from 'react-limbix-ui';

import { QueryParticipantFreeFormTextsArgs } from '@/apollo/types';
import { PARTICIPANT_FREE_FORM_TEXTS } from '@/apollo/queries';
import {
  UseParamsType,
} from '@/types';
import { formatDate } from '@/utils/dateUtils';
import { createColumn, createCell, renderPID } from '@/utils/tableUtils';
import { useModal, usePermissions } from '@/hooks/redux';
import { useQuery } from '@/hooks/apollo';
import { isPermissionDeniedError } from '@/utils/errorUtils';

const FreeFormTexts: React.FC = () => {
  const [showModal] = useModal();
  const [{ studyPermissions }] = usePermissions();
  const { studyUid, userUid } = useParams<UseParamsType>();
  const { loading, data, error } = useQuery<QueryParticipantFreeFormTextsArgs>(PARTICIPANT_FREE_FORM_TEXTS, {
    variables: {
      studyUid,
      userUid,
    },
  });

  if (loading) {
    return <LoadingSpinner />;
  }

  const permissions = studyPermissions.find((study) => study.uid === studyUid);
  if (permissions.isBlinded) {
    return (
      <Text as="h1">
        You are blinded for this study. You cannot review free form text.
      </Text>
    );
  }

  if (isPermissionDeniedError(error)) {
    return <Redirect to="/research" />;
  }

  const { participantFreeFormTexts } = data;

  if (!participantFreeFormTexts || participantFreeFormTexts.length === 0) {
    return (
      <Text as="h1">
        {'It seems there aren\'t any free form texts for this participant'}
        <br />
        If that seems like a mistake please reach out to Research and Eng
      </Text>
    );
  }

  const renderClinicalConcerns = (participantId: string) => (
    <Button
      variant="contained"
      onClick={() => showModal(
        'CLINICAL_CONCERNS',
        {
          userUid,
          participantId,
          studyUid,
          navigate: true,
        },
      )}
    >
      Clinical Concern
    </Button>
  );

  const columns: Column[] = [
    createColumn('pid', 'PID', 'string', null, true),
    createColumn('dateCreated', 'Date Created', 'date', null, true),
    createColumn('prompt', 'Prompt', 'string', null, true),
    createColumn('text', 'Text', 'string', null, true),
  ];

  if (permissions.canModifyParticipants) {
    columns.push(createColumn('clinicalConcerns', 'Clinical Concerns', 'string', null, false));
  }

  const rows: Row[] = participantFreeFormTexts.map((fft, index) => {
    const row: Row = {
      id: `${index}`,
      cells: [
        createCell('pid', fft.participantId, renderPID(studyUid, userUid, fft.participantId)),
        createCell('dateCreated', fft.createdAt, formatDate(fft.createdAt)),
        createCell('prompt', fft.prompt, fft.prompt),
        createCell('text', fft.value, fft.value),
      ],
    };
    if (permissions.canModifyParticipants) {
      row.cells.push(
        createCell(
          'clinicalConcerns',
          'Clinical Concerns',
          renderClinicalConcerns(fft.participantId),
        ),
      );
    }
    return row;
  });

  const defaultSortState: SortState = { col: 'dateCreated', direction: 'asc' };

  return (
    <Box width="100%">
      <Table columns={columns} rows={rows} defaultSortState={defaultSortState} />
    </Box>
  );
};

export default FreeFormTexts;
