import React, { useState } from 'react';
import 'date-fns';
import { TextField } from '@material-ui/core';
import { GraphQLError } from 'graphql';

import Styled from '../Modal.styles';

import { STUDY_QUERY_PARTICIPANT_DATA } from '@/apollo/queries';
import { REGISTER_EXISTING_USER } from '@/apollo/mutations';
import { MutationRegisterExistingUserArgs } from '@/apollo/types';
import { MutationResultType } from '@/types';
import ExclamationSVG from '@/assets/ExclamationCircle.svg';
import { ModalPayloadType } from '@/types/ReduxTypes';
import { useMutation } from '@/hooks/apollo';

type Props = {
  payload: ModalPayloadType['REGISTER_EXISTING_USER'];
  onClose: () => void;
};
const RegisterExistingUser: React.FC<Props> = (props: Props) => {
  const {
    onClose,
    payload,
  } = props;

  const { studyUid } = payload;

  const [email, setEmail] = useState('');
  const [referralSource, setReferralSource] = useState('');
  const [mutationErrors, setMutationErrors] = useState<readonly GraphQLError[]>(null);
  const [isConfirming, setIsConfirming] = useState(false);

  const refetchQueries = [
    {
      query: STUDY_QUERY_PARTICIPANT_DATA,
      variables: {
        studyUid,
      },
    },
  ];
  const [registerExistingUserMutation] = useMutation<MutationRegisterExistingUserArgs>(
    REGISTER_EXISTING_USER, { refetchQueries },
  );
  const registerExistingUser = (args: MutationRegisterExistingUserArgs): Promise<MutationResultType> => (
    registerExistingUserMutation({ variables: args })
  );

  const submitAndClose = async () => {
    const result = await registerExistingUser({
      studyUid,
      userEmail: email,
      referralSource,
    });
    if (result.errors) {
      setMutationErrors(result.errors);
    } else {
      onClose();
    }
  };

  const emailField = (
    <TextField
      variant="outlined"
      margin="normal"
      fullWidth
      name="email"
      label="Email"
      id="email"
      type="email"
      onChange={(event) => setEmail(event.target.value)}
    />
  );

  const referralSourceField = (
    <TextField
      variant="outlined"
      margin="normal"
      fullWidth
      name="referralSource"
      label="Referral source"
      id="referralSource"
      value={referralSource}
      onChange={(event) => setReferralSource(event.target.value)}
    />
  );

  const buttonDisabled = !email;

  const formModalContent = (
    <>
      <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 16 }}>
        <Styled.HeaderText style={{ marginBottom: 32 }}>
          {`Register existing user in Study ${studyUid}`}
        </Styled.HeaderText>
        { emailField }
        { referralSourceField }
      </div>
      <div style={{ display: 'flex' }}>
        <Styled.NoButton onClick={onClose}>
          <Styled.NoButtonText>
            Cancel
          </Styled.NoButtonText>
        </Styled.NoButton>
        <Styled.YesButton
          style={{ backgroundColor: buttonDisabled ? 'gray' : null }}
          onClick={() => setIsConfirming(true)}
          disabled={buttonDisabled}
          color="primary"
        >
          <Styled.YesButtonText>
            Submit
          </Styled.YesButtonText>
        </Styled.YesButton>
      </div>
    </>
  );

  const confirmModalContent = (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <div style={{ marginRight: 16, marginTop: 4 }}>
        <Styled.WarningSVG src={ExclamationSVG} />
      </div>
      <div>
        <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 16 }}>
          <Styled.HeaderText style={{ marginBottom: 16 }}>
            Confirm New User Information
          </Styled.HeaderText>
          <p>{`Email: ${email}`}</p>
          {(!!referralSource && <p>{`Referral source: ${referralSource}`}</p>)}
          {mutationErrors && (
            <div>
              {mutationErrors.map((error) => error.message)}
            </div>
          )}
        </div>
        <div style={{ display: 'flex' }}>
          <Styled.NoButton
            onClick={() => {
              setIsConfirming(false);
              setMutationErrors([]);
            }}
          >
            <Styled.NoButtonText>
              Back
            </Styled.NoButtonText>
          </Styled.NoButton>
          <Styled.YesButton
            onClick={submitAndClose}
            color="primary"
          >
            <Styled.YesButtonText>
              Confirm
            </Styled.YesButtonText>
          </Styled.YesButton>
        </div>
      </div>
    </div>
  );

  return (
    <div style={{ width: '416px', height: '75%' }}>
      <Styled.DialogContent>
        { isConfirming ? confirmModalContent : formModalContent }
      </Styled.DialogContent>
    </div>
  );
};

export default RegisterExistingUser;
