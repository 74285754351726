import React, { useState } from 'react';
import { Snackbar } from '@material-ui/core';
import { GraphQLError } from 'graphql';
import { Alert } from '@material-ui/lab';
import { LoadingSpinner, Box, Text } from 'react-limbix-ui';

import { RESEARCH_ASSIGNMENT_QUERY } from '@/apollo/queries';
import {
  COMPLETE_RESEARCH_ASSIGNMENT,
  START_RESEARCH_ASSIGNMENT,
  REPORT_RESEARCH_ASSESSMENT_RESULT,
} from '@/apollo/mutations';
import Assessment from '@/components/Assessment';
import {
  QueryAssignmentArgs,
  MutationStartResearchAssignmentArgs,
  MutationCompleteResearchAssignmentArgs,
  MutationReportResearchAssessmentResultArgs,
} from '@/apollo/types';
import { GraphQLErrorsType, MutationResultType } from '@/types';
import { useQuery, useMutation } from '@/hooks/apollo';

type Props = {
  assignmentUid: string;
  studyUid: string;
}
const ResearchAssignment: React.FC<Props> = (props: Props) => {
  const { assignmentUid, studyUid } = props;
  const [mutationErrors, setMutationErrors] = useState<GraphQLErrorsType>(null);
  const { loading, data, error } = useQuery<QueryAssignmentArgs>(RESEARCH_ASSIGNMENT_QUERY, {
    variables: {
      studyUid,
      assignmentUid,
    },
  });
  const [startAssignment] = useMutation<MutationStartResearchAssignmentArgs>(START_RESEARCH_ASSIGNMENT);
  const [completeAssignment] = useMutation<MutationCompleteResearchAssignmentArgs>(
    COMPLETE_RESEARCH_ASSIGNMENT,
  );
  const [reportResearchAssessmentResultMutation] = useMutation<MutationReportResearchAssessmentResultArgs>(
    REPORT_RESEARCH_ASSESSMENT_RESULT,
    {
      refetchQueries: [{
        query: RESEARCH_ASSIGNMENT_QUERY,
        variables: {
          studyUid,
          assignmentUid,
        },
      }],
    },
  );

  if (loading) {
    return <LoadingSpinner />;
  }
  if (error) {
    return (
      <Text>{error.message}</Text>
    );
  }
  const { assignment } = data;

  const handleSubmit = async (assessmentAnswers: string[], changeReason?: string):Promise<MutationResultType> => {
    const result = await reportResearchAssessmentResultMutation({
      variables: {
        studyUid,
        assessmentResultUid: assignment?.researchAssessmentResult?.uid,
        assessmentAnswers,
        assignmentUid,
        changeReason,
      },
    });
    if (result.errors && result.errors.length > 0) {
      setMutationErrors(result.errors);
    } else {
      // Complete the assignment
      const resultOfCompletion = await completeAssignment({
        variables: {
          assignmentUid,
          completeTime: new Date(),
          studyUid,
        },
      });
      if (resultOfCompletion.errors && resultOfCompletion.errors.length > 0) {
        setMutationErrors(resultOfCompletion.errors);
      }
    }
    return result;
  };

  const handleStart = async () => {
    const result = await startAssignment({
      variables: {
        assignmentUid,
        startTime: new Date(),
        studyUid,
      },
    });
    if (result.errors && result.errors.length > 0) {
      setMutationErrors(result.errors);
    }
  };

  return (
    <Box padding="32px">
      <Assessment
        onStart={handleStart}
        onSubmit={handleSubmit}
        assessment={assignment?.assessment}
        canEditAfterCompletion={assignment?.canEditAfterCompletion}
        canSkipQuestions={assignment?.canSkipQuestions}
        assessmentResult={assignment?.researchAssessmentResult}
      />
      <Snackbar
        open={!!mutationErrors}
        onClose={() => { setMutationErrors(null); }}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert severity="error">
          {mutationErrors && mutationErrors.map((e:GraphQLError) => e.message)}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default ResearchAssignment;
