import { createAction } from '@reduxjs/toolkit';

import { NavigationActionTypes } from '@/types/ReduxTypes';

type BlockNavigationOnEventType = {
  listeners: Array<keyof WindowEventMap>,
  message?: string,
};
const blockNavigationOnEvent = createAction<BlockNavigationOnEventType>(NavigationActionTypes.blockNavigationOnEvent);
const eventOccurred = createAction<{ value: boolean }>(NavigationActionTypes.eventOccurred);
const blockNavigation = createAction<{ message?: string }>(NavigationActionTypes.blockNavigation);
const unblockNavigation = createAction(NavigationActionTypes.unblockNavigation);

export default {
  blockNavigationOnEvent,
  eventOccurred,
  blockNavigation,
  unblockNavigation,
};
