import React, { PropsWithChildren } from 'react';

import { Link, LoadingSpinner } from 'react-limbix-ui';

import { GENERATE_PARTICIPANT_CRF } from '@/apollo/mutations';
import { MutationGenerateCaseReportFormArgs } from '@/apollo/types';
import { useMutation } from '@/hooks/apollo';
import { MutationResultType } from '@/types';
import { useModal } from '@/hooks/redux';

type Props = {
  studyParticipantUserUid: string;
  studyUid: string;
}

const CaseReportFormDownloader: React.FC<PropsWithChildren<Props>> = (props: PropsWithChildren<Props>) => {
  const [showModal] = useModal();
  const [generateCRFMutation, { loading }] = useMutation<MutationGenerateCaseReportFormArgs>(
    GENERATE_PARTICIPANT_CRF,
  );

  const {
    studyParticipantUserUid, studyUid,
  } = props;

  const generateCRF = (userUid: string): Promise<MutationResultType> => (
    generateCRFMutation({
      variables: {
        userUid,
        studyUid,
      },
    })
  );

  const onClickRenderCRF = async () => {
    const result = await generateCRF(studyParticipantUserUid);
    if (result.errors) {
      showModal(
        'ERROR_POPUP',
        {
          header: 'Error while generating CRF:',
          errors: result.errors.map((mutationError) => mutationError.message),
        },
      );
    } else {
      const crfURL = result?.data?.generateCaseReportForm?.url;
      if (crfURL) {
        window.open(crfURL, '_blank');
      }
    }
  };

  return (
    loading
      ? <LoadingSpinner /> : (
        <Link.ButtonLink onClick={() => onClickRenderCRF()}>
          {props.children || 'Download CRF' }
        </Link.ButtonLink>
      )
  );
};

export default CaseReportFormDownloader;
