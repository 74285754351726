import { gql } from '@apollo/client';

import useQuery from '../useQuery';

import {
  QueryStudyAssignmentSchedulesArgs,
  AssignmentScheduleType,
  AssessmentType,
} from '@/apollo/types';

const STUDY_ASSIGNMENT_SCHEDULES = gql`
  query StudyAssignmentSchedules($studyUid: String!) {
    studyAssignmentSchedules(studyUid: $studyUid) {
      uid
      accountType
      assessment {
        uid
      }
      count
      ordinal
      repeatFrequencyDays
      startTimeOffsetDays
      visibleDurationDays
      study {
        name
      }
      typeForAssignment
      typeOfClientForAssignment
      typeOfNotificationForAssignment
      sendPushNotifications
      sendEmailNotifications
      sendSmsNotifications
      scheduleOnStudyState
      canSkipQuestions
      canEditAfterCompletion
    }
  }
`;

const ASSESSMENTS = gql`
  query Assessments {
    assessments {
      uid
      name
      nameInternal
    }
  }
`;

type UseAssignmentEditorQueriesType = {
  loading: boolean,
  assignmentSchedules: Array<AssignmentScheduleType>,
  assessments: Array<AssessmentType>,
}
const useAssignmentEditorQueries = (
  studyUid: string,
): UseAssignmentEditorQueriesType => {
  const {
    data: { assessments } = {},
    loading: assessmentsLoading,
  } = useQuery<QueryStudyAssignmentSchedulesArgs>(ASSESSMENTS);

  const {
    data: { studyAssignmentSchedules } = {},
    loading: assignmentSchedulesLoading,
  } = useQuery<QueryStudyAssignmentSchedulesArgs>(
    STUDY_ASSIGNMENT_SCHEDULES,
    {
      variables: {
        studyUid,
      },
    },
  );

  return {
    loading: assignmentSchedulesLoading || assessmentsLoading,
    assignmentSchedules: studyAssignmentSchedules,
    assessments,
  };
};

export default useAssignmentEditorQueries;
