import React from 'react';
import { useParams, Redirect } from 'react-router-dom';
import { LoadingSpinner, Box } from 'react-limbix-ui';

import { QueryCareteamsArgs } from '@/apollo/types';
import { CARETEAMS_LIST } from '@/apollo/queries';
import { UseParamsType } from '@/types';
import { useQuery } from '@/hooks/apollo';
import { isPermissionDeniedError } from '@/utils/errorUtils';

const PatientInfo: React.FC = () => {
  const { pdtUid, patientUid } = useParams<UseParamsType>();

  const { loading, data, error } = useQuery<QueryCareteamsArgs>(CARETEAMS_LIST, {
    variables: {
      pdtUid,
    },
  });

  if (loading) {
    return <LoadingSpinner />;
  }

  if (isPermissionDeniedError(error)) {
    return <Redirect to="/operations" />;
  }

  if (data?.careteams && data?.careteams?.length > 0) {
    const careteam = data.careteams.find((_careteam) => _careteam.patient.uid === patientUid);
    if (careteam) {
      return (
        <Box width="100%">
          {careteam.patient.uid}
        </Box>
      );
    }
  }
  return (<></>);
};

export default PatientInfo;
