import React, { useState } from 'react';
import { Link, LoadingSpinner } from 'react-limbix-ui';

import { useMutation } from '@/hooks/apollo';
import { GENERATE_CENTRALIZED_CHANGE_LOG_CSV } from '@/apollo/mutations';
import { useModal, usePermissions } from '@/hooks/redux';
import { MutationGenerateCentralizedChangeLogCsvArgs } from '@/apollo/types';

type Props = {
  studyUid: string
}

const ChangeLogDownloadLink: React.FC<Props> = (props: Props) => {
  const [showModal] = useModal();
  const [changeLogLoading, setChangeLogLoading] = useState(false);
  const [generateCentralizedChangeLogCsv] = useMutation<MutationGenerateCentralizedChangeLogCsvArgs>(
    GENERATE_CENTRALIZED_CHANGE_LOG_CSV,
  );
  const [{ studyPermissions }] = usePermissions();

  const { studyUid } = props;

  // Hide link to download change log for users that are blinded.
  const permissions = studyPermissions.find((study) => study.uid === studyUid);
  if (permissions.isBlinded) {
    return null;
  }

  const handleClickDownload = async () => {
    setChangeLogLoading(true);
    const result = await generateCentralizedChangeLogCsv({
      variables: {
        studyUid,
      },
    });
    if (result.errors) {
      showModal(
        'ERROR_POPUP',
        {
          header: 'Error while generating CSV:',
          errors: result.errors.map((mutationError) => mutationError.message),
        },
      );
    } else {
      const csvUrl = result?.data?.generateCentralizedChangeLogCsv?.url;
      if (csvUrl) {
        window.open(csvUrl, '_blank');
      }
    }
    setChangeLogLoading(false);
  };

  return changeLogLoading ? <LoadingSpinner /> : (
    <Link.ButtonLink
      underlined
      onClick={handleClickDownload}
    >
      Get Change Log
    </Link.ButtonLink>
  );
};

export default ChangeLogDownloadLink;
