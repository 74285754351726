import React, { useState } from 'react';

import { Button } from '@material-ui/core';
import { AssessmentForm, Box, Text } from 'react-limbix-ui';

import { useModal } from '@/hooks/redux';

import {
  AssessmentType, QuestionType,
} from '@/apollo/types';
import { AnyAssessmentResult, MutationResultType } from '@/types';

type Props = {
  assessment: AssessmentType
  canSkipQuestions: boolean;
  canEditAfterCompletion: boolean;
  assessmentResult?: AnyAssessmentResult;
  onStart?: () => void;
  onSubmit?: (assessmentAnswers: string[], changeReason?: string) => Promise<MutationResultType>;
};

const Assessment: React.FC<Props> = (props: Props) => {
  const {
    assessment,
    canSkipQuestions,
    assessmentResult,
    onSubmit,
    onStart,
    canEditAfterCompletion,
  } = props;
  const [showModal] = useModal();

  const [isInEditMode, setIsInEditMode] = useState(false);

  const handleCancel = () => {
    setIsInEditMode(false);
  };

  const handleSubmit = async (assessmentAnswers: string[], changeReason?: string): Promise<MutationResultType> => {
    setIsInEditMode(false);
    return onSubmit(assessmentAnswers, changeReason);
  };

  const handleClickEdit = () => {
    setIsInEditMode(true);
  };

  const renderChangeReasonModal = (submit: () => Promise<MutationResultType>) => {
    showModal(
      'CONFIRMATION_POPUP_CHANGE_REASON',
      {
        header: 'Confirm Assessment Result Edits',
        body: 'Please enter a reason for making these edits',
        mutation: submit,
      },
    );
  };

  const renderSkippedQuestionsModal = (
    skippedQuestions: QuestionType[],
    onSkip: () => void, onReturn?:
    () => void,
  ) => {
    const questionsMissingAnswers = skippedQuestions.map((question) => (
      <Box key={question.uid}>
        <Text as="span">{question.text}</Text>
        <Text as="span" color="red">{question.isOptional ? '' : ' *'}</Text>
      </Box>
    ));
    showModal(
      'GENERIC_WARNING',
      {
        headerText: 'Are you sure you want to skip this question?',
        body: questionsMissingAnswers,
        primaryButtonText: 'Return to survey',
        secondaryButtonText: 'Skip questions and submit',
        onClickPrimaryButton: onReturn,
        onClickSecondaryButton: onSkip,
      },
    );
  };

  return (
    <Box>
      { !isInEditMode && assessmentResult
        && (
          <Button
            onClick={handleClickEdit}
            variant="outlined"
            disabled={!canEditAfterCompletion}
          >
            Edit Assessment Result
          </Button>
        )}
      {assessment && (
        <AssessmentForm
          assessment={assessment}
          canSkipQuestions={canSkipQuestions}
          onStart={onStart}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
          onSubmitWithSkippedQuestions={renderSkippedQuestionsModal}
          onSubmitWithChangeReason={renderChangeReasonModal}
          assessmentResult={assessmentResult}
          disabled={assessmentResult && !isInEditMode}
        />
      )}
    </Box>
  );
};

export default Assessment;
