import { createAction } from '@reduxjs/toolkit';

import {
  ModalActionTypes,
  ModalContentType,
  ModalPayloadType,
} from '@/types/ReduxTypes';

const showModal = createAction(
  ModalActionTypes.ShowModal,
  <ModalContent extends ModalContentType>(modalType: ModalContent, payload: ModalPayloadType[ModalContent]) => ({
    payload: {
      modalType,
      payload,
    },
  }),
);

const hideModal = createAction(ModalActionTypes.HideModal);

export type ShowModalActionType = typeof showModal;
export type HideModalActionType = typeof hideModal;

export default {
  showModal,
  hideModal,
};
