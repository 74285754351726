import { Buffer } from 'buffer';

import { GENDER_MAP } from './constants';

import { PractitionerType } from '@/apollo/types';
import { GenderMapKeys } from '@/types';

export const formatPractitionerName = (practitioner: PractitionerType): string => {
  let prefix = '';
  let suffix = '';
  if (practitioner.practitionerRole === 'DOCTOR') {
    prefix = 'Dr.';
  }
  if (practitioner.practitionerRole === 'ADMIN') {
    suffix = 'HCA';
  }
  if (practitioner.practitionerRole === 'NURSE') {
    suffix = 'RN';
  }

  prefix = prefix !== '' ? `${prefix} ` : prefix;
  suffix = suffix !== '' ? ` ${suffix}` : suffix;
  const { user: { firstName, lastName } } = practitioner;
  return `${prefix}${firstName} ${lastName}${suffix}`;
};

export const stringToBase64 = (data: string): string => (
  Buffer.from(data).toString('base64')
);

export const base64ToString = (data: string): string => (
  Buffer.from(data, 'base64').toString('ascii')
);

export const genderKeyToValue = (key: GenderMapKeys): string => (key ? GENDER_MAP[key] : 'None');

export const genderValueToKey = (value: string): string => (
  Object.entries(GENDER_MAP).find(([, val]) => value === val)[0]
);
