import React, { useEffect } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import moment from 'moment';
import {
  LoadingSpinner,
  Row,
  Text,
  Box,
} from 'react-limbix-ui';

import { AssessmentDataGrid } from '@/components';
import {
  QueryLimbixStudyArgs,
  QueryStudyEnrollmentTrackerResultsArgs,
  MutationUpdateEnrollmentTrackerArgs,
  StudyParticipantType,
} from '@/apollo/types';
import { formatDate } from '@/utils/dateUtils';
import { STUDY_ENROLLMENT_TRACKER_QUERY, STUDY_ENROLLMENT_TRACKER_ASSESSMENT } from '@/apollo/queries';
import { UPDATE_ENROLLMENT_TRACKER } from '@/apollo/mutations';
import { MutationResultType, UseParamsType } from '@/types';
import { makeStaticColumns } from '@/utils/dataGridUtils';
import { usePermissions, usePreventNavigation } from '@/hooks/redux';
import { useQuery, useMutation, useLazyQuery } from '@/hooks/apollo';
import { isPermissionDeniedError } from '@/utils/errorUtils';
import { REDACTED_FOR_BLINDING_MESSAGE } from '@/utils/constants';

const EnrollmentTracker: React.FC = () => {
  const [{ studyPermissions }] = usePermissions();
  const [, { setPreventNavigation, resetListenerEvent }] = usePreventNavigation();

  useEffect(() => {
    setPreventNavigation({ isBlocked: true, listeners: ['keyup', 'dblclick'] });
  }, []);

  const { studyUid } = useParams<UseParamsType>();
  const enrollmentTrackerAssessmentQuery = useQuery<QueryLimbixStudyArgs>(STUDY_ENROLLMENT_TRACKER_ASSESSMENT, {
    variables: {
      studyUid,
    },
  });
  const [getEnrollmentTracker, {
    called,
    data,
    error,
    loading: loadingLazyQuery,
  }] = useLazyQuery<QueryStudyEnrollmentTrackerResultsArgs>(STUDY_ENROLLMENT_TRACKER_QUERY, {
    variables: {
      studyUid,
    },
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (!called) {
      getEnrollmentTracker();
    }
  }, [called]);

  const handleFinishMutations = () => {
    getEnrollmentTracker();
    resetListenerEvent();
  };

  const [updateEnrollmentTrackerMutation] = useMutation<MutationUpdateEnrollmentTrackerArgs>(
    UPDATE_ENROLLMENT_TRACKER, {
      ignoreResults: true,
    },
  );
  const updateEnrollmentTracker = (
    enrollmentTrackerResultUid: string,
    assessmentAnswers: Array<string>,
    changeReason: string,
  ): Promise<MutationResultType> => {
    const variables: MutationUpdateEnrollmentTrackerArgs = {
      enrollmentTrackerResultUid,
      assessmentAnswers,
      studyUid,
      changeReason,
    };
    return updateEnrollmentTrackerMutation({ variables });
  };

  const permissions = studyPermissions.find((study) => study.uid === studyUid);

  if (enrollmentTrackerAssessmentQuery.loading) {
    return <LoadingSpinner />;
  }

  if (isPermissionDeniedError(error)) {
    return <Redirect to="/research" />;
  }

  if (!enrollmentTrackerAssessmentQuery.data?.limbixStudy?.enrollmentTrackerAssessment) {
    return (
      <Text as="h1">
        No Enrollment Tracker seems to be attacted to this study, please add one and try again
      </Text>
    );
  }

  const enrollmentTrackerAssessment = enrollmentTrackerAssessmentQuery.data?.limbixStudy?.enrollmentTrackerAssessment;
  const participants = data?.limbixStudy?.studyParticipants || [];

  if (!enrollmentTrackerAssessment) {
    return (
      <Text as="h1">
        No Enrollment Tracker Assessment seems to be attached to this study, please add one and try again
      </Text>
    );
  }

  const enrollmentTrackerAssessments = participants
    .filter((participant) => participant.enrollmentTrackerResult)
    .map((participant) => participant.enrollmentTrackerResult) || [];

  const staticColumns = makeStaticColumns([
    {
      field: 'pid',
      headerName: 'PID',
    },
    {
      field: 'createdAt',
      headerName: 'Created At',
    },
    {
      field: 'studyArm',
      headerName: 'Study Arm',
    },
    {
      field: 'status',
      headerName: 'Status',
    },
    {
      field: 'referralSource',
      headerName: 'Referral Source',
    },
    {
      field: 'firstName',
      headerName: 'First Name',
    },
    {
      field: 'dateOfBirth',
      headerName: 'DOB',
    },
    {
      field: 'ageAtEnrollment',
      headerName: 'Age at Enrollment',
    },
    {
      field: 'turnsEighteenWhileEnrolled',
      headerName: 'Turns 18 While Enrolled',
    },
    {
      field: 'email',
      headerName: 'Email',
    },
    {
      field: 'phone',
      headerName: 'Phone',
    },
    {
      field: 'caregiverFirstName',
      headerName: 'Caregiver First Name',
    },
    {
      field: 'caregiverEmail',
      headerName: 'Caregiver Email',
    },
    {
      field: 'caregiverPhone',
      headerName: 'Caregiver Phone',
    },
    {
      field: 'interventionCompletionDate',
      headerName: 'Intervention Completion Date',
    },
  ]);

  const getInterventionCompletionDate = (activationTime: string): string => {
    const activationMoment = moment(activationTime);
    if (!activationMoment.isValid()) {
      return 'N/A - user not yet activated';
    }
    const interventionDays = data?.limbixStudy?.appAccessDurationDays;
    if (!interventionDays) {
      return 'N/A - user has unlimited access';
    }
    const interventionCompletionMoment = activationMoment.add(interventionDays, 'days');
    return formatDate(interventionCompletionMoment.toDate());
  };

  const getTurnsEighteenWhileEnrolled = (participant: StudyParticipantType): string => {
    const turnsEighteenWhileEnrolled = participant?.turnsEighteenWhileEnrolled?.toString();

    if (!turnsEighteenWhileEnrolled) {
      const activationMoment = moment(participant?.activationTime);
      if (!activationMoment.isValid()) {
        return 'N/A - user not yet activated';
      }
      return 'N/A - could not be calculated';
    }
    return turnsEighteenWhileEnrolled;
  };

  const staticCells = participants.filter((participant) => !!participant.enrollmentTrackerResult)
    .map((participant): Row => {
      const pid = participant?.participantId;
      const createdAt = formatDate(participant.enrollmentTrackerResult.createdAt);
      const studyArm = permissions.isBlinded ? REDACTED_FOR_BLINDING_MESSAGE : participant?.studyArm?.name;
      const status = participant?.status;
      const referralSource = participant?.referralSource;
      const firstName = participant?.user?.firstName;
      const dateOfBirth = formatDate(participant?.user?.dateOfBirth);
      const ageAtEnrollment = `${participant?.user?.ageAtEnrollment}`;
      const turnsEighteenWhileEnrolled = getTurnsEighteenWhileEnrolled(participant);
      const email = participant?.user?.email;
      const phone = participant?.user?.phone;
      const firstCaregiver = participant?.user?.parents[0];
      const caregiverFirstName = firstCaregiver ? `${firstCaregiver?.firstName}` : '';
      const caregiverEmail = firstCaregiver?.email;
      const caregiverPhone = firstCaregiver?.phone;
      const interventionCompletionDate = getInterventionCompletionDate(participant?.activationTime);

      return {
        pid: { value: pid, originalValue: pid },
        createdAt: { value: createdAt, originalValue: createdAt },
        studyArm: { value: studyArm, originalValue: studyArm },
        status: { value: status, originalValue: status },
        referralSource: { value: referralSource, originalValue: referralSource },
        firstName: { value: firstName, originalValue: firstName },
        dateOfBirth: { value: dateOfBirth, originalValue: dateOfBirth },
        ageAtEnrollment: { value: ageAtEnrollment, originalValue: ageAtEnrollment },
        turnsEighteenWhileEnrolled: { value: turnsEighteenWhileEnrolled, originalValue: turnsEighteenWhileEnrolled },
        email: { value: email, originalValue: email },
        phone: { value: phone, originalValue: phone },
        caregiverFirstName: { value: caregiverFirstName, originalValue: caregiverFirstName },
        caregiverEmail: { value: caregiverEmail, originalValue: caregiverEmail },
        caregiverPhone: { value: caregiverPhone, originalValue: caregiverPhone },
        interventionCompletionDate: { value: interventionCompletionDate, originalValue: interventionCompletionDate },
      };
    });

  return (
    <Box display="inline" height="100%">
      {loadingLazyQuery && (
        <LoadingSpinner />
      )}
      <AssessmentDataGrid
        assessment={enrollmentTrackerAssessment}
        assessmentResults={enrollmentTrackerAssessments}
        staticColumns={staticColumns}
        staticCells={staticCells}
        permissions={permissions}
        onSaveRow={updateEnrollmentTracker}
        onFinishMutations={handleFinishMutations}
      />
    </Box>
  );
};

export default EnrollmentTracker;
