import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Paper,
  InputBase,
  IconButton,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

type Props = {
  onChange: React.ChangeEventHandler;
  defaultValue?: string;
  placeholder?: string;
};

const SearchBar: React.FC<Props> = (props: Props) => {
  const { onChange, defaultValue, placeholder } = props;
  const classes = useStyles();

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  return (
    <Paper component="form" className={classes.root} elevation={3}>
      <InputBase
        className={classes.input}
        onChange={onChange}
        defaultValue={defaultValue || ''}
        style={{ zIndex: 0 }}
        placeholder={placeholder || 'Search...'}
        inputProps={{ 'aria-label': 'search' }}
        onKeyDown={handleKeyPress}
      />
      <IconButton className={classes.iconButton} aria-label="search">
        <SearchIcon />
      </IconButton>
    </Paper>
  );
};

export default SearchBar;
