import { InMemoryCache, makeVar, ReactiveVar } from '@apollo/client';

export const isLoggedInVar: ReactiveVar<boolean> = makeVar<boolean>(false);

export const cache = new InMemoryCache({
  // stored to the cache
  typePolicies: {
    Query: {
      fields: {
        isLoggedIn: {
          read() {
            return isLoggedInVar();
          },
        },
        limbixStudy: {
          merge(existing, incoming) {
            if (existing?.uid && incoming?.uid && incoming.uid === existing.uid) {
              return {
                ...existing,
                ...incoming,
              };
            }
            return incoming;
          },
        },
      },
    },
  },
});
