import AssignmentSchedules from './AssignmentSchedules';
import DeteriorationAlerts from './DeteriorationAlerts';
import EnrollmentTracker from './EnrollmentTracker';
import FreeFormTextReview from './FreeFormTextReview';
import ManageParticipants from './ManageParticipants';
import StudyList from './StudyList';
import StudySettings from './StudySettings';

export default {
  AssignmentSchedules,
  DeteriorationAlerts,
  EnrollmentTracker,
  FreeFormTextReview,
  ManageParticipants,
  StudyList,
  StudySettings,
};
