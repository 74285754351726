import React from 'react';
import { Grid } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { Box, Link } from 'react-limbix-ui';

import Styled from './ParticipantInfo.styles';

import { formatDate } from '@/utils/dateUtils';
import { StudyParticipantType } from '@/apollo/types';
import { useModal, usePermissions } from '@/hooks/redux';
import CaseReportFormDownloader from '@/components/CaseReportFormDownloader';

type Props = {
  studyParticipant: StudyParticipantType;
  isSummary?: boolean;
  studyUid: string;
};
const ParticipantInfo: React.FC<Props> = (props: Props) => {
  const {
    studyParticipant,
    isSummary,
    studyUid,
  } = props;
  const [{ studyPermissions }] = usePermissions();
  const [showModal] = useModal();

  const {
    userPdtData,
    moodLogs,
    behavioralActivations,
    tasks,
  } = studyParticipant;

  const permissions = studyPermissions.find((permissons) => permissons.uid === studyUid);

  const BASE_URL = `/research/study/${studyUid}/participant/${studyParticipant.user.uid}`;

  const renderDetailsCol = () => (
    <>
      {
        !permissions.isBlinded
        && (
          <div>
            <Link.RouterLink to={`${BASE_URL}/research_assignments`}>
              <Styled.BoldText size="22px">
                Research Assignments
              </Styled.BoldText>
            </Link.RouterLink>
          </div>
        )
      }
      <div>
        <Link.RouterLink to={`${BASE_URL}/assignments`}>
          <Styled.BoldText size="22px">
            Assignments
          </Styled.BoldText>
        </Link.RouterLink>
      </div>
      {
        !permissions.isBlinded
        && (
          <div>
            <Link.RouterLink to={`${BASE_URL}/free-form-text-review`}>
              <Styled.BoldText size="22px">
                Free-Form Text Review
              </Styled.BoldText>
            </Link.RouterLink>
          </div>
        )
      }
      <div>
        <Link.RouterLink to={`${BASE_URL}/summary`}>
          <Styled.BoldText size="22px">
            Participant Summary
          </Styled.BoldText>
        </Link.RouterLink>
      </div>
      {
        !permissions.isBlinded && !!studyParticipant.study.caseReportFormTemplate
        && (
          <Box padding="16px 0px">
            <CaseReportFormDownloader
              studyParticipantUserUid={studyParticipant.user.uid}
              studyUid={studyUid}
            >
              <Styled.BoldText size="22px">
                Case Report Form
              </Styled.BoldText>
            </CaseReportFormDownloader>
          </Box>
        )
      }
      {
        !permissions.isBlinded && !!studyParticipant.study?.clinicalConcernAssessment
        && (
          <div>
            <Link.RouterLink to={`${BASE_URL}/clinical-concerns-log`}>
              <Styled.BoldText size="22px">
                Clinical Concerns
              </Styled.BoldText>
            </Link.RouterLink>
          </div>
        )
      }
    </>
  );

  const renderSummaryCol = () => (
    <>
      <div style={{ marginBottom: '10px' }}>
        <Link.ButtonLink onClick={() => (
          showModal('ALL_CONTACT_INFO', { studyParticipant })
        )}
        >
          <Styled.BoldText size="22px">
            View all contact info
          </Styled.BoldText>
        </Link.ButtonLink>
      </div>
      <div>
        <Link.RouterLink to={`${BASE_URL}/assignments`}>
          <Styled.BoldText size="22px">
            Assignments
          </Styled.BoldText>
        </Link.RouterLink>
      </div>
      {
        !permissions.isBlinded && !!studyParticipant.study?.clinicalConcernAssessment
        && (
          <div>
            <Link.RouterLink to={`${BASE_URL}/clinical-concerns-log`}>
              <Styled.BoldText size="22px">
                Clinical Concerns
              </Styled.BoldText>
            </Link.RouterLink>
          </div>
        )
      }
    </>
  );

  return (
    <>
      <Styled.BoldText size="24px">
        Participant Details:
      </Styled.BoldText>
      <div style={{ padding: '15px 0px 30px 0px' }}>
        <Grid
          container
          justifyContent="flex-start"
          alignItems="stretch"
          style={{ height: '100%' }}
        >
          <Styled.GridItem withborders={1}>
            <div>
              <Styled.BoldText>
                PID:
              </Styled.BoldText>
              <div style={{ display: 'flex' }}>
                <Styled.DetailText>
                  {studyParticipant.participantId || 'None'}
                </Styled.DetailText>
                {permissions.canModifyParticipants && (
                  <div style={{ margin: '0 0 0 auto', display: 'flex', cursor: 'pointer' }}>
                    <Link.ButtonLink
                      onClick={() => showModal('UPDATE_PID', {
                        studyParticipant,
                      })}
                    >
                      <EditIcon fontSize="small" />
                      Update PID
                    </Link.ButtonLink>
                  </div>
                )}
              </div>
            </div>
            <div style={{ marginTop: '20px' }}>
              <Styled.BoldText>
                Status:
              </Styled.BoldText>
              <Styled.DetailText>
                {studyParticipant.status}
              </Styled.DetailText>
            </div>
            <div style={{ marginTop: '20px' }}>
              <Styled.BoldText>
                Activation Time:
              </Styled.BoldText>
              <Styled.DetailText>
                {formatDate(studyParticipant.activationTime)}
              </Styled.DetailText>
            </div>
            <div style={{ marginTop: '20px' }}>
              <Styled.BoldText>
                Complete Time:
              </Styled.BoldText>
              <Styled.DetailText>
                {formatDate(studyParticipant.completeTime)}
              </Styled.DetailText>
            </div>
          </Styled.GridItem>
          {
            !permissions.isBlinded && (
              <Styled.GridItem withborders={1}>
                <div>
                  <div style={{ display: 'flex' }}>
                    <Styled.BoldText style={{ marginRight: '5px' }}>
                      Current Module:
                    </Styled.BoldText>
                    <Styled.DetailText>
                      {` ${userPdtData?.currentModuleNumber || 0}`}
                    </Styled.DetailText>
                  </div>
                  <div style={{ display: 'flex' }}>
                    <Styled.BoldText style={{ marginRight: '5px' }}>
                      Current Task:
                    </Styled.BoldText>
                    <Styled.DetailText>
                      {` ${userPdtData?.currentTaskNumber || 0}`}
                    </Styled.DetailText>
                  </div>
                </div>
                <div style={{ marginTop: '20px' }}>
                  <Styled.BoldText>
                    Tasks Completed:
                  </Styled.BoldText>
                  <Styled.DetailText>
                    {tasks.reduce(
                      (prev, curr) => (curr.completeTime ? prev + 1 : prev), 0,
                    ) || 0}
                  </Styled.DetailText>
                </div>
                <div style={{ marginTop: '20px' }}>
                  <Styled.BoldText>
                    Mood Logs Completed:
                  </Styled.BoldText>
                  <Styled.DetailText>
                    {moodLogs?.length || 0}
                  </Styled.DetailText>
                </div>
                <div style={{ marginTop: '20px' }}>
                  <Styled.BoldText>
                    Behavioral Activations Completed:
                  </Styled.BoldText>
                  <Styled.DetailText>
                    {behavioralActivations.reduce(
                      (prev, curr) => (curr.completeTime ? prev + 1 : prev), 0,
                    )}
                  </Styled.DetailText>
                </div>
              </Styled.GridItem>
            )
          }
          <Styled.GridItem withborders={1}>
            <div>
              <Styled.BoldText>
                Notes:
              </Styled.BoldText>
              <Styled.DetailText>
                {studyParticipant.notes || 'No Notes'}
              </Styled.DetailText>
            </div>
          </Styled.GridItem>
          <Styled.GridItem>
            {isSummary ? renderSummaryCol() : renderDetailsCol()}
          </Styled.GridItem>
        </Grid>
      </div>
    </>
  );
};

export default ParticipantInfo;
