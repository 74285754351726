import React, { useState, useEffect } from 'react';
import { useParams, Redirect, useLocation } from 'react-router-dom';
import { LoadingSpinner, Box } from 'react-limbix-ui';

import ProviderTable from './ProviderTable';

import { QueryPractitionersArgs } from '@/apollo/types';
import { PRACTITIONERS_LIST } from '@/apollo/queries';
import { UseParamsType } from '@/types';
import history from '@/utils/history';
import { useQuery } from '@/hooks/apollo';
import { isPermissionDeniedError } from '@/utils/errorUtils';

const ProviderList: React.FC = () => {
  const { pdtUid } = useParams<UseParamsType>();
  const { state, pathname } = useLocation<{ searchFilter?: string }>();

  const [searchFilter] = useState(state?.searchFilter?.toLowerCase() || '');

  useEffect(() => {
    history.replace(pathname, null);
  }, []);

  const { loading, data, error } = useQuery<QueryPractitionersArgs>(PRACTITIONERS_LIST, {
    variables: {
      pdtUid,
    },
  });

  if (loading) {
    return <LoadingSpinner />;
  }

  if (isPermissionDeniedError(error)) {
    return <Redirect to="/operations" />;
  }

  if (data?.practitioners) {
    return (
      <Box width="100%">
        <ProviderTable
          pdtUid={pdtUid}
          practitioners={data.practitioners}
          searchFilter={searchFilter}
        />
      </Box>
    );
  }
  return (<></>);
};

export default ProviderList;
